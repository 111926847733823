import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { numberWithCommas } from 'utils/util';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto'
  }
});

function RankingTable(props) {
  const { classes, values } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>顧客</TableCell>
            <TableCell align="right">金額</TableCell>
            <TableCell align="right">件数</TableCell>
            <TableCell align="right">割合</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map(row => (
            <TableRow key={row.customerName}>
              <TableCell>{row.customerName}</TableCell>
              <TableCell align="right">{numberWithCommas(row.summary)} 円</TableCell>
              <TableCell align="right">{row.count}</TableCell>
              <TableCell align="right">{row.rate.toFixed(2)} %</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(RankingTable);
