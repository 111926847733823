import React from 'react';
import { withFormik } from 'formik';
import { compose } from 'react-apollo';
import * as yup from 'yup';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import constants from 'myConstants';

import MyTextField from 'elements/MyTextField';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  form: {
    marginTop: theme.spacing.unit
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center'
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

const ResetPasswordStep2Form = props => {
  const { classes, values, errors, touched, handleChange, handleBlur, handleSubmit } = props;
  return (
    <Paper className={classes.paper}>
      <Typography component="h2" variant="h6" align="center">
        新しいパスワードを入力してください。
      </Typography>
      <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={12}>
            <MyTextField
              name="newPassword"
              type="password"
              values={values}
              touched={touched}
              errors={errors}
              label="新しいパスワード"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MyTextField
              name="newPasswordConfirm"
              type="password"
              values={values}
              touched={touched}
              errors={errors}
              label="新しいパスワード（確認）"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button type="submit" variant="contained" color="primary" className={classes.submit}>
            変更
          </Button>
        </div>
      </form>
    </Paper>
  );
};

const initialValues = {
  newPassword: '',
  newPasswordConfirm: ''
};

const schema = yup.object().shape({
  newPassword: yup.string().required(),
  newPasswordConfirm: yup
    .string()
    .oneOf([yup.ref('newPassword'), null])
    .required('Password Confirm is required')
});

export default compose(
  withStyles(styles),
  withFormik({
    mapPropsToValues: () => initialValues,
    validationSchema: schema,
    handleSubmit: (values, { props, setSubmitting, setFieldError }) => {
      const { mutate, onSucceed } = props;

      const { newPassword } = values;
      const variables = {
        resetKey: props.resetKey,
        newPassword
      };

      console.log(variables);

      mutate({
        variables
      })
        .then(onSucceed)
        .catch(error => {
          if (error.message === 'GraphQL error: Invalid password') {
            setFieldError('password', constants.MESSAGE.INVALID_PASSWORD);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    displayName: 'ResetPasswordStep2Form'
  })
)(ResetPasswordStep2Form);
