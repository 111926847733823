import { find, findIndex, transform, isEqual, isObject, get, has } from 'lodash';

import { removeVoidProps } from 'utils/util';

export const convertIntoSelectFieldValues = arr =>
  arr.map(item => ({ ...item, value: item.id, label: item.name }));

export const convertIntoIdObjArr = arr => arr.map(item => ({ id: item }));

export const convertIntoIdArr = idObjArr => idObjArr.map(obj => obj.id);

export const takeOption = (options, value) => {
  const index = findIndex(options, option => option.value === value);
  if (index >= 0) {
    return options[index];
  } else {
    return undefined;
  }
};

export const takeOptionField = (options, value, field) => {
  const option = takeOption(options, value);
  if (option) {
    return option[field];
  } else {
    return undefined;
  }
};

export const takeErrorState = (errors, touched, name) => {
  if (has(touched, name) && has(errors, name)) {
    return true;
  } else {
    return false;
  }
};

export const takeHelperText = (errors, touched, name) => {
  if (takeErrorState(errors, touched, name)) {
    return get(errors, name);
  } else {
    return undefined;
  }
};

export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const mergeInitialValues = (initialValues, baseValues) => {
  const values = { ...initialValues, ...removeVoidProps(baseValues) };
  return values;
};

export const convertIntoWhereData = ({ id, ...rest }) => {
  return {
    where: { id },
    data: rest
  };
};

export const differenceObject = (object, base) => {
  return transform(object, (result, value, key) => {
    if (key === 'id' || !isEqual(value, base[key])) {
      if (Array.isArray(value) && Array.isArray(base[key])) {
        result[key] = value;
      } else {
        result[key] =
          isObject(value) && isObject(base[key]) ? differenceObject(value, base[key]) : value;
      }
    }
  });
};

export const relateFields = ({ ...values }, base) => {
  Object.entries(values).forEach(([name, value]) => {
    const relatedIdsIndex = name.indexOf('RelatedIds');
    const relatedIdIndex = name.indexOf('RelatedId');
    if (relatedIdsIndex > -1) {
      const newName = name.substring(0, relatedIdsIndex);
      if (base) {
        values[newName] = { set: values[name].map(id => ({ id })) };
      } else {
        values[newName] = { connect: values[name].map(id => ({ id })) };
      }
      delete values[name];
    } else if (relatedIdIndex > -1) {
      if (value) {
        values[name.substring(0, relatedIdIndex)] = {
          connect: { id: value }
        };
      } else if (base && base[name]) {
        values[name.substring(0, relatedIdIndex)] = {
          disconnect: true
        };
      }
      delete values[name];
    }
  });
  return values;
};

export const takeHasManyFieldValue = (valuesArr, base, convert) => {
  const crud = {
    create: [],
    update: [],
    delete: []
  };

  valuesArr.forEach(values => {
    let newValues;
    const baseValues = find(base, { id: values.id });
    if (baseValues) {
      newValues = differenceObject(values, baseValues);
      newValues = relateFields(newValues, baseValues);

      if (convert) newValues = convert(newValues);

      crud.update.push(convertIntoWhereData(newValues));
    } else {
      newValues = relateFields(values, baseValues);

      if (convert) newValues = convert(newValues);

      crud.create.push(newValues);
    }
  });

  base.forEach(values => {
    if (findIndex(valuesArr, { id: values.id }) < 0) {
      crud.delete.push({ id: values.id });
    }
  });

  return crud;
};
