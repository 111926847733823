import gql from 'graphql-tag';

import {
  ReportFieldsFragment,
  ReportFieldsOfListFragment,
  VoucherFieldsFragment,
  VoucherFieldsOfListFragment,
  MediaFieldsFragment,
  OperationFieldsFragment,
  ExpenseFieldsFragment,
  UserFieldsFragment
} from './fragments';

export const NEW_REPORT = gql`
  mutation AddReport($data: ReportCreateInput!) {
    addReport(data: $data) {
      id
    }
  }
`;

export const FETCH_REPORT = gql`
  query FetchReport($where: ReportWhereUniqueInput!) {
    fetchReport(where: $where) {
      ...ReportFields
    }
  }
  ${ReportFieldsFragment}
`;

export const EDIT_REPORT = gql`
  mutation EditReport($data: ReportUpdateInput!, $where: ReportWhereUniqueInput!) {
    editReport(data: $data, where: $where) {
      ...ReportFields
    }
  }
  ${ReportFieldsFragment}
`;

export const EDIT_REPORT_STATUS = gql`
  mutation EditReportStatus($where: ReportWhereUniqueInput!, $reportStatus: ReportStatus!) {
    editReportStatus(where: $where, reportStatus: $reportStatus) {
      ...ReportFields
    }
  }
  ${ReportFieldsFragment}
`;

export const ADD_REPORT_MESSAGE = gql`
  mutation AddReportMessage($data: ReportMessageCreateInput!) {
    addReportMessage(data: $data) {
      ...ReportFields
    }
  }
  ${ReportFieldsFragment}
`;

export const REMOVE_REPORT_MESSAGE = gql`
  mutation RemoveReportMessage($where: ReportMessageWhereUniqueInput!) {
    removeReportMessage(where: $where) {
      ...ReportFields
    }
  }
  ${ReportFieldsFragment}
`;

export const REMOVE_REPORT = gql`
  mutation EditReport($where: ReportWhereUniqueInput!) {
    removeReport(where: $where) {
      id
    }
  }
`;

export const SEARCH_REPORTS = gql`
  query ReportList(
    $where: ReportWhereInput
    $skip: Int
    $first: Int
    $orderBy: ReportOrderByInput
  ) {
    searchReports(where: $where, skip: $skip, first: $first, orderBy: $orderBy) {
      count
      reports {
        ...ReportFieldsOfList
      }
    }
  }
  ${ReportFieldsOfListFragment}
`;

export const GET_SEARCH_REPORT_OPERATIONS_FORM_OPTIONS = gql`
  query GetSearchReportOperationsFormOptions($where: UserWhereInput, $orderBy: UserOrderByInput) {
    getUsers(where: $where, orderBy: $orderBy) {
      ...UserFields
    }
    eizoOperations: operations(where: { section: Eizo }, orderBy: order_ASC) {
      ...OperationFields
    }
    onkyoOperations: operations(where: { section: Onkyo }, orderBy: order_ASC) {
      ...OperationFields
    }
    gaigaOperations: operations(where: { section: Gaiga }, orderBy: order_ASC) {
      ...OperationFields
    }
    eigyoOperations: operations(where: { section: Eigyo }, orderBy: order_ASC) {
      ...OperationFields
    }
    otherOperations: operations(where: { section: Other }, orderBy: order_ASC) {
      ...OperationFields
    }
  }
  ${UserFieldsFragment}
  ${OperationFieldsFragment}
`;

export const SEARCH_REPORT_OPERATIONS = gql`
  query ReportOperationList(
    $where: ReportOperationWhereInput
    $skip: Int
    $first: Int
    $orderBy: ReportOperationOrderByInput
  ) {
    searchReportOperations(where: $where, skip: $skip, first: $first, orderBy: $orderBy) {
      count
      reportOperations {
        id
        report {
          id
          program {
            programNo
          }
          customer {
            name
          }
          personnel {
            name
          }
          title
          subTitle
        }
        user {
          name
        }
        date
        section
        operation {
          name
        }
        startHours
        startMinutes
        endHours
        endMinutes
        unitLabel
        amount
        charge
        rating
        systemCheck
        detail
      }
    }
  }
`;

export const SEARCH_REPORT_MEDIAS = gql`
  query ReportMediaList(
    $where: ReportMediaWhereInput
    $skip: Int
    $first: Int
    $orderBy: ReportMediaOrderByInput
  ) {
    searchReportMedias(where: $where, skip: $skip, first: $first, orderBy: $orderBy) {
      count
      reportMedias {
        id
        report {
          id
          program {
            programNo
          }
          customer {
            name
          }
          title
          subTitle
        }
        date
        media {
          name
        }
        user {
          name
        }
        amount
        mediaType
        base
        check
      }
    }
  }
`;

export const GET_SEARCH_REPORT_EXPENSES_FORM_OPTIONS = gql`
  query GetSearchReportExpensesFormOptions {
    getUsers(where: { isRetired: false }, orderBy: nameKana_ASC) {
      ...UserFields
    }
    expenses(orderBy: order_ASC) {
      ...ExpenseFields
    }
  }
  ${UserFieldsFragment}
  ${ExpenseFieldsFragment}
`;

export const SEARCH_REPORT_EXPENSES = gql`
  query ReportExpenseList(
    $where: ReportExpenseWhereInput
    $skip: Int
    $first: Int
    $orderBy: ReportExpenseOrderByInput
  ) {
    searchReportExpenses(where: $where, skip: $skip, first: $first, orderBy: $orderBy) {
      count
      reportExpenses {
        id
        report {
          id
          program {
            programNo
          }
          title
        }
        date
        expense {
          name
        }
        user {
          name
        }
        amount
        unitPrice
        check
      }
    }
  }
`;

export const GET_REPORT_FORM_OPTIONS = gql`
  query GetReportFormOptions {
    getUsers(where: { isRetired: false }, orderBy: nameKana_ASC) {
      id
      name
      nameKana
      email
    }
    eizoOperations: operations(where: { section: Eizo }, orderBy: order_ASC) {
      ...OperationFields
    }
    onkyoOperations: operations(where: { section: Onkyo }, orderBy: order_ASC) {
      ...OperationFields
    }
    gaigaOperations: operations(where: { section: Gaiga }, orderBy: order_ASC) {
      ...OperationFields
    }
    eigyoOperations: operations(where: { section: Eigyo }, orderBy: order_ASC) {
      ...OperationFields
    }
    otherOperations: operations(where: { section: Other }, orderBy: order_ASC) {
      ...OperationFields
    }
    medias(orderBy: order_ASC) {
      ...MediaFields
    }
    expenses(orderBy: order_ASC) {
      ...ExpenseFields
    }
  }
  ${OperationFieldsFragment}
  ${MediaFieldsFragment}
  ${ExpenseFieldsFragment}
`;

export const NEW_VOUCHER = gql`
  mutation AddVoucher($data: VoucherCreateInput!) {
    addVoucher(data: $data) {
      id
    }
  }
`;

export const NEW_INTEGRATED_VOUCHER = gql`
  mutation addIntegratedVoucher($data: VoucherCreateInput!, $fromWhere: VoucherWhereInput) {
    addIntegratedVoucher(data: $data, fromWhere: $fromWhere) {
      id
    }
  }
`;

export const FETCH_VOUCHER = gql`
  query FetchVoucher($where: VoucherWhereUniqueInput!) {
    fetchVoucher: fetchVoucher(where: $where) {
      ...VoucherFields
    }
    getVoucherPaymentDay: getVoucherPaymentDay(where: $where)
  }
  ${VoucherFieldsFragment}
`;

export const GET_SPECIFICATABLE_VOUCHERS = gql`
  query GetSpecificatableVouchers($where: CustomerWhereInput!) {
    getSpecificatableVouchers(where: $where) {
      ...VoucherFields
    }
  }
  ${VoucherFieldsFragment}
`;

export const GET_INTEGRATABLE_VOUCHERS = gql`
  query GetIntegratableVouchers($where: CustomerWhereInput!) {
    getIntegratableVouchers(where: $where) {
      ...VoucherFields
    }
  }
  ${VoucherFieldsFragment}
`;

export const FETCH_INTEGRATED_VOUCHER = gql`
  query FetchIntegratedVoucher(
    $mainWhere: VoucherWhereUniqueInput!
    $allWhere: VoucherWhereInput!
  ) {
    fetchIntegratedVoucher(mainWhere: $mainWhere, allWhere: $allWhere) {
      ...VoucherFields
    }
  }
  ${VoucherFieldsFragment}
`;

export const EDIT_VOUCHER = gql`
  mutation EditVoucher($data: VoucherUpdateInput!, $where: VoucherWhereUniqueInput!) {
    editVoucher(data: $data, where: $where) {
      ...VoucherFields
    }
  }
  ${VoucherFieldsFragment}
`;

export const EDIT_VOUCHER_STATUS = gql`
  mutation EditVoucherStatus($where: VoucherWhereUniqueInput!, $voucherStatus: VoucherStatus!) {
    editVoucherStatus(where: $where, voucherStatus: $voucherStatus) {
      ...VoucherFields
    }
  }
  ${VoucherFieldsFragment}
`;

export const ARCHIVE_VOUCHER = gql`
  mutation ArchiveVoucher($where: VoucherWhereUniqueInput!) {
    archiveVoucher(where: $where) {
      id
    }
  }
`;

export const ADD_VOUCHER_MESSAGE = gql`
  mutation AddVoucherMessage($data: VoucherMessageCreateInput!) {
    addVoucherMessage(data: $data) {
      ...VoucherFields
    }
  }
  ${VoucherFieldsFragment}
`;

export const REMOVE_VOUCHER_MESSAGE = gql`
  mutation RemoveVoucherMessage($where: VoucherMessageWhereUniqueInput!) {
    removeVoucherMessage(where: $where) {
      ...VoucherFields
    }
  }
  ${VoucherFieldsFragment}
`;

export const REMOVE_VOUCHER = gql`
  mutation RemoveVoucher($where: VoucherWhereUniqueInput!) {
    removeVoucher(where: $where) {
      id
    }
  }
`;

export const GET_VOUCHER_FORM_OPTIONS = gql`
  query GetVoucherFormOptions {
    getUsers(where: { isRetired: false }, orderBy: nameKana_ASC) {
      id
      name
      nameKana
      email
    }
    eizoOperations: operations(where: { section: Eizo }, orderBy: order_ASC) {
      ...OperationFields
    }
    onkyoOperations: operations(where: { section: Onkyo }, orderBy: order_ASC) {
      ...OperationFields
    }
    gaigaOperations: operations(where: { section: Gaiga }, orderBy: order_ASC) {
      ...OperationFields
    }
    eigyoOperations: operations(where: { section: Eigyo }, orderBy: order_ASC) {
      ...OperationFields
    }
    otherOperations: operations(where: { section: Other }, orderBy: order_ASC) {
      ...OperationFields
    }
    medias(orderBy: order_ASC) {
      ...MediaFields
    }
    expenses(orderBy: order_ASC) {
      ...ExpenseFields
    }
  }
  ${OperationFieldsFragment}
  ${MediaFieldsFragment}
  ${ExpenseFieldsFragment}
`;

export const SEARCH_VOUCHERS = gql`
  query VoucherList(
    $where: VoucherWhereInput
    $skip: Int
    $first: Int
    $orderBy: VoucherOrderByInput
  ) {
    searchVouchers(where: $where, skip: $skip, first: $first, orderBy: $orderBy) {
      count
      vouchers {
        ...VoucherFieldsOfList
      }
    }
  }
  ${VoucherFieldsOfListFragment}
`;

export const GET_SEARCH_VOUCHER_OPERATIONS_FORM_OPTIONS = gql`
  query GetSearchVoucherOperationsFormOptions {
    eizoOperations: operations(where: { section: Eizo }, orderBy: order_ASC) {
      ...OperationFields
    }
    onkyoOperations: operations(where: { section: Onkyo }, orderBy: order_ASC) {
      ...OperationFields
    }
    gaigaOperations: operations(where: { section: Gaiga }, orderBy: order_ASC) {
      ...OperationFields
    }
    eigyoOperations: operations(where: { section: Eigyo }, orderBy: order_ASC) {
      ...OperationFields
    }
    otherOperations: operations(where: { section: Other }, orderBy: order_ASC) {
      ...OperationFields
    }
  }
  ${OperationFieldsFragment}
`;

export const SEARCH_VOUCHER_OPERATIONS = gql`
  query VoucherOperationList(
    $where: VoucherOperationWhereInput
    $skip: Int
    $first: Int
    $orderBy: VoucherOperationOrderByInput
  ) {
    searchVoucherOperations(where: $where, skip: $skip, first: $first, orderBy: $orderBy) {
      count
      voucherOperations {
        id
        voucher {
          id
          program {
            programNo
          }
          customer {
            name
          }
          title
        }
        date
        operation {
          name
        }
        startHours
        startMinutes
        endHours
        endMinutes
        amount
        unitLabel
        unitPrice
        detail
      }
    }
  }
`;
