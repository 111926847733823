import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import constants from 'myConstants';
import { ProgressContext } from 'contexts';

import MyAppBar from 'elements/MyAppBar';
import MenuDrawer from 'elements/MenuDrawer';

const styles = theme => ({
  root: {
    display: 'flex'
  },
  contentColumn: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    height: '100vh',
    [theme.breakpoints.up(600)]: {
      maxWidth: 'calc(100% - 73px)'
    }
  },
  contentColumnShift: {
    maxWidth: `calc(100% - ${constants.VALUE.DRAWER_WIDTH}px)`
  },
  emptyToolbar: {
    ...theme.mixins.toolbar
  },
  drawerColumn: {
    flex: '0 0 auto'
  },
  contentWrapper: {
    flex: '1 1 0',
    display: 'flex',
    minHeight: 0
  }
});

class Wrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { appBarShiftState: false, progressState: false };
  }

  handleDrawerOpen = () => {
    this.setState({ appBarShiftState: true });
  };

  handleDrawerClose = () => {
    this.setState({ appBarShiftState: false });
  };

  updateProgressState = state => {
    if (state) {
      this.setState({ progressState: true });
    } else {
      setTimeout(() => {
        this.setState({ progressState: false });
      }, 1000);
    }
  };

  render() {
    const { classes, children, appBarText, colorOrange } = this.props;
    const { appBarShiftState, progressState } = this.state;

    return (
      <ProgressContext.Provider
        value={{ progressState, updateProgressState: this.updateProgressState }}
      >
        <div className={classes.root}>
          <MyAppBar
            open={appBarShiftState}
            appBarText={appBarText}
            progressState={progressState}
            colorOrange={colorOrange}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <div className={classes.drawerColumn}>
            <MenuDrawer open={appBarShiftState} handleDrawerClose={this.handleDrawerClose} />
          </div>
          <div
            className={classNames({
              [classes.contentColumn]: true,
              [classes.contentColumnShift]: appBarShiftState
            })}
          >
            <div className={classes.emptyToolbar} />
            <div className={classes.contentWrapper}>{children}</div>
          </div>
        </div>
      </ProgressContext.Provider>
    );
  }
}
const EnhancedWrapper = withStyles(styles)(Wrapper);

const wrapMenu = ({ appBarText, colorOrange }) => Component => {
  class C extends React.Component {
    constructor(props) {
      super(props);
      this.state = { appBarText };
    }

    changeAppBarText = appBarText => {
      this.setState({ appBarText });
    };

    render() {
      return (
        <EnhancedWrapper appBarText={this.state.appBarText} colorOrange={colorOrange}>
          <Component {...this.props} changeAppBarText={this.changeAppBarText} />
        </EnhancedWrapper>
      );
    }
  }

  C.displayName = `wrapMenu(${Component.displayName || Component.name})`;

  return C;
};

export default wrapMenu;
