import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Dashboard from '@material-ui/icons/Dashboard';
import Domain from '@material-ui/icons/Domain';
import Equalizer from '@material-ui/icons/Equalizer';
import SvgIcon from '@material-ui/core/SvgIcon';

import { UserContext } from 'contexts';
import { redirect, logout } from 'browser';
import constants from 'myConstants';
import { getCurrentPeriod } from 'utils/period';

const styles = theme => ({
  root: {
    display: 'flex'
  },
  drawer: {
    width: constants.VALUE.DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: constants.VALUE.DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    display: 'none',
    width: theme.spacing.unit * 7 + 1,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      width: theme.spacing.unit * 9 + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  }
});

const DashboardMenu = () => {
  return (
    <ListItem
      button
      onClick={() => {
        redirect('/dashboard');
      }}
    >
      <ListItemIcon>
        <Dashboard />
      </ListItemIcon>
      <ListItemText primary="ダッシュボード" />
    </ListItem>
  );
};

const WorkReportMenu = () => {
  return (
    <ListItem
      button
      onClick={() => {
        redirect('/reports');
      }}
    >
      <ListItemIcon>
        <SvgIcon>
          <path d="M15.5,17H14L12,9.5L10,17H8.5L6.1,7H7.8L9.34,14.5L11.3,7H12.7L14.67,14.5L16.2,7H17.9M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText primary="ワークレポート" />
    </ListItem>
  );
};

const VoucherMenu = () => {
  return (
    <ListItem
      button
      onClick={() => {
        redirect('/vouchers');
      }}
    >
      <ListItemIcon>
        <SvgIcon>
          <path d="M16,15H9V13H16M19,11H9V9H19M19,7H9V5H19M21,1H7C5.89,1 5,1.89 5,3V17C5,18.11 5.9,19 7,19H21C22.11,19 23,18.11 23,17V3C23,1.89 22.1,1 21,1M3,5V21H19V23H3A2,2 0 0,1 1,21V5H3Z" />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText primary="作業伝票" />
    </ListItem>
  );
};

const AdhocVoucherMenu = () => {
  return (
    <ListItem
      button
      onClick={() => {
        redirect('/provisionalVouchers');
      }}
    >
      <ListItemIcon>
        <SvgIcon>
          <path d="M19.5,14.5A7.5,7.5 0 0,1 12,22A7.5,7.5 0 0,1 4.5,14.5C4.5,10.36 7.86,2 12,2C16.14,2 19.5,10.36 19.5,14.5Z" />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText primary="仮伝票" />
    </ListItem>
  );
};

const ReportOperationMenu = () => {
  return (
    <ListItem
      button
      onClick={() => {
        redirect('/reportOperations');
      }}
    >
      <ListItemIcon>
        <SvgIcon>
          <path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,3C13.76,3 15.4,3.53 16.78,4.41L16.5,5H13L12,5L10.28,4.16L10.63,3.13C11.08,3.05 11.53,3 12,3M9.53,3.38L9.19,4.41L6.63,5.69L5.38,5.94C6.5,4.73 7.92,3.84 9.53,3.38M13,6H16L18.69,9.59L17.44,12.16L14.81,12.78L11.53,8.94L13,6M6.16,6.66L7,10L5.78,13.06L3.22,13.94C3.08,13.31 3,12.67 3,12C3,10.1 3.59,8.36 4.59,6.91L6.16,6.66M20.56,9.22C20.85,10.09 21,11.03 21,12C21,13.44 20.63,14.79 20.03,16H19L18.16,12.66L19.66,9.66L20.56,9.22M8,10H11L13.81,13.28L12,16L8.84,16.78L6.53,13.69L8,10M12,17L15,19L14.13,20.72C13.44,20.88 12.73,21 12,21C10.25,21 8.63,20.5 7.25,19.63L8.41,17.91L12,17M19,17H19.5C18.5,18.5 17,19.67 15.31,20.34L16,19L19,17Z" />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText primary="オペレーション（ワ）" />
    </ListItem>
  );
};

const VoucherOperationMenu = () => {
  return (
    <ListItem
      button
      onClick={() => {
        redirect('/voucherOperations');
      }}
    >
      <ListItemIcon>
        <SvgIcon>
          <path d="M12,2C9.5,2 7.25,2.9 5.5,4.4C6,4.81 6.41,5.27 6.8,5.76L7.89,5.13L8.89,6.87L7.89,7.44C8.45,8.53 8.82,9.73 8.95,11H10V12L10,13H8.95C8.82,14.27 8.45,15.47 7.89,16.56L8.89,17.13L7.89,18.87L6.8,18.24C6.41,18.73 6,19.19 5.5,19.6C7.25,21.1 9.5,22 12,22C14.5,22 16.75,21.1 18.5,19.6C18,19.19 17.59,18.73 17.19,18.24L16.11,18.87L15.11,17.13L16.11,16.55C15.55,15.47 15.18,14.27 15.05,13H14V11H15.05C15.18,9.73 15.55,8.53 16.11,7.45L15.11,6.87L16.11,5.13L17.19,5.76C17.59,5.27 18,4.81 18.5,4.4C16.75,2.9 14.5,2 12,2M4.12,5.85C2.79,7.55 2,9.68 2,12C2,14.32 2.79,16.45 4.12,18.15C4.46,17.87 4.76,17.55 5.05,17.22L4.43,16.87L5.43,15.13L6.16,15.56C6.55,14.77 6.82,13.91 6.93,13H6V12L6,11H6.93C6.82,10.09 6.55,9.23 6.16,8.44L5.43,8.87L4.43,7.13L5.05,6.78C4.76,6.45 4.46,6.13 4.12,5.85M19.88,5.85C19.54,6.13 19.24,6.45 18.95,6.78L19.57,7.13L18.57,8.87L17.84,8.44C17.45,9.23 17.18,10.09 17.07,11H18V13H17.07C17.18,13.91 17.45,14.77 17.84,15.56L18.57,15.13L19.57,16.87L18.95,17.22C19.24,17.55 19.54,17.87 19.88,18.15C21.21,16.45 22,14.32 22,12C22,9.68 21.21,7.55 19.88,5.85Z" />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText primary="オペレーション（作）" />
    </ListItem>
  );
};

const MediaMenu = () => {
  return (
    <ListItem
      button
      onClick={() => {
        redirect('/reportMedias');
      }}
    >
      <ListItemIcon>
        <SvgIcon>
          <path d="M18,9H16V7H18M18,13H16V11H18M18,17H16V15H18M8,9H6V7H8M8,13H6V11H8M8,17H6V15H8M18,3V5H16V3H8V5H6V3H4V21H6V19H8V21H16V19H18V21H20V3H18Z" />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText primary="メディア" />
    </ListItem>
  );
};

const ExpenseMenu = () => {
  return (
    <ListItem
      button
      onClick={() => {
        redirect('/reportExpenses');
      }}
    >
      <ListItemIcon>
        <SvgIcon>
          <path d="M5,20.5A3.5,3.5 0 0,1 1.5,17A3.5,3.5 0 0,1 5,13.5A3.5,3.5 0 0,1 8.5,17A3.5,3.5 0 0,1 5,20.5M5,12A5,5 0 0,0 0,17A5,5 0 0,0 5,22A5,5 0 0,0 10,17A5,5 0 0,0 5,12M14.8,10H19V8.2H15.8L13.86,4.93C13.57,4.43 13,4.1 12.4,4.1C11.93,4.1 11.5,4.29 11.2,4.6L7.5,8.29C7.19,8.6 7,9 7,9.5C7,10.13 7.33,10.66 7.85,10.97L11.2,13V18H13V11.5L10.75,9.85L13.07,7.5M19,20.5A3.5,3.5 0 0,1 15.5,17A3.5,3.5 0 0,1 19,13.5A3.5,3.5 0 0,1 22.5,17A3.5,3.5 0 0,1 19,20.5M19,12A5,5 0 0,0 14,17A5,5 0 0,0 19,22A5,5 0 0,0 24,17A5,5 0 0,0 19,12M16,4.8C17,4.8 17.8,4 17.8,3C17.8,2 17,1.2 16,1.2C15,1.2 14.2,2 14.2,3C14.2,4 15,4.8 16,4.8Z" />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText primary="経費" />
    </ListItem>
  );
};

const CustomerMenu = () => {
  return (
    <ListItem
      button
      onClick={() => {
        redirect('/customers');
      }}
    >
      <ListItemIcon>
        <Domain />
      </ListItemIcon>
      <ListItemText primary="顧客" />
    </ListItem>
  );
};

const PdfMenu = () => {
  return (
    <ListItem
      button
      onClick={() => {
        redirect('/pdfs');
      }}
    >
      <ListItemIcon>
        <SvgIcon>
          <path d="M11.43,10.94C11.2,11.68 10.87,12.47 10.42,13.34C10.22,13.72 10,14.08 9.92,14.38L10.03,14.34V14.34C11.3,13.85 12.5,13.57 13.37,13.41C13.22,13.31 13.08,13.2 12.96,13.09C12.36,12.58 11.84,11.84 11.43,10.94M17.91,14.75C17.74,14.94 17.44,15.05 17,15.05C16.24,15.05 15,14.82 14,14.31C12.28,14.5 11,14.73 9.97,15.06C9.92,15.08 9.86,15.1 9.79,15.13C8.55,17.25 7.63,18.2 6.82,18.2C6.66,18.2 6.5,18.16 6.38,18.09L5.9,17.78L5.87,17.73C5.8,17.55 5.78,17.38 5.82,17.19C5.93,16.66 6.5,15.82 7.7,15.07C7.89,14.93 8.19,14.77 8.59,14.58C8.89,14.06 9.21,13.45 9.55,12.78C10.06,11.75 10.38,10.73 10.63,9.85V9.84C10.26,8.63 10.04,7.9 10.41,6.57C10.5,6.19 10.83,5.8 11.2,5.8H11.44C11.67,5.8 11.89,5.88 12.05,6.04C12.71,6.7 12.4,8.31 12.07,9.64C12.05,9.7 12.04,9.75 12.03,9.78C12.43,10.91 13,11.82 13.63,12.34C13.89,12.54 14.18,12.74 14.5,12.92C14.95,12.87 15.38,12.85 15.79,12.85C17.03,12.85 17.78,13.07 18.07,13.54C18.17,13.7 18.22,13.89 18.19,14.09C18.18,14.34 18.09,14.57 17.91,14.75M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M17.5,14.04C17.4,13.94 17,13.69 15.6,13.69C15.53,13.69 15.46,13.69 15.37,13.79C16.1,14.11 16.81,14.3 17.27,14.3C17.34,14.3 17.4,14.29 17.46,14.28H17.5C17.55,14.26 17.58,14.25 17.59,14.15C17.57,14.12 17.55,14.08 17.5,14.04M8.33,15.5C8.12,15.62 7.95,15.73 7.85,15.81C7.14,16.46 6.69,17.12 6.64,17.5C7.09,17.35 7.68,16.69 8.33,15.5M11.35,8.59L11.4,8.55C11.47,8.23 11.5,7.95 11.56,7.73L11.59,7.57C11.69,7 11.67,6.71 11.5,6.47L11.35,6.42C11.33,6.45 11.3,6.5 11.28,6.54C11.11,6.96 11.12,7.69 11.35,8.59Z" />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText primary="PDF" />
    </ListItem>
  );
};

const StaffMenu = () => {
  return (
    <ListItem
      button
      onClick={() => {
        redirect('/users');
      }}
    >
      <ListItemIcon>
        <SvgIcon>
          <path d="M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M15.6,8.34C16.67,8.34 17.53,9.2 17.53,10.27C17.53,11.34 16.67,12.2 15.6,12.2A1.93,1.93 0 0,1 13.67,10.27C13.66,9.2 14.53,8.34 15.6,8.34M9.6,6.76C10.9,6.76 11.96,7.82 11.96,9.12C11.96,10.42 10.9,11.5 9.6,11.5C8.3,11.5 7.24,10.42 7.24,9.12C7.24,7.81 8.29,6.76 9.6,6.76M9.6,15.89V19.64C7.2,18.89 5.3,17.04 4.46,14.68C5.5,13.56 8.13,13 9.6,13C10.13,13 10.8,13.07 11.5,13.21C9.86,14.08 9.6,15.23 9.6,15.89M12,20C11.72,20 11.46,20 11.2,19.96V15.89C11.2,14.47 14.14,13.76 15.6,13.76C16.67,13.76 18.5,14.15 19.44,14.91C18.27,17.88 15.38,20 12,20Z" />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText primary="スタッフ" />
    </ListItem>
  );
};

const period = getCurrentPeriod();
const StatisticsMenu = () => {
  return (
    <ListItem
      button
      onClick={() => {
        redirect(`/statistics/sales?period=${period.value}`);
      }}
    >
      <ListItemIcon>
        <Equalizer />
      </ListItemIcon>
      <ListItemText primary="統計情報" />
    </ListItem>
  );
};

const SettingMenu = () => {
  return (
    <ListItem
      button
      onClick={() => {
        redirect('/settings');
      }}
    >
      <ListItemIcon>
        <SvgIcon>
          <path d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText primary="設定" />
    </ListItem>
  );
};

const LogoutMenu = () => {
  return (
    <ListItem button onClick={() => logout()}>
      <ListItemIcon>
        <SvgIcon>
          <path d="M14.08,15.59L16.67,13H7V11H16.67L14.08,8.41L15.5,7L20.5,12L15.5,17L14.08,15.59M19,3A2,2 0 0,1 21,5V9.67L19,7.67V5H5V19H19V16.33L21,14.33V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19Z" />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText primary="ログアウト" />
    </ListItem>
  );
};

class MenuDrawer extends React.Component {
  render() {
    const { open, handleDrawerClose, classes, theme } = this.props;
    return (
      <UserContext.Consumer>
        {value => (
          <Drawer
            variant="permanent"
            className={classNames(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })
            }}
            open={open}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </div>
            <Divider />
            <List>
              {value.userLevel === 'User' ? (
                <React.Fragment>
                  <DashboardMenu />
                  <WorkReportMenu />
                  <ReportOperationMenu />
                  <MediaMenu />
                  <ExpenseMenu />
                  <CustomerMenu />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <DashboardMenu />
                  <WorkReportMenu />
                  <VoucherMenu />
                  <AdhocVoucherMenu />
                  <ReportOperationMenu />
                  <VoucherOperationMenu />
                  <MediaMenu />
                  <ExpenseMenu />
                  <PdfMenu />
                  <CustomerMenu />
                </React.Fragment>
              )}
            </List>
            <Divider />
            <List>
              {value.userLevel === 'User' ? (
                <React.Fragment>
                  <LogoutMenu />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <StaffMenu />
                  <StatisticsMenu />
                  <SettingMenu />
                  <LogoutMenu />
                </React.Fragment>
              )}
            </List>
          </Drawer>
        )}
      </UserContext.Consumer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MenuDrawer);
