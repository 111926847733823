import React from 'react';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

const withDebounceInput = (options = { debounceTimeMillisec: 300 }) => Component => {
  class C extends React.Component {
    constructor(props) {
      super(props);

      this.inputRef = React.createRef();
    }

    componentDidMount() {
      const { debounceTimeMillisec } = options;
      const { onDebounce } = this.props;

      this.subscription = fromEvent(this.inputRef.current, 'keyup')
        .pipe(map(i => i.currentTarget.value))
        .pipe(debounceTime(debounceTimeMillisec))
        .subscribe(text => {
          onDebounce(text);
        });
    }

    render() {
      return <Component {...this.props} debounceInputRef={this.inputRef} />;
    }
  }

  C.displayName = `withDebounceInput(${Component.displayName || Component.name})`;

  return C;
};

export default withDebounceInput;
