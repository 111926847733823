import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { newWindow } from 'browser';
import wrapTablePagination from 'hocs/wrapTablePagination';
import enums from 'enums';
import { convertIntoDateFormat } from 'utils/date';
import { numberWithCommas } from 'utils/util';
import { taxPercent } from 'utils/tax';

import SortableTableHead from 'elements/SortableTableHead';

function calcTotalPrice(voucher) {
  return ['voucherOperations', 'voucherMedias', 'voucherExpenses'].reduce((totalPrice, field) => {
    return (
      totalPrice +
      voucher[field].reduce((accumulator, currentValue) => {
        return accumulator + currentValue.unitPrice * currentValue.amount;
      }, 0)
    );
  }, 0);
}

function calcTotalPriceWithTax(voucher) {
  const subtotal = calcTotalPrice(voucher);
  const percent = taxPercent(voucher.billDate);
  const tax = Math.floor(subtotal * percent);
  return subtotal + tax;
}

const columns = [
  {
    id: 'programNo',
    align: 'center',
    disablePadding: true,
    label: 'No.',
    sortable: false,
    width: 48
  },
  {
    id: 'billDate',
    align: 'center',
    disablePadding: true,
    label: '納品日',
    sortable: true,
    width: 100
  },
  {
    id: 'customer',
    align: 'left',
    disablePadding: true,
    label: 'クライアント',
    sortable: false,
    width: 120
  },
  {
    id: 'personnel',
    align: 'left',
    disablePadding: true,
    label: '担当者',
    sortable: false,
    width: 80
  },
  {
    id: 'title',
    align: 'left',
    disablePadding: true,
    label: 'PROGRAM',
    sortable: true,
    width: 200
  },
  {
    id: 'subTitle',
    align: 'left',
    disablePadding: true,
    label: 'サブタイトル',
    sortable: true,
    width: 200
  },
  {
    id: 'totalPrice',
    align: 'center',
    disablePadding: true,
    label: '合計金額',
    sortable: false,
    width: 80
  },
  {
    id: 'totalPriceWithTax',
    align: 'center',
    disablePadding: true,
    label: '税込金額',
    sortable: false,
    width: 80
  },
  {
    id: 'reportStatus',
    align: 'center',
    disablePadding: true,
    label: 'ステータス',
    sortable: true,
    width: 80
  },
  {
    id: 'user',
    align: 'center',
    disablePadding: true,
    label: '管理者',
    sortable: false,
    width: 80
  }
];

const styles = theme => ({
  tableBodyCell: {
    paddingRight: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit / 2
  }
});

class HeadBody extends React.Component {
  handleClick = voucher => {
    newWindow(`/vouchers/${voucher.id}`);
  };

  render() {
    const { classes, order, orderBy, vouchers, handleRequestSort } = this.props;

    return (
      <React.Fragment>
        <SortableTableHead
          order={order}
          orderBy={orderBy}
          columns={columns}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {vouchers.map(voucher => (
            <TableRow
              hover
              onClick={() => this.handleClick(voucher)}
              tabIndex={-1}
              key={voucher.id}
            >
              <TableCell className={classes.tableBodyCell} align="left" component="th" scope="row">
                {voucher.program.programNoSuffix}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {convertIntoDateFormat(voucher.billDate)}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {voucher.customer.name}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {voucher.personnel && voucher.personnel.name}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {voucher.title}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {voucher.subTitle}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="right">
                {numberWithCommas(calcTotalPrice(voucher))}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="right">
                {numberWithCommas(calcTotalPriceWithTax(voucher))}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {enums.VOUCHER_STATUS[voucher.voucherStatus]}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {voucher.user.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </React.Fragment>
    );
  }
}

const EnhancedHeadBody = withStyles(styles)(HeadBody);

export default wrapTablePagination({ fixed: true })(EnhancedHeadBody);
