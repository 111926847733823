import React from 'react';
import queryString from 'query-string';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';

import { RESET_PASSWORD_STEP2 } from 'gqls/user';
import { storeToken, redirect } from 'browser';

import ResetPasswordStep2Form from 'forms/ResetPasswordStep2Form';

const styles = theme => ({
  main: {
    width: 800,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

class ResetPasswordStep2 extends React.Component {
  handleSucceed = ({ data }) => {
    const { resetPasswordStep2 } = data;
    storeToken(resetPasswordStep2);
    redirect('/dashboard');
  };

  render() {
    const { classes, mutate } = this.props;

    const params = queryString.parse(this.props.location.search);

    if (!params.resetKey) throw new Error();

    return (
      <main className={classes.main}>
        <ResetPasswordStep2Form
          resetKey={params.resetKey}
          mutate={mutate}
          onSucceed={this.handleSucceed}
        />
      </main>
    );
  }
}

export default compose(
  withStyles(styles),
  graphql(RESET_PASSWORD_STEP2)
)(ResetPasswordStep2);
