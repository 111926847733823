import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';
import * as yup from 'yup';

import { ProgressContext } from 'contexts';
import { NEW_VOUCHER } from 'gqls/program';
import { redirect } from 'browser';
import { voucherNewInitialValues } from 'initialValues';
import { hoursField, minutesField } from 'schemas';

import VoucherForm from 'forms/VoucherForm';

export const styles = theme => ({
  main: {
    flex: '1 1 auto',
    paddingTop: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  }
});

export const schema = yup.object().shape({
  customerRelatedId: yup.string().required(),
  personnelRelatedId: yup.string(),
  userRelatedId: yup.string().required(),
  reportOperations: yup.array().of(
    yup.object().shape({
      date: yup.date().required(),
      section: yup.string(),
      operationRelatedId: yup.string(),
      startHours: hoursField,
      startMinutes: minutesField,
      endHours: hoursField,
      endMinutes: minutesField,
      unitLabel: yup.string(),
      amount: yup.string(),
      userRelatedId: yup.string(),
      charge: yup.string(),
      rating: yup.string(),
      detail: yup.string()
    })
  ),
  title: yup.string().required(),
  subTitle: yup.string(),
  memo: yup.string(),
  billDate: yup.string().required()
});

class NewVoucher extends React.Component {
  onSucceed = ({ data }) => {
    const {
      addVoucher: { id }
    } = data;
    redirect(`/vouchers/${id}`);
  };

  render() {
    const { classes, mutate } = this.props;

    return (
      <ProgressContext.Consumer>
        {({ updateProgressState }) => (
          <main className={classes.main}>
            <VoucherForm
              action="NewVoucher"
              schema={schema}
              initialValues={voucherNewInitialValues}
              mutate={mutate}
              onSucceed={this.onSucceed}
              updateProgressState={updateProgressState}
            />
          </main>
        )}
      </ProgressContext.Consumer>
    );
  }
}

export default compose(
  withStyles(styles),
  graphql(NEW_VOUCHER)
)(NewVoucher);
