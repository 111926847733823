import moment from 'moment';

import {
  reportEditInitialValues,
  reportOperationInitialValues,
  reportExpenseInitialValues,
  reportMediaInitialValues,
  voucherNewInitialValues,
  voucherEditInitialValues,
  voucherOperationInitialValues,
  voucherExpenseInitialValues,
  voucherMediaInitialValues
} from 'initialValues';
import {
  mergeInitialValues,
  differenceObject,
  relateFields,
  takeHasManyFieldValue
} from 'utils/form';
import { timeSpan } from 'utils/date';

const convertOneToOneRelationFieldsIntoForm = ({ ...values }) => {
  const { program, user, customer, personnel } = values;

  values.programRelatedId = program.id;
  values.programNo = program.programNo;
  values.userRelatedId = user.id;
  values.customerRelatedId = customer.id;

  if (personnel) {
    values.personnelRelatedId = personnel.id;
  }

  delete values.program;
  delete values.user;
  delete values.customer;
  delete values.personnel;

  return values;
};

const convertStatusDateFieldsIntoForm = ({ ...values }) => {
  if (values.registerUser) {
    values.registerUserRelatedId = values.registerUser.id;
    delete values.registerUser;
  }

  if (values.checkUser) {
    values.checkUserRelatedId = values.checkUser.id;
    delete values.checkUser;
  }

  if (values.mailUser) {
    values.mailUserRelatedId = values.mailUser.id;
    delete values.mailUser;
  }

  if (values.fixUser) {
    values.fixUserRelatedId = values.fixUser.id;
    delete values.fixUser;
  }

  if (values.demandUser) {
    values.demandUserRelatedId = values.demandUser.id;
    delete values.demandUser;
  }

  if (values.completeUser) {
    values.completeUserRelatedId = values.completeUser.id;
    delete values.completeUser;
  }

  return values;
};

export const convertOperationTimeFieldsIntoForm = ({ ...values }) => {
  if (values.unitLabel === 'Hour') {
    const { startHours, startMinutes, endHours, endMinutes } = values;
    values.timeSpan = timeSpan(startHours, startMinutes, endHours, endMinutes);
  } else {
    values.startHours = '';
    values.startMinutes = '';
    values.endHours = '';
    values.endMinutes = '';
    values.timeSpan = 0;
  }
  return values;
};

export const convertIntoReportForm = ({ ...baseVaules }) => {
  const values = convertOneToOneRelationFieldsIntoForm(baseVaules);

  values.reportOperations = values.reportOperations.map(operation =>
    convertIntoReportOperationForm(operation)
  );

  values.reportMedias = values.reportMedias.map(media => convertIntoReportMediaForm(media));

  values.reportExpenses = values.reportExpenses.map(expense =>
    convertIntoReportExpenseForm(expense)
  );

  return mergeInitialValues(reportEditInitialValues, values);
};

export const convertIntoReportOperationForm = ({ ...values }) => {
  const { operation, user } = values;

  if (operation) values.operationRelatedId = operation.id;
  if (user) values.userRelatedId = user.id;

  delete values.user;

  values.date = moment(values.date).format('YYYY-MM-DD');

  values = convertOperationTimeFieldsIntoForm(values);

  return mergeInitialValues(reportOperationInitialValues, values);
};

export const convertIntoReportMediaForm = ({ ...values }) => {
  const { user, media } = values;

  if (user) values.userRelatedId = user.id;
  if (media) values.mediaRelatedId = media.id;

  delete values.user;
  delete values.media;

  values.date = moment(values.date).format('YYYY-MM-DD');
  if (values.check) values.check = true;

  return mergeInitialValues(reportMediaInitialValues, values);
};

export const convertIntoReportExpenseForm = ({ ...values }) => {
  const { user, expense } = values;

  if (user) values.userRelatedId = user.id;
  if (expense) values.expenseRelatedId = expense.id;

  delete values.user;

  values.price = Math.floor(values.amount * values.unitPrice);
  values.date = moment(values.date).format('YYYY-MM-DD');
  if (values.check) values.check = true;

  return mergeInitialValues(reportExpenseInitialValues, values);
};

export const convertIntoEditReportGql = ({ ...values }, initialValues) => {
  const diff = differenceObject(values, initialValues);

  const { id, ...rest } = diff;

  let newValues;
  newValues = relateFields(rest, initialValues);

  if (newValues.reportOperations) {
    newValues.reportOperations = takeHasManyFieldValue(
      newValues.reportOperations,
      initialValues.reportOperations,
      convertIntoReportOperationGql
    );
  }

  if (newValues.reportMedias) {
    newValues.reportMedias = takeHasManyFieldValue(
      newValues.reportMedias,
      initialValues.reportMedias,
      convertIntoReportMediaGql
    );
  }

  if (newValues.reportExpenses) {
    newValues.reportExpenses = takeHasManyFieldValue(
      newValues.reportExpenses,
      initialValues.reportExpenses,
      convertIntoReportExpenseGql
    );
  }

  return newValues;
};

export const convertIntoReportOperationGql = ({ ...values }) => {
  Object.entries(values).forEach(([name, value]) => {
    switch (name) {
      case 'section':
      case 'operation':
      case 'charge':
      case 'systemCheck':
        if (value === '') values[name] = null;
        break;
      case 'startHours':
      case 'endHours':
      case 'startMinutes':
      case 'endMinutes':
        values[name] = value !== null && value !== '' ? +value : null;
        break;
      case 'rating':
      case 'amount':
        values[name] = value !== '' ? +value : null;
        break;
      case 'timeSpan':
        delete values[name];
        break;
      default:
        break;
    }
  });

  if (values.unitLabel === 'Hour') {
    ['startHours', 'endHours'].forEach(ele => {
      if (values[ele] === undefined) values[ele] = 10;
    });

    ['startMinutes', 'endMinutes'].forEach(ele => {
      if (values[ele] === undefined) values[ele] = 0;
    });
  }

  return values;
};

export const convertIntoReportExpenseGql = ({ ...values }) => {
  Object.entries(values).forEach(([name, value]) => {
    switch (name) {
      case 'systemCheck':
        if (value === '') values[name] = null;
        break;
      case 'check':
        values[name] = value ? 'Check' : null;
        break;
      case 'price':
        delete values[name];
        break;
      default:
        break;
    }
  });
  return values;
};

export const convertIntoReportMediaGql = ({ ...values }) => {
  Object.entries(values).forEach(([name, value]) => {
    switch (name) {
      case 'media':
      case 'mediaType':
      case 'base':
      case 'systemCheck':
        if (value === '') values[name] = null;
        break;
      case 'check':
        values[name] = value ? 'Check' : null;
        break;
      default:
        break;
    }
  });
  return values;
};

export const convertIntoNewVoucherForm = ({ ...baseValues }, customer) => {
  const values = convertOneToOneRelationFieldsIntoForm(baseValues);

  delete values.id;

  delete values.isNoMedia;
  delete values.isNoExpense;

  delete values.registerDate;
  delete values.registerUser;
  delete values.finishDate;
  delete values.finishUser;
  delete values.checkDate;
  delete values.checkUser;
  delete values.approveDate;
  delete values.approveUser;
  delete values.completeDate;
  delete values.completeUser;

  delete values.reportStatus;

  values.voucherStatus = 'Registered';

  values.voucherOperations = values.reportOperations.reduce((accumulator, currentValue) => {
    if (currentValue.systemCheck === 'Check') {
      accumulator.push(convertIntoNewVoucherOperationForm(currentValue, customer));
    }
    return accumulator;
  }, []);

  values.voucherMedias = values.reportMedias.reduce((accumulator, currentValue) => {
    if (currentValue.systemCheck === 'Check') {
      accumulator.push(convertIntoNewVoucherMediaForm(currentValue));
    }
    return accumulator;
  }, []);

  values.voucherExpenses = values.reportExpenses.reduce((accumulator, currentValue) => {
    if (currentValue.systemCheck === 'Check') {
      accumulator.push(convertIntoNewVoucherExpenseForm(currentValue));
    }
    return accumulator;
  }, []);

  delete values.reportOperations;
  delete values.reportExpenses;
  delete values.reportMedias;

  return mergeInitialValues(voucherNewInitialValues, values);
};

export const convertIntoNewVoucherOperationForm = ({ ...values }, customer) => {
  const { operation } = values;

  if (operation) {
    values.operationRelatedId = operation.id;

    switch (operation.applyUnitPrice) {
      case 'EedPrice':
        values.unitPrice = customer.eedPrice;
        break;
      case 'MaPrice':
        values.unitPrice = customer.maPrice;
        break;
      default:
        values.unitPrice = 0;
        break;
    }
  } else {
    values.operationRelatedId = '';
    values.unitPrice = 0;
  }

  delete values.id;
  delete values.user;
  delete values.section;
  delete values.operation;

  values.date = moment(values.date).format('YYYY-MM-DD');

  values = convertOperationTimeFieldsIntoForm(values);

  values.price = Math.floor(values.amount * values.unitPrice);

  delete values.charge;
  delete values.rating;
  delete values.systemCheck;
  delete values.sortOrder;

  return mergeInitialValues(voucherOperationInitialValues, values);
};

export const convertIntoNewVoucherMediaForm = ({ ...values }) => {
  const { media } = values;

  if (media) {
    values.mediaRelatedId = media.id;
    values.unitPrice = media.unitPrice;
  } else {
    values.mediaRelatedId = '';
    values.unitPrice = 0;
  }

  delete values.id;
  delete values.user;
  delete values.media;

  values.price = Math.floor(values.amount * values.unitPrice);
  values.date = moment(values.date).format('YYYY-MM-DD');

  delete values.mediaType;
  delete values.base;
  delete values.systemCheck;
  delete values.check;
  delete values.sortOrder;

  return mergeInitialValues(voucherMediaInitialValues, values);
};

export const convertIntoNewVoucherExpenseForm = ({ ...values }) => {
  const { expense } = values;

  if (expense) {
    values.expenseRelatedId = expense.id;
  } else {
    values.expenseRelatedId = '';
  }

  delete values.id;
  delete values.user;
  delete values.expense;

  values.price = Math.floor(values.amount * values.unitPrice);
  values.date = moment(values.date).format('YYYY-MM-DD');

  delete values.systemCheck;
  delete values.check;
  delete values.sortOrder;

  return mergeInitialValues(voucherExpenseInitialValues, values);
};

export const convertIntoEditVoucherForm = ({ ...baseValues }) => {
  const values = convertOneToOneRelationFieldsIntoForm(baseValues);

  values.billDate = moment(values.billDate).format('YYYY-MM-DD');

  values.voucherOperations = values.voucherOperations.map(operation =>
    convertIntoEditVoucherOperationForm(operation)
  );

  values.voucherMedias = values.voucherMedias.map(media => convertIntoEditVoucherMediaForm(media));

  values.voucherExpenses = values.voucherExpenses.map(expense =>
    convertIntoEditVoucherExpenseForm(expense)
  );

  return mergeInitialValues(voucherEditInitialValues, values);
};

export const convertIntoIntegratedVoucherForm = ({ ...baseValues }) => {
  let values = convertIntoEditVoucherForm(baseValues);
  values = convertStatusDateFieldsIntoForm(values);

  delete values.id;
  delete values.programRelatedId;
  delete values.programNo;

  values.voucherOperations = values.voucherOperations.map(operation => {
    delete operation.id;
    return operation;
  });

  values.voucherMedias = values.voucherMedias.map(media => {
    delete media.id;
    return media;
  });

  values.voucherExpenses = values.voucherExpenses.map(expense => {
    delete expense.id;
    return expense;
  });

  return values;
};

export const convertIntoEditVoucherOperationForm = ({ ...values }) => {
  if (values.operation) {
    values.operationRelatedId = values.operation.id;
  } else {
    values.operationRelatedId = '';
  }
  delete values.operation;

  values.price = Math.floor(values.amount * values.unitPrice);
  values.date = moment(values.date).format('YYYY-MM-DD');

  values = convertOperationTimeFieldsIntoForm(values);

  return mergeInitialValues(voucherOperationInitialValues, values);
};

export const convertIntoEditVoucherMediaForm = ({ ...values }) => {
  if (values.media) {
    values.mediaRelatedId = values.media.id;
  } else {
    values.mediaRelatedId = '';
  }
  delete values.media;

  values.price = Math.floor(values.amount * values.unitPrice);
  values.date = moment(values.date).format('YYYY-MM-DD');

  return mergeInitialValues(voucherMediaInitialValues, values);
};

export const convertIntoEditVoucherExpenseForm = ({ ...values }) => {
  if (values.expense) {
    values.expenseRelatedId = values.expense.id;
  } else {
    values.expenseRelatedId = '';
  }
  delete values.expense;

  values.price = Math.floor(values.amount * values.unitPrice);
  values.date = moment(values.date).format('YYYY-MM-DD');

  return mergeInitialValues(voucherExpenseInitialValues, values);
};

export const convertIntoNewVoucherGql = ({ ...values }) => {
  let newValues = relateFields(values, voucherNewInitialValues);

  newValues.voucherOperations = takeHasManyFieldValue(
    newValues.voucherOperations,
    [],
    convertIntoVoucherOperationGql
  );
  delete newValues.voucherOperations.update;
  delete newValues.voucherOperations.delete;

  newValues.voucherExpenses = takeHasManyFieldValue(
    newValues.voucherExpenses,
    [],
    convertIntoVoucherExpenseGql
  );
  delete newValues.voucherExpenses.update;
  delete newValues.voucherExpenses.delete;

  newValues.voucherMedias = takeHasManyFieldValue(
    newValues.voucherMedias,
    [],
    convertIntoVoucherMediaGql
  );
  delete newValues.voucherMedias.update;
  delete newValues.voucherMedias.delete;

  delete newValues.programNo;

  return newValues;
};

export const convertIntoEditVoucherGql = ({ ...values }, initialValues) => {
  const diff = differenceObject(values, initialValues);

  const { id, ...rest } = diff;

  let newValues = relateFields(rest, initialValues);

  if (newValues.voucherOperations) {
    newValues.voucherOperations = takeHasManyFieldValue(
      newValues.voucherOperations,
      initialValues.voucherOperations,
      convertIntoVoucherOperationGql
    );
  }

  if (newValues.voucherExpenses) {
    newValues.voucherExpenses = takeHasManyFieldValue(
      newValues.voucherExpenses,
      initialValues.voucherExpenses,
      convertIntoVoucherExpenseGql
    );
  }

  if (newValues.voucherMedias) {
    newValues.voucherMedias = takeHasManyFieldValue(
      newValues.voucherMedias,
      initialValues.voucherMedias,
      convertIntoVoucherMediaGql
    );
  }

  delete newValues.programNo;

  return newValues;
};

export const convertIntoVoucherOperationGql = ({ ...values }) => {
  Object.entries(values).forEach(([name, value]) => {
    switch (name) {
      case 'startHours':
      case 'endHours':
      case 'startMinutes':
      case 'endMinutes':
        values[name] = value !== null && value !== '' ? +value : null;
        break;
      case 'amount':
        values[name] = value !== '' ? +value : null;
        break;
      case 'timeSpan':
      case 'price':
        delete values[name];
        break;
      default:
        break;
    }
  });

  if (values.unitLabel === 'Hour') {
    ['startHours', 'endHours'].forEach(ele => {
      if (values[ele] === undefined) values[ele] = 10;
    });

    ['startMinutes', 'endMinutes'].forEach(ele => {
      if (values[ele] === undefined) values[ele] = 0;
    });
  }

  return values;
};

export const convertIntoVoucherExpenseGql = ({ ...values }) => {
  Object.entries(values).forEach(([name]) => {
    switch (name) {
      case 'price':
        delete values[name];
        break;
      default:
        break;
    }
  });
  return values;
};

export const convertIntoVoucherMediaGql = ({ ...values }) => {
  Object.entries(values).forEach(([name]) => {
    switch (name) {
      case 'price':
        delete values[name];
        break;
      default:
        break;
    }
  });
  return values;
};

export const appendProgramNoSuffix = ({ ...voucher }) => {
  const r = voucher.revision !== 1 ? true : false;
  const g = voucher.program.integratedFroms.length > 0 ? true : false;
  const suffix = `${r || g ? '-' : ''}${r ? 'R' : ''}${g ? 'G' : ''}`;
  voucher.program.programNoSuffix = `${voucher.program.programNo}${suffix}`;
  return voucher;
};
