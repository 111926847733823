import React from 'react';
import Mustache from 'mustache';
import { withFormik } from 'formik';
import { compose, graphql, withApollo } from 'react-apollo';
import { find } from 'lodash';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { SEND_MAIL } from 'gqls/mail';
import withDropzonefrom from 'hocs/withDropzone';
import withDraftEditor from 'hocs/withDraftEditor';
import { fileTob64 } from 'utils/file';
import { takeOption, convertIntoSelectFieldValues } from 'utils/form';
import { convertIntoDateFormat } from 'utils/date';
import { convertIntoPreviewPdfGql, convertIntoSendMailGql } from 'formConvertors/mail';
import { PREVIEW_PDF } from 'gqls/mail';
import { confirm, redirect } from 'browser';
import { notifyError } from 'notifications';

import MyTextField from 'elements/MyTextField';
import SelectFiles from 'elements/SelectFiles';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit,
    padding: theme.spacing.unit * 2
  },
  form: {
    marginTop: theme.spacing.unit
  },
  menu: {
    width: 200
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing.unit / 4
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center'
  },
  previewWrapper: {
    position: 'relative',
    height: 400
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

class SendMailForm extends React.Component {
  state = {
    selectVouchersFieldShowState: false,
    previewPdfBlobUrl: ''
  };

  renderMailContent = (mailTemplateId, personnelId) => {
    const { values, voucher, mailTemplates, personnels, user, setFieldValue } = this.props;

    const mailTemplate = find(mailTemplates, { id: mailTemplateId });
    const personnel = find(personnels, { id: personnelId });

    if (!mailTemplate || !personnel) return '';

    let name;
    if (voucher.customer.customerType === 'Individual') {
      name = `${voucher.customer.name} 様`;
    } else {
      name = `${voucher.customer.name} ${personnel.name} 様`;
    }

    const params = {
      date: moment(values.date).format('YYYY年M月D日'),
      name,
      fromName: user.name,
      fromEmail: user.email
    };

    setFieldValue('text', Mustache.render(mailTemplate.body, params));
  };

  previewPdf = overwrite => {
    const { values, client } = this.props;

    const params = convertIntoPreviewPdfGql(values, overwrite);

    client
      .query({
        query: PREVIEW_PDF,
        variables: {
          data: params
        }
      })
      .then(({ data }) => {
        const { previewPdfFromVouchers } = data;

        this.setState({
          previewPdfBlobUrl: `data:application/pdf;base64,${previewPdfFromVouchers}`
        });
      })
      .catch(() => {});
  };

  handleDateChange = e => {
    const { mailTemplates, values, handleChange, setFieldValue } = this.props;

    if (values.mailTemplate) {
      const baseMailTemplate = find(mailTemplates, { id: values.mailTemplate });
      const params = {
        date: moment(e.target.value).format('M月D日')
      };
      setFieldValue('subject', Mustache.render(baseMailTemplate.subject, params));
    }

    handleChange(e);
  };

  createHandleBlur = field => e => {
    const { values, handleBlur } = this.props;
    handleBlur(e);

    if (values.mailTemplate) {
      this.previewPdf({ [field]: e.target.value });
      this.renderMailContent(values.mailTemplate, values.personnel);
    }
  };

  handleMailTemplateChange = e => {
    const {
      voucher,
      mailTemplates,
      values,
      handleChange,
      setFieldValue,
      setFieldTouched
    } = this.props;

    handleChange(e);

    const mailTemplate = find(mailTemplates, { id: e.target.value });

    if (mailTemplate.pdfType === '' || mailTemplate.pdfType === 'Estimate') {
      this.setState({ selectVouchersFieldShowState: false });
    } else {
      this.setState({ selectVouchersFieldShowState: true });
    }

    switch (mailTemplate.pdfType) {
      case 'Specification':
        setFieldValue('ccs', 'keiri@salmix.co.jp');
        setFieldTouched('ccs', true);
        break;
      case 'Bill':
        setFieldValue('from', 'keiri@salmix.co.jp');
        setFieldTouched('from', true);
        setFieldValue('bccs', 'keiri@salmix.co.jp');
        setFieldTouched('bccs', true);
        break;
      default:
        break;
    }

    this.renderMailContent(e.target.value, values.personnel);

    const params = {
      date: moment(values.date).format('M月D日')
    };
    setFieldValue('subject', Mustache.render(mailTemplate.subject, params));
    setFieldTouched('subject', true);

    setFieldValue('pdfType', mailTemplate.pdfType);
    setFieldTouched('pdfType', true);

    if (mailTemplate.title) {
      setFieldValue('title', mailTemplate.title);
      setFieldTouched('title', true);
    }

    if (mailTemplate.greeting) {
      setFieldValue('greeting', mailTemplate.greeting);
      setFieldTouched('greeting', true);
    }

    if (mailTemplate.payment) {
      setFieldValue('payment', mailTemplate.payment);
      setFieldTouched('payment', true);
    }

    if (mailTemplate.pdfType) {
      setFieldValue('allWhere', [voucher.id]);
      setFieldTouched('allWhere', true);

      this.previewPdf({
        pdfType: mailTemplate.pdfType,
        title: mailTemplate.title,
        greeting: mailTemplate.greeting,
        payment: mailTemplate.payment
      });
    }
  };

  handlePersonnelChange = e => {
    const { personnels, values, handleChange, setFieldValue, setFieldTouched } = this.props;
    handleChange(e);

    this.renderMailContent(values.mailTemplate, e.target.value);

    const personnel = find(personnels, { id: e.target.value });

    setFieldValue('tos', personnel.email);
    setFieldTouched('tos', true);
  };

  handleAllWhereChange = e => {
    const { handleChange } = this.props;
    handleChange(e);

    this.previewPdf({
      allWhere: e.target.value
    });
  };

  render() {
    const { classes, values, errors, touched, handleChange, handleBlur, handleSubmit } = this.props;
    const { files, onDrop, onCancel, onDelete } = this.props;
    const { otherVouchers, mailTemplates, personnels } = this.props;
    const { previewPdfBlobUrl } = this.state;

    const otherVoucherOptions = convertIntoSelectFieldValues(otherVouchers);

    const mailTemplate = find(mailTemplates, { id: values.mailTemplate });

    return (
      <Paper className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <MyTextField
                name="mailTemplate"
                values={values}
                touched={touched}
                errors={errors}
                label="メールテンプレート"
                onChange={this.handleMailTemplateChange}
                onBlur={handleBlur}
                required
                fullWidth
                select
              >
                {mailTemplates.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="personnel"
                values={values}
                touched={touched}
                errors={errors}
                label="担当者"
                onChange={this.handlePersonnelChange}
                onBlur={handleBlur}
                required
                fullWidth
                select
              >
                {personnels.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>
            {this.state.selectVouchersFieldShowState && (
              <Grid item xs={12}>
                <MyTextField
                  name="allWhere"
                  values={values}
                  touched={touched}
                  errors={errors}
                  label="まとめる作業伝票"
                  onChange={this.handleAllWhereChange}
                  onBlur={handleBlur}
                  SelectProps={{
                    multiple: true,
                    MenuProps: {
                      className: classes.menu
                    },
                    renderValue: selected => (
                      <div className={classes.chips}>
                        {selected.map(value => {
                          const v = takeOption(otherVoucherOptions, value);

                          if (v === undefined) return null;

                          const label = `${v.program.programNo} ${v.title} ${convertIntoDateFormat(
                            v.billDate
                          )} ${v.personnel.name}`;

                          return <Chip key={value} label={label} className={classes.chip} />;
                        })}
                      </div>
                    )
                  }}
                  fullWidth
                  select
                >
                  {otherVoucherOptions.map(option => {
                    if (option === undefined) return null;

                    const label = `${option.program.programNo} ${
                      option.title
                    } ${convertIntoDateFormat(option.billDate)} ${option.personnel.name}`;

                    return (
                      <MenuItem key={option.id} value={option.id}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </MyTextField>
              </Grid>
            )}
            <Grid item xs={6}>
              <MyTextField
                name="from"
                values={values}
                touched={touched}
                errors={errors}
                label="From"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="tos"
                values={values}
                touched={touched}
                errors={errors}
                label="TO（カンマで複数可）"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="ccs"
                values={values}
                touched={touched}
                errors={errors}
                label="CC（カンマで複数可）"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="bccs"
                values={values}
                touched={touched}
                errors={errors}
                label="BCC（カンマで複数可）"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MyTextField
                name="subject"
                values={values}
                touched={touched}
                errors={errors}
                label="件名"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFiles files={files} onDrop={onDrop} onCancel={onCancel} onDelete={onDelete} />
            </Grid>
            <Grid item xs={12}>
              <MyTextField
                name="text"
                values={values}
                touched={touched}
                errors={errors}
                label="メール内容"
                multiline
                rows="10"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                type="date"
                name="date"
                values={values}
                touched={touched}
                errors={errors}
                label="日付"
                onChange={this.handleDateChange}
                onBlur={this.createHandleBlur('date')}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="title"
                values={values}
                touched={touched}
                errors={errors}
                label="書類名"
                onChange={handleChange}
                onBlur={this.createHandleBlur('title')}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="greeting"
                values={values}
                touched={touched}
                errors={errors}
                label="備考"
                multiline
                rows="3"
                onChange={handleChange}
                onBlur={this.createHandleBlur('greeting')}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="payment"
                values={values}
                touched={touched}
                errors={errors}
                label="お支払いに関して"
                multiline
                rows="3"
                onChange={handleChange}
                onBlur={this.createHandleBlur('payment')}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="companyStamp"
                    checked={values.companyStamp}
                    onChange={handleChange}
                    onBlur={this.createHandleBlur('companyStamp')}
                    color="primary"
                  />
                }
                label="PDFに印鑑を入れる"
              />
            </Grid>
            <Grid item xs={12}>
              {mailTemplate && mailTemplate.pdfType !== '' && previewPdfBlobUrl && (
                <div className={classes.previewWrapper}>
                  <embed
                    src={previewPdfBlobUrl}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                  />
                </div>
              )}
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button type="submit" variant="contained" color="primary" className={classes.submit}>
              送信
            </Button>
          </div>
        </form>
      </Paper>
    );
  }
}

export default compose(
  withStyles(styles),
  withDropzonefrom(),
  withDraftEditor(),
  withApollo,
  graphql(SEND_MAIL),
  withFormik({
    mapPropsToValues: props => props.initialValues,
    validationSchema: props => props.schema,
    handleSubmit: async (values, { props, setSubmitting }) => {
      const { voucher, files, mutate } = props;

      if (!confirm('送信してもよろしいですか？')) {
        setSubmitting(false);
        return;
      }

      const bFiles = [];
      for (let i = 0; i < files.length; i++) {
        const name = files[i].name;
        const bFile = await fileTob64(files[i]);
        bFiles.push({
          filename: name,
          content: bFile
        });
      }

      const newValues = convertIntoSendMailGql(values, bFiles);

      const variables = {
        data: newValues
      };

      console.log(newValues);

      mutate({
        variables
      })
        .then(() => {
          redirect(`/vouchers/${voucher.id}`);
        })
        .catch(error => {
          notifyError('送信エラーが発生しました。');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    displayName: 'SendMailForm'
  })
)(SendMailForm);
