import React from 'react';
import { compose, graphql } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  EXPENSE_LIST,
  ADD_EXPENSE,
  EDIT_EXPENSE,
  REMOVE_EXPENSE,
  ARRANGE_EXPENSES
} from 'gqls/expense';
import wrapDragDropList from 'hocs/wrapDragDropList';
import wrapMenu from 'hocs/wrapMenu';

import ExpenseForm from 'forms/ExpenseForm';

const DragDropList = compose(
  graphql(ADD_EXPENSE, { name: 'addExpense' }),
  graphql(EDIT_EXPENSE, { name: 'editExpense' }),
  graphql(REMOVE_EXPENSE, { name: 'removeExpense' }),
  graphql(ARRANGE_EXPENSES, { name: 'arrangeExpenses' }),
  wrapDragDropList({
    label: '経費',
    defaultValues: {
      name: ''
    },
    convertIntoForm: values => values,
    convertIntoGql: ({ ...values }) => {
      return values;
    },
    handleAddName: 'addExpense',
    handleEditName: 'editExpense',
    handleRemoveName: 'removeExpense',
    handleArrangeName: 'arrangeExpenses'
  })
)(ExpenseForm);

const styles = theme => ({
  main: {
    width: 600,
    marginTop: theme.spacing.unit * 8,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 10,
    right: theme.spacing.unit * 10
  }
});

class ExpenseList extends React.Component {
  render() {
    const { classes, data } = this.props;

    if (data.loading) return null;

    return (
      <main className={classes.main}>
        <DragDropList list={data.expenses} />
      </main>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  wrapMenu({ appBarText: '経費マスタ' }),
  graphql(EXPENSE_LIST)
)(ExpenseList);
