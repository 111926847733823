import React from 'react';
import { compose } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';
import { groupBy, sumBy } from 'lodash';

import { GET_MONTHLY_EARNINGS } from 'gqls/statistics';
import withUbs from 'hocs/withUbs';

import SearchSaleForm from 'forms/SearchSaleForm';
import SaleTable from 'elements/SaleTable';

const styles = theme => ({
  root: {}
});

const defaultSearchParams = {
  order: 'desc',
  orderBy: 'date',
  page: '0',
  rowsPerPage: '50'
};

class Sales extends React.Component {
  render() {
    const { classes } = this.props;
    const { list, updateSearchParams } = this.props;

    const sumSummary = sumBy(list, 'summary');
    const sumCount = sumBy(list, 'count');

    const values = groupBy(list, value => {
      return value.yearMonth.slice(0, 4);
    });

    return (
      <div className={classes.root}>
        <SearchSaleForm updateSearchParams={updateSearchParams} />
        <SaleTable values={values} sumSummary={sumSummary} sumCount={sumCount} />
      </div>
    );
  }
}

const convert = ({ params }) => {
  const searchParams = {};

  Object.entries(params).forEach(([key, value]) => {
    switch (key) {
      case 'period':
        searchParams['term'] = +value;
        break;
      case 'customerRelatedId':
        searchParams['customerId'] = value;
        break;
      default:
        throw new Error();
    }
  });

  return searchParams;
};

export default compose(
  withStyles(styles),
  withUbs({
    defaultSearchParams,
    gql: GET_MONTHLY_EARNINGS,
    convertGraphqlParams: convert,
    takeResult: ({ data }) => {
      const { getMonthlyEarnings } = data;
      return {
        list: getMonthlyEarnings,
        count: 0
      };
    },
    useWhere: false
  })
)(Sales);
