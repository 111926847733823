import React from 'react';
import queryString from 'query-string';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';

import { ProgressContext } from 'contexts';
import { FETCH_INTEGRATED_VOUCHER, NEW_INTEGRATED_VOUCHER } from 'gqls/program';
import { convertIntoIntegratedVoucherForm } from 'formConvertors/program';
import { redirect } from 'browser';
import { styles, schema } from './NewVoucherPurelyScreen';

import VoucherForm from 'forms/VoucherForm';

class NewVoucher extends React.Component {
  onSucceed = ({ data }) => {
    const {
      addIntegratedVoucher: { id }
    } = data;
    redirect(`/vouchers/${id}`);
  };

  render() {
    const { location, classes, data, mutate } = this.props;

    if (data.loading) return null;

    const params = queryString.parse(location.search);

    const customer = data.fetchIntegratedVoucher.customer;
    const initialValues = convertIntoIntegratedVoucherForm(data.fetchIntegratedVoucher);

    return (
      <ProgressContext.Consumer>
        {({ updateProgressState }) => (
          <main className={classes.main}>
            <VoucherForm
              action="NewVoucherByOthers"
              schema={schema}
              initialValues={initialValues}
              mutate={mutate}
              onSucceed={this.onSucceed}
              updateProgressState={updateProgressState}
              customer={customer}
              fromVoucherIds={params.allVoucherIds}
            />
          </main>
        )}
      </ProgressContext.Consumer>
    );
  }
}

export default compose(
  withStyles(styles),
  graphql(FETCH_INTEGRATED_VOUCHER, {
    options: props => {
      const params = queryString.parse(props.location.search);
      return {
        variables: {
          mainWhere: { id: params.voucherId },
          allWhere: { id_in: params.allVoucherIds }
        }
      };
    }
  }),
  graphql(NEW_INTEGRATED_VOUCHER)
)(NewVoucher);
