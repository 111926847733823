import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';
import * as yup from 'yup';

import { SIGN_UP } from 'gqls/user';
import { redirect, storeToken } from 'browser';
import { userNewInitialValues } from 'initialValues';

import UserForm from 'forms/UserForm';

const styles = theme => ({
  main: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  }
});

class SingUp extends React.Component {
  handleSucceed = ({ data }) => {
    const {
      signUp: { token }
    } = data;
    storeToken(token);
    redirect('/dashboard');
  };

  render() {
    const { classes, mutate } = this.props;
    return (
      <main className={classes.main}>
        <UserForm
          action="SignUp"
          schema={schema}
          initialValues={userNewInitialValues}
          mutate={mutate}
          onSucceed={this.handleSucceed}
        />
      </main>
    );
  }
}

const schema = yup.object().shape({
  name: yup.string().required(),
  nameKana: yup.string().required(),
  loginId: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  password: yup.string().required(),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null])
    .required('Password Confirm is required'),
  signName: yup.string().required(),
  employeeId: yup.string().required(),
  sectionsRelatedIds: yup.array().of(yup.string()),
  joinedDate: yup.date().required()
});

export default compose(
  withStyles(styles),
  graphql(SIGN_UP)
)(SingUp);
