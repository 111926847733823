import React from 'react';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import wrapTablePagination from 'hocs/wrapTablePagination';
import { convertIntoDateFormat } from 'utils/date';
import enums from 'enums';

import SortableTableHead from 'elements/SortableTableHead';

const columns = [
  { id: 'pdfType', align: 'left', disablePadding: false, label: '種類', sortable: true },
  { id: 'programNo', align: 'left', disablePadding: false, label: 'No.', sortable: false },
  { id: 'title', align: 'left', disablePadding: false, label: 'PROGRAM', sortable: false },
  { id: 'subTitle', align: 'left', disablePadding: false, label: 'サブタイトル', sortable: false },
  { id: 'name', align: 'left', disablePadding: false, label: '会社名', sortable: false },
  { id: 'registerDate', align: 'left', disablePadding: false, label: '送信日', sortable: true }
];

class HeadBody extends React.Component {
  render() {
    const { order, orderBy, pdfs, createHandleClick, handleRequestSort } = this.props;
    return (
      <React.Fragment>
        <SortableTableHead
          order={order}
          orderBy={orderBy}
          columns={columns}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {pdfs.map(pdf => (
            <TableRow hover onClick={createHandleClick(pdf)} tabIndex={-1} key={pdf.id}>
              <TableCell component="th" scope="row">
                {enums.PDF_TYPE[pdf.pdfType]}
              </TableCell>
              <TableCell>{pdf.voucher.program.programNo}</TableCell>
              <TableCell>{pdf.voucher.title}</TableCell>
              <TableCell>{pdf.voucher.subTitle}</TableCell>
              <TableCell>{pdf.voucher.customer.name}</TableCell>
              <TableCell>{convertIntoDateFormat(pdf.registerDate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </React.Fragment>
    );
  }
}

export default wrapTablePagination()(HeadBody);
