import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

const LabeledTextField = props => {
  const {
    type = 'text',
    value,
    label,
    margin = 'dense',
    smallFont = false,
    redFont = false,
    InputLabelProps = { shrink: true },
    InputProps = {},
    inputProps = {},
    theme,
    ...rest
  } = props;

  if (smallFont) {
    InputProps['style'] = { ...InputProps['style'], fontSize: '0.8rem' };
  }

  if (redFont) {
    InputProps['style'] = { ...InputProps['style'], color: theme.palette.error.main };
  }

  const common = {
    type,
    value,
    label,
    margin,
    InputLabelProps,
    InputProps,
    inputProps,
    ...rest
  };

  return <TextField {...common} disabled />;
};

export default withStyles(() => {}, { withTheme: true })(LabeledTextField);
