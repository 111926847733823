import React from 'react';
import { FieldArray } from 'formik';
import { get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import enums from 'enums';
import withFormFieldRow from 'hocs/withFormFieldRow';
import { reportExpenseInitialValues } from 'initialValues';

import MyTextField from 'elements/MyTextField';
import FieldsFormToolbar from 'elements/FieldsFormToolbar';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  table: {
    tableLayout: 'fixed'
  },
  tableHeadRow: {
    height: 48
  },
  tableHeadCell: {
    paddingRight: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit / 2
  },
  tableBodyCell: {
    paddingRight: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit / 2
  }
});

class FieldRow extends React.Component {
  render() {
    const {
      index,
      classes,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      userOptions,
      expenseOptions,
      movable
    } = this.props;
    const {
      model,
      handleRemove,
      handleUp,
      handleDown,
      handleAmountBlur,
      handleUnitPriceBlur
    } = this.props;

    const rowTouched = touched.reportExpenses ? touched.reportExpenses[index] : {};
    const rowErrors = errors.reportExpenses ? errors.reportExpenses[index] : {};
    const isNew = get(values, `${model}.${index}.id`) === undefined;

    return (
      <TableRow>
        {movable && (
          <TableCell className={classes.tableBodyCell} align="center" padding="none">
            {!isNew && (
              <>
                <IconButton aria-label="Up" onClick={handleUp}>
                  <KeyboardArrowUpIcon fontSize="small" />
                </IconButton>
                <IconButton aria-label="Down" onClick={handleDown}>
                  <KeyboardArrowDownIcon fontSize="small" />
                </IconButton>
              </>
            )}
          </TableCell>
        )}
        <TableCell
          className={classes.tableBodyCell}
          align="center"
          padding="none"
          component="th"
          scope="row"
        >
          <MyTextField
            name={`${model}.${index}.date`}
            type="date"
            values={values}
            touched={rowTouched}
            errors={rowErrors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            required
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.expenseRelatedId`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            select
          >
            <MenuItem key="" value="" />
            {expenseOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </MyTextField>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.amount`}
            type="number"
            values={values}
            touched={rowTouched}
            errors={rowErrors}
            margin="none"
            smallFont
            inputProps={{ step: '1' }}
            onChange={handleChange}
            onBlur={handleAmountBlur}
            fullWidth
            required
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.unitPrice`}
            type="number"
            values={values}
            touched={rowTouched}
            errors={rowErrors}
            margin="none"
            smallFont
            inputProps={{ step: '1' }}
            onChange={handleChange}
            onBlur={handleUnitPriceBlur}
            fullWidth
            required
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.price`}
            type="number"
            values={values}
            touched={rowTouched}
            errors={rowErrors}
            margin="none"
            smallFont
            inputProps={{ step: '1' }}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            required
            disabled
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.userRelatedId`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            select
          >
            <MenuItem key="" value="" />
            {userOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </MyTextField>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.detail`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.systemCheck`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            select
          >
            <MenuItem key="" value="" />
            {Object.entries(enums.SYSTEM_CHECK).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </MyTextField>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <Checkbox
            name={`${model}.${index}.check`}
            checked={get(values, `${model}.${index}.check`)}
            onChange={handleChange}
            onBlur={handleBlur}
            value={`${model}.${index}.check`}
            color="primary"
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <IconButton aria-label="Delete" onClick={handleRemove} disabled={movable}>
            <DeleteIcon fontSize="small" color="secondary" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}
const EnchancedFieldRow = withFormFieldRow('reportExpenses', {
  willSubmitWhenRemove: props => props.action === 'EditReport'
})(FieldRow);

function ReportExpenseFieldsForm(props) {
  const { classes, values, movable, onToggleMovable, handleChange, handleBlur } = props;

  return (
    <FieldArray
      name="reportExpenses"
      render={arrayHelpers => (
        <Paper className={classes.root}>
          <FieldsFormToolbar
            title="経費"
            movable={movable}
            onToggleMovable={onToggleMovable}
            onAddClick={() => {
              const n = values.reportExpenses.length;
              arrayHelpers.push({
                ...reportExpenseInitialValues,
                sortOrder: n + 1
              });
            }}
            isReport={true}
            isNoFieldName="isNoExpense"
            isNoFieldValue={values.isNoExpense}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeadRow}>
                {movable && (
                  <TableCell
                    className={classes.tableHeadCell}
                    align="center"
                    padding="none"
                    style={{ width: 108 }}
                  />
                )}
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 132 }}
                >
                  日付
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 200 }}
                >
                  内容
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 74 }}
                >
                  数量
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 108 }}
                >
                  単価
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 108 }}
                >
                  金額
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 128 }}
                >
                  作業者
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 416 }}
                >
                  詳細
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  システム
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  チェック
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  削除
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.reportExpenses &&
                values.reportExpenses.map((row, index) => {
                  return (
                    <EnchancedFieldRow
                      key={index}
                      index={index}
                      row={row}
                      arrayHelpers={arrayHelpers}
                      movable={movable}
                      {...props}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      )}
    />
  );
}

export default withStyles(styles)(ReportExpenseFieldsForm);
