import { customerInitialValues, personnelInitialValues } from 'initialValues';
import { mergeInitialValues, convertIntoIdArr } from 'utils/form';
import { convertIntoDateFormat } from 'utils/date';

export const convertIntoNewCustomerForm = () => {
  return customerInitialValues;
};

export const convertIntoEditCustomerForm = ({ ...values }) => {
  const { labels } = values;

  delete values['personnels'];

  values.labelsRelatedIds = convertIntoIdArr(labels);
  values.registerDate = convertIntoDateFormat(values.registerDate);

  return mergeInitialValues(customerInitialValues, values);
};

export const convertIntoNewPersonnelForm = customer => {
  return mergeInitialValues(personnelInitialValues, { customerRelatedId: customer.id });
};

export const convertIntoEditPersonnelForm = ({ ...values }) => {
  values.customerRelatedId = values.customer.id;
  delete values.customer;

  values.registerDate = convertIntoDateFormat(values.registerDate);

  return mergeInitialValues(personnelInitialValues, values);
};
