import React from 'react';

import { SEARCH_ADDRESS } from 'gqls/gql';

import { withApollo } from 'react-apollo';

export const withPostalCodeField = () => Component => {
  class C extends React.Component {
    handlePostalCodeInputDebounceChange = value => {
      const { client, setFieldValue } = this.props;

      const variables = {
        postalCode: value
      };

      client
        .query({
          query: SEARCH_ADDRESS,
          variables
        })
        .then(({ data }) => {
          const {
            searchAddress: { prefecture, address }
          } = data;
          setFieldValue('prefecture', prefecture);
          setFieldValue('address', address);
        })
        .catch(() => {});
    };

    render() {
      return (
        <Component
          {...this.props}
          handlePostalCodeInputDebounceChange={this.handlePostalCodeInputDebounceChange}
        />
      );
    }
  }

  C.displayName = `withPostalCodeField(${Component.displayName || Component.name})`;

  return withApollo(C);
};

export default withPostalCodeField;
