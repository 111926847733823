import React from 'react';
import { compose, graphql } from 'react-apollo';
import * as yup from 'yup';
import withStyles from '@material-ui/core/styles/withStyles';

import { convertIntoUserForm } from 'formConvertors/user';
import { FETCH_USER, EDIT_USER } from 'gqls/user';
import { redirect } from 'browser';
import wrapMenu from 'hocs/wrapMenu';

import UserForm from 'forms/UserForm';

const styles = theme => ({
  main: {
    width: 800,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

class EditUser extends React.Component {
  handleSucceed = () => {
    redirect('/users');
  };

  render() {
    const {
      classes,
      data: { fetchUser },
      mutate
    } = this.props;

    if (!fetchUser) return null;

    const initialValues = convertIntoUserForm(fetchUser);

    return (
      <main className={classes.main}>
        {initialValues ? (
          <UserForm
            action="EditUser"
            schema={schema}
            initialValues={initialValues}
            mutate={mutate}
            onSucceed={this.handleSucceed}
          />
        ) : null}
      </main>
    );
  }
}

const schema = yup.object().shape({
  name: yup.string().required(),
  nameKana: yup.string().required(),
  loginId: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  signName: yup.string().required(),
  employeeId: yup.string().required(),
  sectionsRelatedIds: yup.array().of(yup.string()),
  joinedDate: yup.date().required(),
  userLevel: yup.string().required(),
  isRetired: yup.bool().required()
});

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: '従業員情報' }),
  graphql(FETCH_USER, {
    options: props => {
      const {
        match: {
          params: { userId }
        }
      } = props;
      return {
        variables: {
          where: { id: userId }
        }
      };
    }
  }),
  graphql(EDIT_USER)
)(EditUser);
