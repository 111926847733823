import React from 'react';
import { withRouter } from 'react-router-dom';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { compose } from 'react-apollo';
import { keys } from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import withChangeSubmit from 'hocs/withChangeSubmit';
import { takeSpecificKeys } from 'utils/util';
import { takeSearchParams } from 'utils/search';

import MyTextField from 'elements/MyTextField';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit
  },
  form: {
    marginTop: theme.spacing.unit
  }
});

class SearchUserForm extends React.Component {
  render() {
    const { classes, values, errors, touched, handleChange, handleBlur, handleSubmit } = this.props;
    const { handleChangeSF, handleBlurSF, handleKeyPress } = this.props;

    return (
      <Paper className={classes.paper}>
        <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={8}>
            <Grid item xs={3}>
              <MyTextField
                name="name"
                values={values}
                touched={touched}
                errors={errors}
                label="名前"
                margin="none"
                onChange={handleChange}
                onBlur={handleBlurSF}
                onKeyPress={handleKeyPress}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <MyTextField
                name="isRetired"
                values={values}
                touched={touched}
                errors={errors}
                label="在職状況"
                margin="none"
                onChange={handleChangeSF}
                onBlur={handleBlur}
                fullWidth
                select
              >
                <MenuItem value={false}>在職中</MenuItem>
                <MenuItem value={true}>退職済み</MenuItem>
              </MyTextField>
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
}

const initialValues = {
  name: '',
  isRetired: false
};

const schema = yup.object().shape({
  name: yup.string(),
  isRetired: yup.bool()
});

export default compose(
  withStyles(styles),
  withRouter,
  withFormik({
    mapPropsToValues: props => {
      const { location } = props;
      const params = takeSearchParams(location);
      return Object.assign({}, initialValues, takeSpecificKeys(params, keys(initialValues)));
    },
    validationSchema: schema,
    handleSubmit: (values, { props, setSubmitting }) => {
      const { updateSearchParams } = props;
      setSubmitting(false);
      updateSearchParams(values);
    }
  }),
  withChangeSubmit()
)(SearchUserForm);
