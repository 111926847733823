import React from 'react';
import queryString from 'query-string';
import classNames from 'classnames';
import { withFormik } from 'formik';
import { compose, graphql } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';
import { isEmpty } from 'lodash';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';

import {
  GET_VOUCHER_FORM_OPTIONS,
  EDIT_VOUCHER_STATUS,
  ARCHIVE_VOUCHER,
  REMOVE_VOUCHER
} from 'gqls/program';
import { convertIntoNewVoucherGql, convertIntoEditVoucherGql } from 'formConvertors/program';
import { convertIntoSelectFieldValues, takeOption, takeOptionField } from 'utils/form';
import withCustomerPersonnelField from 'hocs/withCustomerPersonnelField';
import withReactSelectHandler from 'hocs/withReactSelectHandler';
import withBlurSubmit from 'hocs/withBlurSubmit';
import enums from 'enums';
import { confirm, reload, redirect } from 'browser';
import { numberWithCommas } from 'utils/util';
import { calcSubtotal, taxPercent, totalPriceWithTax } from 'utils/tax';

import MyTextField from 'elements/MyTextField';
import LabeledTextField from 'elements/LabeledTextField';
import SearchSelectField from 'elements/SearchSelectField';
import SuggestSelectField from 'elements/SuggestSelectField';
import VoucherOperationFieldsForm from 'forms/VoucherOperationFieldsForm';
import VoucherExpenseFieldsForm from 'forms/VoucherExpenseFieldsForm';
import VoucherMediaFieldsForm from 'forms/VoucherMediaFieldsForm';
import SelectIntegratableVouchersForm from 'forms/SelectIntegratableVouchersForm';
import VoucherStatusFlow from 'elements/VoucherStatusFlow';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(theme.breakpoints.values.md + theme.spacing.unit * 2 * 2)]: {
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 2
    }
  },
  form: {
    marginTop: theme.spacing.unit * 2
  },
  actionBottons: {
    marginTop: theme.spacing.unit * 3,
    alignItems: 'flex-end'
  },
  statusesWrapper: {
    marginTop: theme.spacing.unit * 3
  },
  buttonWrapper: {
    display: 'flex',
    [`& button:not(:first-child)`]: {
      marginLeft: theme.spacing.unit * 2
    }
  },
  positionLeft: {
    justifyContent: 'flex-start'
  },
  positionCenter: {
    justifyContent: 'center'
  },
  positionRight: {
    justifyContent: 'flex-end'
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  labels: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  chip: {
    marginLeft: theme.spacing.unit
  }
});

class VoucherForm extends React.Component {
  componentDidMount() {
    const { setCustomers, setPersonnels } = this.props;

    const { customer } = this.props;
    if (customer) {
      setCustomers([customer]);
      setPersonnels(customer.personnels);
    }
  }

  handleStatusChange = e => {
    const {
      values: { id },
      handleChange,
      editVoucherStatus
    } = this.props;

    if (confirm('ステータスを変更してもよろしいですか？')) {
      handleChange(e);

      const value = e.target.value;

      editVoucherStatus({
        variables: {
          where: { id },
          voucherStatus: value
        }
      })
        .then(() => {
          reload();
        })
        .catch(error => {});
    }
  };

  handleDeleteClick = () => {
    const {
      values: { id },
      removeVoucher
    } = this.props;

    if (confirm('この作業伝票を削除してもよろしいですか？')) {
      removeVoucher({
        variables: {
          where: { id }
        }
      })
        .then(() => {
          redirect('/vouchers');
        })
        .catch(error => {});
    }
  };

  handleMailClick = () => {
    const {
      customer,
      values: { id }
    } = this.props;

    redirect(`/vouchers/${id}/sendMail?customerId=${customer.id}`);
  };

  handleArchiveClick = () => {
    const {
      values: { id },
      archiveVoucher
    } = this.props;

    if (confirm('改訂してもよろしいですか？')) {
      archiveVoucher({
        variables: {
          where: { id }
        }
      })
        .then(() => {
          reload();
        })
        .catch(error => {});
    }
  };

  handleIntegrateClick = selectedVoucherIds => {
    const {
      values: { id }
    } = this.props;

    const params = {
      voucherId: id,
      allVoucherIds: [id, ...selectedVoucherIds]
    };

    redirect(`/vouchers/new?${queryString.stringify(params)}`);
  };

  render() {
    const {
      classes,
      initialValues,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      submitForm,
      paymentDay
    } = this.props;

    const {
      customers,
      personnels,
      handleCustomerInputChange,
      handleCustomerInputDebounceChange,
      handleCustomerChange
    } = this.props;
    const { createHandleSelect } = this.props;

    const { action, customer, data } = this.props;

    const formProps = {
      action,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      submitForm
    };

    if (data.loading) return null;

    const userOptions = convertIntoSelectFieldValues(data.getUsers);
    const customerOptions = convertIntoSelectFieldValues(customers);
    const personnelOptions = convertIntoSelectFieldValues(personnels);
    const operationOptions = [
      ...convertIntoSelectFieldValues(data.eizoOperations),
      ...convertIntoSelectFieldValues(data.onkyoOperations),
      ...convertIntoSelectFieldValues(data.gaigaOperations),
      ...convertIntoSelectFieldValues(data.eigyoOperations),
      ...convertIntoSelectFieldValues(data.otherOperations)
    ];
    const mediaOptions = convertIntoSelectFieldValues(data.medias);
    const expenseOptions = convertIntoSelectFieldValues(data.expenses);

    const subtotal = calcSubtotal(
      values.voucherOperations,
      values.voucherExpenses,
      values.voucherMedias
    );
    const percent = taxPercent(values.billDate);
    const tax = Math.floor(subtotal * percent);
    const total = totalPriceWithTax(
      values.voucherOperations,
      values.voucherExpenses,
      values.voucherMedias,
      values.billDate
    );

    const { selectedCustomer } = this.props;
    const labels = selectedCustomer && selectedCustomer.labels ? selectedCustomer.labels : [];

    return (
      <Paper className={classes.paper}>
        <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={8}>
            {action !== 'EditVoucher' ? (
              <React.Fragment>
                <Grid item xs={2}>
                  <MyTextField
                    name="voucherStatus"
                    values={values}
                    touched={touched}
                    errors={errors}
                    label="ステータス"
                    margin="none"
                    smallFont
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    select
                  >
                    {Object.entries(enums.SELECTABLE_VOUCHER_STATUS).map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </MyTextField>
                </Grid>
                <Grid item xs={2}>
                  <SuggestSelectField
                    TextFieldProps={{
                      name: 'userRelatedId',
                      touched: touched,
                      errors: errors,
                      label: '管理者',
                      margin: 'none',
                      smallFont: true,
                      required: true,
                      fullWidth: true
                    }}
                    options={userOptions}
                    selectedOption={{
                      value: values['userRelatedId'],
                      label: takeOptionField(userOptions, values['userRelatedId'], 'name')
                    }}
                    onChange={createHandleSelect('userRelatedId')}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyTextField
                    name="billDate"
                    type="date"
                    values={values}
                    touched={touched}
                    errors={errors}
                    label="納品日"
                    margin="none"
                    smallFont
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.labels}>
                    {labels.map(label => (
                      <Chip
                        key={label.color}
                        style={{ backgroundColor: label.color, fontWeight: 400 }}
                        label={label.name}
                        color="primary"
                        className={classes.chip}
                      />
                    ))}
                  </div>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid item xs={2}>
                  <MyTextField
                    name="programNo"
                    values={values}
                    touched={touched}
                    errors={errors}
                    label="No."
                    margin="none"
                    smallFont
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyTextField
                    name="voucherStatus"
                    values={values}
                    touched={touched}
                    errors={errors}
                    label="ステータス"
                    margin="none"
                    smallFont
                    onChange={this.handleStatusChange}
                    onBlur={handleBlur}
                    fullWidth
                    select
                  >
                    {Object.entries(enums.SELECTABLE_VOUCHER_STATUS).map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </MyTextField>
                </Grid>
                <Grid item xs={2}>
                  <SuggestSelectField
                    TextFieldProps={{
                      name: 'userRelatedId',
                      touched: touched,
                      errors: errors,
                      label: '管理者',
                      margin: 'none',
                      smallFont: true,
                      required: true,
                      fullWidth: true
                    }}
                    options={userOptions}
                    selectedOption={{
                      value: values['userRelatedId'],
                      label: takeOptionField(userOptions, values['userRelatedId'], 'name')
                    }}
                    onChange={createHandleSelect('userRelatedId')}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyTextField
                    name="billDate"
                    type="date"
                    values={values}
                    touched={touched}
                    errors={errors}
                    label="納品日"
                    margin="none"
                    smallFont
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.labels}>
                    {labels.map(label => (
                      <Chip
                        key={label.color}
                        style={{ backgroundColor: label.color, fontWeight: 400 }}
                        label={label.name}
                        color="primary"
                        className={classes.chip}
                      />
                    ))}
                  </div>
                </Grid>
              </React.Fragment>
            )}
            <Grid item xs={6}>
              <SearchSelectField
                TextFieldProps={{
                  name: 'customerRelatedId',
                  values: values,
                  touched: touched,
                  errors: errors,
                  label: '顧客',
                  margin: 'none',
                  smallFont: true,
                  onBlur: handleBlur,
                  required: true,
                  fullWidth: true
                }}
                defaultValue={takeOption(customerOptions, values['customerRelatedId'])}
                options={customerOptions}
                onInputChange={handleCustomerInputChange}
                onInputDebounceChange={handleCustomerInputDebounceChange}
                onChange={handleCustomerChange}
              />
            </Grid>
            <Grid item xs={6}>
              <SuggestSelectField
                TextFieldProps={{
                  name: 'personnelRelatedId',
                  touched: touched,
                  errors: errors,
                  label: '担当者',
                  margin: 'none',
                  smallFont: true,
                  fullWidth: true
                }}
                options={personnelOptions}
                selectedOption={{
                  value: values['personnelRelatedId'],
                  label: takeOptionField(personnelOptions, values['personnelRelatedId'], 'name')
                }}
                onChange={createHandleSelect('personnelRelatedId')}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="title"
                values={values}
                touched={touched}
                errors={errors}
                label="PROGRAM"
                margin="none"
                smallFont
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="subTitle"
                values={values}
                touched={touched}
                errors={errors}
                label="サブタイトル"
                margin="none"
                smallFont
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MyTextField
                name="memo"
                values={values}
                touched={touched}
                errors={errors}
                label="備考"
                margin="none"
                smallFont
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                multiline
                rowsMax="10"
              />
            </Grid>
            <Grid item xs={12}>
              <VoucherOperationFieldsForm {...formProps} operationOptions={operationOptions} />
            </Grid>
            <Grid item xs={12}>
              <VoucherMediaFieldsForm {...formProps} mediaOptions={mediaOptions} />
            </Grid>
            <Grid item xs={12}>
              <VoucherExpenseFieldsForm {...formProps} expenseOptions={expenseOptions} />
            </Grid>
            <Grid item xs={false} md={6} />
            <Grid item xs={4} md={2}>
              <LabeledTextField
                value={numberWithCommas(subtotal)}
                label="小計"
                margin="none"
                smallFont
                fullWidth
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <LabeledTextField
                value={numberWithCommas(tax)}
                label="消費税"
                margin="none"
                smallFont
                fullWidth
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <LabeledTextField
                value={numberWithCommas(total)}
                label="合計"
                margin="none"
                smallFont
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} className={classes.actionBottons}>
            <Grid item xs={4} />
            <Grid item xs={4} className={classNames(classes.buttonWrapper, classes.positionCenter)}>
              {action !== 'EditVoucher' && (
                <Button type="submit" variant="contained" color="primary">
                  登録
                </Button>
              )}
            </Grid>
            <Grid item xs={4} className={classNames(classes.buttonWrapper, classes.positionRight)}>
              {action === 'EditVoucher' &&
                !['Archived', 'Integrated'].includes(values.voucherStatus) && (
                  <React.Fragment>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={this.handleMailClick}
                    >
                      メール
                    </Button>
                    <Button size="small" variant="contained" onClick={this.handleArchiveClick}>
                      改訂
                    </Button>
                    <Button size="small" color="secondary" onClick={this.handleDeleteClick}>
                      この作業伝票を削除
                    </Button>
                  </React.Fragment>
                )}
            </Grid>
          </Grid>
        </form>
        {action === 'EditVoucher' &&
          !['Archived', 'Integrated'].includes(values.voucherStatus) && (
            <React.Fragment>
              <div className={classes.statusesWrapper}>
                <VoucherStatusFlow voucher={initialValues} paymentDay={paymentDay} />
              </div>
              <Grid container spacing={8} className={classes.actionBottons}>
                <Grid item xs={2} />
                <Grid item xs={8}>
                  <SelectIntegratableVouchersForm
                    customer={customer}
                    onIntegrateClick={this.handleIntegrateClick}
                  />
                </Grid>
                <Grid item xs={2} />
              </Grid>
            </React.Fragment>
          )}
      </Paper>
    );
  }
}

const takeNewValues = values => {
  return {
    data: convertIntoNewVoucherGql(values)
  };
};

const takeEditValues = (values, initialValues) => {
  const { id, ...rest } = values;

  return {
    data: convertIntoEditVoucherGql(rest, initialValues),
    where: {
      id
    }
  };
};

export default compose(
  withStyles(styles),
  graphql(GET_VOUCHER_FORM_OPTIONS),
  graphql(EDIT_VOUCHER_STATUS, { name: 'editVoucherStatus' }),
  graphql(ARCHIVE_VOUCHER, { name: 'archiveVoucher' }),
  graphql(REMOVE_VOUCHER, { name: 'removeVoucher' }),
  withFormik({
    mapPropsToValues: props => props.initialValues,
    validationSchema: props => props.schema,
    handleSubmit: (values, { props, setSubmitting }) => {
      const { action, initialValues, mutate, updateProgressState, onSucceed } = props;

      let variables;
      switch (action) {
        case 'NewVoucher':
        case 'NewVoucherByReport':
          variables = takeNewValues(values);
          break;
        case 'NewVoucherByOthers':
          variables = takeNewValues(values);
          variables.fromWhere = {
            id_in: props.fromVoucherIds
          };
          break;
        case 'EditVoucher':
          variables = takeEditValues(values, initialValues);
          break;
        default:
          throw new Error();
      }

      if (isEmpty(variables.data)) {
        return;
      }

      console.log(variables);

      updateProgressState(true);

      mutate({
        variables
      })
        .then(onSucceed)
        .catch(error => {})
        .finally(() => {
          setSubmitting(false);
          updateProgressState(false);
        });
    },
    displayName: 'VoucherForm'
  }),
  withReactSelectHandler(),
  withCustomerPersonnelField({
    takeInitialCustomer: props => (props.customer ? props.customer : undefined)
  }),
  withBlurSubmit({
    willSubmit: props =>
      props.action === 'EditVoucher' &&
      !['Archived', 'Integrated'].includes(props.values.voucherStatus)
  })
)(VoucherForm);
