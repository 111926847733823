import React from 'react';
import { compose, graphql } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';

import {
  OPERATION_LIST,
  addOperationGqlMap,
  editOperationGqlMap,
  removeOperationGqlMap,
  arrangeOperationGqlMap
} from 'gqls/operation';
import wrapDragDropList from 'hocs/wrapDragDropList';
import wrapMenu from 'hocs/wrapMenu';

import OperationForm from 'forms/OperationForm';

const makeComponent = (section, label) => {
  return compose(
    graphql(addOperationGqlMap[`ADD_${section.toUpperCase()}_OPERATION`], {
      name: `add${section}Operation`
    }),
    graphql(editOperationGqlMap[`EDIT_${section.toUpperCase()}_OPERATION`], {
      name: `edit${section}Operation`
    }),
    graphql(removeOperationGqlMap[`REMOVE_${section.toUpperCase()}_OPERATION`], {
      name: `remove${section}Operation`
    }),
    graphql(arrangeOperationGqlMap[`ARRANGE_${section.toUpperCase()}_OPERATIONS`], {
      name: `arrange${section}Operations`
    }),
    wrapDragDropList({
      label,
      defaultValues: {
        name: '',
        section,
        applyUnitPrice: ''
      },
      convertIntoForm: ({ ...values }) => {
        if (values.applyUnitPrice === null) {
          values.applyUnitPrice = '';
        }
        return values;
      },
      convertIntoGql: ({ ...values }) => {
        if (!values.applyUnitPrice) {
          values.applyUnitPrice = null;
        }
        return values;
      },
      handleAddName: `add${section}Operation`,
      handleEditName: `edit${section}Operation`,
      handleRemoveName: `remove${section}Operation`,
      handleArrangeName: `arrange${section}Operations`,
      withHeadDesc: false
    })
  )(OperationForm);
};

const EizoDragDropList = makeComponent('Eizo', '映像');

const OnkyoDragDropList = makeComponent('Onkyo', '音響');

const GaigaDragDropList = makeComponent('Gaiga', '外画');

const EigyoDragDropList = makeComponent('Eigyo', '営業');

const OtherDragDropList = makeComponent('Other', '請求関連');

const styles = theme => ({
  main: {
    width: 'auto',
    marginTop: theme.spacing.unit * 4,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2
  }
});

class OperationList extends React.Component {
  render() {
    const { classes, data } = this.props;

    if (data.loading) return null;

    return (
      <main className={classes.main}>
        <Grid container spacing={24}>
          <Grid item xs={12} md={4}>
            <EizoDragDropList list={data.eizoOperations} />
          </Grid>
          <Grid item xs={12} md={4}>
            <OnkyoDragDropList list={data.onkyoOperations} />
          </Grid>
          <Grid item xs={12} md={4}>
            <GaigaDragDropList list={data.gaigaOperations} />
          </Grid>
          <Grid item xs={12} md={4}>
            <EigyoDragDropList list={data.eigyoOperations} />
          </Grid>
          <Grid item xs={12} md={4}>
            <OtherDragDropList list={data.otherOperations} />
          </Grid>
        </Grid>
      </main>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  wrapMenu({ appBarText: 'オペレーションマスタ' }),
  graphql(OPERATION_LIST)
)(OperationList);
