import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';
import * as yup from 'yup';

import { convertIntoMyselfForm } from 'formConvertors/user';
import { UserContext } from 'contexts';
import { EDIT_USER } from 'gqls/user';
import { notifySuccess } from 'notifications';
import wrapMenu from 'hocs/wrapMenu';

import UserForm from 'forms/UserForm';

const styles = theme => ({
  main: {
    width: 800,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

class EditMyself extends React.Component {
  handleSucceed = () => {
    notifySuccess('あなたの情報が更新されました。');
  };

  render() {
    const { classes, mutate } = this.props;

    return (
      <UserContext.Consumer>
        {value => {
          const initialValues = convertIntoMyselfForm(value);

          return (
            <main className={classes.main}>
              {initialValues && (
                <UserForm
                  action="EditMyself"
                  schema={schema}
                  initialValues={initialValues}
                  mutate={mutate}
                  onSucceed={this.handleSucceed}
                />
              )}
            </main>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

const schema = yup.object().shape({
  name: yup.string().required(),
  nameKana: yup.string().required(),
  loginId: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  signName: yup.string().required(),
  sectionsRelatedIds: yup.array().of(yup.string())
});

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: 'アカウント情報' }),
  graphql(EDIT_USER)
)(EditMyself);
