import gql from 'graphql-tag';

export const SEARCH_ADDRESS = gql`
  query SearchAddress($postalCode: String!) {
    searchAddress(postalCode: $postalCode) {
      prefecture
      address
    }
  }
`;

export const GET_PROGRAM_STATUSES = gql`
  query GetProgramStatuses {
    R_Registered: getReportCount(reportStatus: Registered)
    R_Finished: getReportCount(reportStatus: Finished)
    R_ToMa: getReportCount(reportStatus: ToMa)
    R_ToGaiga: getReportCount(reportStatus: ToGaiga)
    R_ToEed: getReportCount(reportStatus: ToEed)
    R_Checked: getReportCount(reportStatus: Checked)
    R_Approved: getReportCount(reportStatus: Approved)
    R_Completed: getReportCount(reportStatus: Completed)

    V_Registered: getVoucherCount(voucherStatus: Registered)
    V_Checked: getVoucherCount(voucherStatus: Checked)
    V_Mailed: getVoucherCount(voucherStatus: Mailed)
    V_Fixed: getVoucherCount(voucherStatus: Fixed)
    V_Demanded: getVoucherCount(voucherStatus: Demanded)
    V_Gray: getVoucherCount(voucherStatus: Gray)
    V_DarkGray: getVoucherCount(voucherStatus: DarkGray)
    V_Completed: getVoucherCount(voucherStatus: Completed)
  }
`;
