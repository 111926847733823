import React from 'react';

export const withBlurSubmit = (options = {}) => Component => {
  const { willSubmit = () => false } = options;

  class C extends React.Component {
    handleBlur = e => {
      const { handleBlur, submitForm } = this.props;

      handleBlur(e);

      if (willSubmit(this.props)) {
        submitForm();
      }
    };

    render() {
      const { handleBlur } = this.props;
      return (
        <Component {...this.props} handleBlur={this.handleBlur} handleOriginBlur={handleBlur} />
      );
    }
  }

  C.displayName = `withBlurSubmit(${Component.displayName || Component.name})`;

  return C;
};

export default withBlurSubmit;
