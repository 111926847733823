import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      bottom: theme.spacing.unit * 10,
      right: theme.spacing.unit * 10
    }
  }
});

const FloatingAddButton = props => {
  const { classes, theme, handleClick } = props;

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen
  };

  return (
    <Zoom
      in={true}
      timeout={transitionDuration}
      style={{
        transitionDelay: `${transitionDuration.exit}ms`
      }}
      unmountOnExit
    >
      <Fab className={classes.fab} color="primary" onClick={handleClick}>
        <AddIcon />
      </Fab>
    </Zoom>
  );
};

export default withStyles(styles, { withTheme: true })(FloatingAddButton);
