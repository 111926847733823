import gql from 'graphql-tag';

import { OperationFieldsFragment } from './fragments';

const sections = ['Eizo', 'Onkyo', 'Gaiga', 'Eigyo', 'Other'];

export const OPERATION_LIST = gql`
  query Operations {
    eizoOperations: operations(where: { section: Eizo }, orderBy: order_ASC) {
      ...OperationFields
    }
    onkyoOperations: operations(where: { section: Onkyo }, orderBy: order_ASC) {
      ...OperationFields
    }
    gaigaOperations: operations(where: { section: Gaiga }, orderBy: order_ASC) {
      ...OperationFields
    }
    eigyoOperations: operations(where: { section: Eigyo }, orderBy: order_ASC) {
      ...OperationFields
    }
    otherOperations: operations(where: { section: Other }, orderBy: order_ASC) {
      ...OperationFields
    }
  }
  ${OperationFieldsFragment}
`;

export const EDIT_OPERATION = gql`
  mutation EditOperation($data: OperationUpdateInput!, $where: OperationWhereUniqueInput!) {
    editOperation(data: $data, where: $where) {
      ...OperationFields
    }
  }
  ${OperationFieldsFragment}
`;

export const addOperationGqlMap = sections.reduce((prev, section) => {
  prev[`ADD_${section.toUpperCase()}_OPERATION`] = gql`
    mutation AddOperation($data: OperationCreateInput!) {
      add${section}Operation(data: $data) {
        ...OperationFields
      }
    }
    ${OperationFieldsFragment}
  `;
  return prev;
}, {});

export const editOperationGqlMap = sections.reduce((prev, section) => {
  prev[`EDIT_${section.toUpperCase()}_OPERATION`] = gql`
    mutation EditOperation($data: OperationUpdateInput!, $where: OperationWhereUniqueInput!) {
      edit${section}Operation(data: $data, where: $where) {
        ...OperationFields
      }
    }
    ${OperationFieldsFragment}
  `;
  return prev;
}, {});

export const removeOperationGqlMap = sections.reduce((prev, section) => {
  prev[`REMOVE_${section.toUpperCase()}_OPERATION`] = gql`
    mutation RemoveOperation($where: OperationWhereUniqueInput!) {
      remove${section}Operation(where: $where) {
        ...OperationFields
      }
    }
    ${OperationFieldsFragment}
  `;
  return prev;
}, {});

export const arrangeOperationGqlMap = sections.reduce((prev, section) => {
  prev[`ARRANGE_${section.toUpperCase()}_OPERATIONS`] = gql`
    mutation ArrangeOperations($list: [ArrangeInput!]!) {
      arrange${section}Operations(list: $list) {
        ...OperationFields
      }
    }
    ${OperationFieldsFragment}
  `;
  return prev;
}, {});
