import gql from 'graphql-tag';

import { MediaFieldsFragment } from './fragments';

export const MEDIA_LIST = gql`
  query Medias {
    medias(orderBy: order_ASC) {
      ...MediaFields
    }
  }
  ${MediaFieldsFragment}
`;

export const ADD_MEDIA = gql`
  mutation AddMedia($data: MediaCreateInput!) {
    addMedia(data: $data) {
      ...MediaFields
    }
  }
  ${MediaFieldsFragment}
`;

export const EDIT_MEDIA = gql`
  mutation EditMedia($data: MediaUpdateInput!, $where: MediaWhereUniqueInput!) {
    editMedia(data: $data, where: $where) {
      ...MediaFields
    }
  }
  ${MediaFieldsFragment}
`;

export const REMOVE_MEDIA = gql`
  mutation RemoveMedia($where: MediaWhereUniqueInput!) {
    removeMedia(where: $where) {
      ...MediaFields
    }
  }
  ${MediaFieldsFragment}
`;

export const ARRANGE_MEDIAS = gql`
  mutation ArrangeMedias($list: [ArrangeInput!]!) {
    arrangeMedias(list: $list) {
      ...MediaFields
    }
  }
  ${MediaFieldsFragment}
`;
