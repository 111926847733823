import React from 'react';

import MyTextField from 'elements/MyTextField';

import withDebounceInput from 'hocs/withDebounceInput';

const PostalCodeField = props => {
  const { debounceInputRef, onDebounce: _, ...rest } = props;
  return <MyTextField inputRef={debounceInputRef} {...rest} />;
};
const EnchancedPostalCodeField = withDebounceInput()(PostalCodeField);
EnchancedPostalCodeField.displayName = 'PostalCodeField';

export default EnchancedPostalCodeField;
