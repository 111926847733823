import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';
import * as yup from 'yup';

import { FETCH_MAIL_TEMPLATE, EDIT_MAIL_TEMPLATE, REMOVE_MAIL_TEMPLATE } from 'gqls/mail';
import { convertIntoTemplateForm } from 'formConvertors/mail';
import { redirect } from 'browser';
import wrapMenu from 'hocs/wrapMenu';

import MailTemplateForm from 'forms/MailTemplateForm';

const styles = theme => ({
  main: {
    width: 800,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

class EditMailTemplate extends React.Component {
  handleSucceed = () => {
    redirect(`/mailTemplates`);
  };

  render() {
    const { classes, data, mutate, removeMailTemplate } = this.props;

    if (data.loading) return null;

    const initialValues = convertIntoTemplateForm(data.fetchMailTemplate);

    return (
      <main className={classes.main}>
        <MailTemplateForm
          action="EditMailTemplate"
          schema={schema}
          initialValues={initialValues}
          mutate={mutate}
          removeMailTemplate={removeMailTemplate}
          onSucceed={this.handleSucceed}
        />
      </main>
    );
  }
}

const schema = yup.object().shape({
  name: yup.string().required(),
  subject: yup.string().required(),
  body: yup.string().required(),
  pdfType: yup.string(),
  title: yup.string(),
  greeting: yup.string(),
  payment: yup.string(),
  order: yup.number().required()
});

export default compose(
  withStyles(styles),
  wrapMenu({
    appBarText: 'メールテンプレートマスタ'
  }),
  graphql(FETCH_MAIL_TEMPLATE, {
    options: props => ({
      variables: {
        where: { id: props.match.params.mailTemplateId }
      }
    })
  }),
  graphql(EDIT_MAIL_TEMPLATE),
  graphql(REMOVE_MAIL_TEMPLATE, { name: 'removeMailTemplate' })
)(EditMailTemplate);
