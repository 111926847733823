import React from 'react';

import { getPeriods, getPassedYearMonths } from 'utils/period';

const periods = getPeriods();

export const withPeriodFields = (options = { willSubmit: false }) => Component => {
  class C extends React.Component {
    constructor(props) {
      super(props);

      const { values } = props;
      if (values.period !== '') {
        const yearMonths = getPassedYearMonths(+values.period);
        this.state = {
          yearMonths
        };
      } else {
        this.state = {
          yearMonths: []
        };
      }
    }

    handlePeriodChange = e => {
      const { willSubmit } = options;
      const { handleChange, setFieldValue, submitForm } = this.props;

      handleChange(e);

      const period = e.target.value;

      const yearMonths = getPassedYearMonths(period);
      this.setState({ yearMonths });
      setFieldValue('yearMonth', '');

      if (willSubmit) {
        submitForm();
      }
    };

    render() {
      return (
        <Component
          {...this.props}
          periods={periods}
          yearMonths={this.state.yearMonths}
          handlePeriodChange={this.handlePeriodChange}
        />
      );
    }
  }

  C.displayName = `withPeriodFields(${Component.displayName || Component.name})`;

  return C;
};

export default withPeriodFields;
