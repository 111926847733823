import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { redirect } from 'browser';

const styles = theme => ({
  titleArea: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary
  },
  icon: {
    marginRight: theme.spacing.unit
  }
});

const SettingCard = ({ classes, title, desc, uri, icon }) => {
  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={() => {
          redirect(uri);
        }}
      >
        <CardContent>
          <div className={classes.titleArea}>
            <span className={classes.icon}>{icon}</span>
            <Typography component="h3" variant="h6">
              {title}
            </Typography>
          </div>
          <Typography component="p">{desc}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default withStyles(styles)(SettingCard);
