import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    minHeight: 48
  },
  title: {
    flex: '0 0 auto'
  },
  actions: {
    marginLeft: theme.spacing.unit * 2,
    color: theme.palette.text.secondary
  },
  formControlLabel: {
    marginLeft: theme.spacing.unit
  }
});

class FieldsFormToolbar extends React.Component {
  handleClick = () => {
    const { onAddClick } = this.props;
    onAddClick();
  };

  render() {
    const {
      classes,
      title,
      movable,
      onToggleMovable,
      isReport,
      isNoFieldName,
      isNoFieldValue,
      handleChange,
      handleBlur
    } = this.props;

    return (
      <Toolbar className={classes.root}>
        <div className={classes.title}>
          <Typography variant="subtitle1">{title}</Typography>
        </div>
        <div className={classes.actions}>
          <Fab size="small" onClick={this.handleClick} disabled={movable}>
            <AddIcon />
          </Fab>
          {isReport && (
            <Button
              size="small"
              color="primary"
              onClick={onToggleMovable}
              className={classes.formControlLabel}
            >
              {movable ? 'この並び替えに変更' : '並び替えを表示'}
            </Button>
          )}
          {isNoFieldName && (
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  name={isNoFieldName}
                  checked={isNoFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="ナシ"
            />
          )}
        </div>
      </Toolbar>
    );
  }
}

export default withStyles(styles)(FieldsFormToolbar);
