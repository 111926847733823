import React from 'react';
import { compose, graphql } from 'react-apollo';
import * as yup from 'yup';

import { ADD_VOUCHER_MESSAGE, REMOVE_VOUCHER_MESSAGE } from 'gqls/program';
import { UserContext } from 'contexts';

import MessagesColumn from 'elements/MessagesColumn';

class VoucherMessagesColumn extends React.Component {
  createHandler = fieldName => data => {
    const { setValues } = this.props;
    setValues(data[fieldName]);
  };

  render() {
    const { program, addMessage, removeMessage } = this.props;

    return (
      <UserContext.Consumer>
        {value => (
          <MessagesColumn
            messages={program.voucherMessages}
            schema={schema}
            initialValues={initialValues}
            user={value}
            program={program}
            addMessage={addMessage}
            removeMessage={removeMessage}
            onAdd={this.createHandler('addVoucherMessage')}
            onRemove={this.createHandler('removeVoucherMessage')}
          />
        )}
      </UserContext.Consumer>
    );
  }
}

const schema = yup.object().shape({
  content: yup.string().required()
});

const initialValues = {
  content: ''
};

export default compose(
  graphql(ADD_VOUCHER_MESSAGE, { name: 'addMessage' }),
  graphql(REMOVE_VOUCHER_MESSAGE, { name: 'removeMessage' })
)(VoucherMessagesColumn);
