import React from 'react';

export const withReactSelectHandler = () => Component => {
  class C extends React.Component {
    createHandleSelect = (field, options = { willSubmit: false }) => value => {
      const { willSubmit } = options;
      const { setFieldTouched, setFieldValue, submitForm } = this.props;

      setFieldTouched(field, true);

      if (value) {
        setFieldValue(field, value.id);
      } else {
        setFieldValue(field, '');
      }

      if (willSubmit) {
        submitForm();
      }
    };

    render() {
      return <Component {...this.props} createHandleSelect={this.createHandleSelect} />;
    }
  }

  C.displayName = `withReactSelectHandler(${Component.displayName || Component.name})`;

  return C;
};

export default withReactSelectHandler;
