import React from 'react';
import { FieldArray } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import withFormFieldRow from 'hocs/withFormFieldRow';
import { voucherExpenseInitialValues } from 'initialValues';

import MyTextField from 'elements/MyTextField';
import FieldsFormToolbar from 'elements/FieldsFormToolbar';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  table: {
    tableLayout: 'fixed'
  },
  tableHeadRow: {
    height: 48
  },
  tableHeadCell: {
    paddingRight: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit / 2
  },
  tableBodyCell: {
    paddingRight: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit / 2
  }
});

class FieldRow extends React.Component {
  render() {
    const {
      expenseOptions,
      index,
      classes,
      values,
      errors,
      touched,
      handleChange,
      handleBlur
    } = this.props;
    const { model, handleRemove, handleAmountBlur, handleUnitPriceBlur } = this.props;

    const rowTouched = touched.voucherExpenses ? touched.voucherExpenses[index] : {};
    const rowErrors = errors.voucherExpenses ? errors.voucherExpenses[index] : {};

    return (
      <TableRow>
        <TableCell
          className={classes.tableBodyCell}
          align="center"
          padding="none"
          component="th"
          scope="row"
        >
          <MyTextField
            name={`${model}.${index}.date`}
            type="date"
            values={values}
            touched={rowTouched}
            errors={rowErrors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            required
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.expenseRelatedId`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            select
          >
            <MenuItem key="" value="" />
            {expenseOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </MyTextField>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none" />
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.amount`}
            type="number"
            values={values}
            touched={rowTouched}
            errors={rowErrors}
            margin="none"
            smallFont
            inputProps={{ step: '1' }}
            onChange={handleChange}
            onBlur={handleAmountBlur}
            fullWidth
            required
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.unitPrice`}
            type="number"
            values={values}
            touched={rowTouched}
            errors={rowErrors}
            margin="none"
            smallFont
            inputProps={{ step: '1' }}
            onChange={handleChange}
            onBlur={handleUnitPriceBlur}
            fullWidth
            required
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.price`}
            type="number"
            values={values}
            touched={rowTouched}
            errors={rowErrors}
            margin="none"
            smallFont
            inputProps={{ step: '1' }}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            required
            disabled
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.detail`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          {!['Archived', 'Integrated'].includes(values.voucherStatus) && (
            <IconButton aria-label="Delete" onClick={handleRemove}>
              <DeleteIcon fontSize="small" color="secondary" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  }
}
const EnchancedFieldRow = withFormFieldRow('voucherExpenses', {
  willSubmitWhenRemove: props => props.action === 'EditVoucher'
})(FieldRow);

function VoucherExpenseFieldsForm(props) {
  const { classes, values } = props;

  return (
    <FieldArray
      name="voucherExpenses"
      render={arrayHelpers => (
        <Paper className={classes.root}>
          <FieldsFormToolbar
            title="経費"
            onAddClick={() => {
              arrayHelpers.push(voucherExpenseInitialValues);
            }}
          />
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeadRow}>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 132 }}
                >
                  日付
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 160 }}
                >
                  内容
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 320 }}
                />
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  数量
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  単価
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  金額
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 416 }}
                >
                  詳細
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  削除
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <React.Fragment>
                {values.voucherExpenses &&
                  values.voucherExpenses.map((row, index) => {
                    return (
                      <EnchancedFieldRow
                        key={index}
                        index={index}
                        row={row}
                        arrayHelpers={arrayHelpers}
                        {...props}
                      />
                    );
                  })}
              </React.Fragment>
            </TableBody>
          </Table>
        </Paper>
      )}
    />
  );
}

export default compose(withStyles(styles))(VoucherExpenseFieldsForm);
