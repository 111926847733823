import React from 'react';
import { withFormik } from 'formik';
import { compose } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

import { relateFields } from 'utils/form';
import withPostalCodeField from 'hocs/withPostalCodeField';

import MyTextField from 'elements/MyTextField';
import PostalCodeField from 'elements/PostalCodeField';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(theme.breakpoints.values.md + theme.spacing.unit * 2 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  form: {
    marginTop: theme.spacing.unit
  },
  menu: {
    width: 200
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center'
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

class PersonnelForm extends React.Component {
  handleCopy = () => {
    const { customer, setFieldValue } = this.props;
    ['postalCode', 'prefecture', 'address', 'tel'].forEach(field => {
      setFieldValue(field, customer[field]);
    });
  };

  render() {
    const {
      action,
      customer,
      classes,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit
    } = this.props;
    const { handlePostalCodeInputDebounceChange } = this.props;

    let buttomLabel;
    switch (action) {
      case 'NewPersonnel':
        buttomLabel = '登録';
        break;
      case 'EditPersonnel':
        buttomLabel = '変更';
        break;
      default:
        throw new Error();
    }

    return (
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" align="center">
          担当者情報
        </Typography>
        <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={24}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <div className={classes.buttons}>
                <Button color="primary" size="small" variant="text" onClick={this.handleCopy}>
                  顧客情報から住所をコピー
                </Button>
              </div>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={12}>
              <Typography component="h2" variant="subtitle1">
                {customer.name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="name"
                values={values}
                touched={touched}
                errors={errors}
                label="名前"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="nameKana"
                values={values}
                touched={touched}
                errors={errors}
                label="名前（カナ）"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="department"
                values={values}
                touched={touched}
                errors={errors}
                label="部署"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <PostalCodeField
                name="postalCode"
                values={values}
                touched={touched}
                errors={errors}
                label="郵便番号"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                onDebounce={handlePostalCodeInputDebounceChange}
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="prefecture"
                values={values}
                touched={touched}
                errors={errors}
                label="都道府県"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="address"
                values={values}
                touched={touched}
                errors={errors}
                label="住所"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="email"
                type="email"
                values={values}
                touched={touched}
                errors={errors}
                label="Email Address"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="tel"
                values={values}
                touched={touched}
                errors={errors}
                label="TEL"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="memo"
                values={values}
                touched={touched}
                errors={errors}
                label="備考"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                multiline
                rowsMax="10"
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="registerDate"
                type="date"
                values={values}
                touched={touched}
                errors={errors}
                label="登録日"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="isRetired"
                values={values}
                touched={touched}
                errors={errors}
                label="在職状況"
                margin="normal"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
                select
              >
                <MenuItem value={false}>在職中</MenuItem>
                <MenuItem value={true}>退職済み</MenuItem>
              </MyTextField>
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button type="submit" variant="contained" color="primary" className={classes.submit}>
              {buttomLabel}
            </Button>
          </div>
        </form>
      </Paper>
    );
  }
}

const takeNewValues = values => {
  return {
    data: relateFields(values)
  };
};

const takeEditValues = (values, initialValues) => {
  const { id, ...rest } = values;
  return {
    data: relateFields(rest, initialValues),
    where: {
      id
    }
  };
};

export default compose(
  withStyles(styles),
  withFormik({
    mapPropsToValues: props => props.initialValues,
    validationSchema: props => props.schema,
    handleSubmit: (values, { props, setSubmitting }) => {
      const { action, initialValues, mutate, onSucceed } = props;

      let variables;
      switch (action) {
        case 'NewPersonnel':
          variables = takeNewValues(values);
          break;
        case 'EditPersonnel':
          variables = takeEditValues(values, initialValues);
          break;
        default:
          throw new Error();
      }

      console.log(variables);

      mutate({
        variables
      })
        .then(onSucceed)
        .catch(error => {})
        .finally(() => {
          setSubmitting(false);
        });
    },
    displayName: 'PersonnelForm'
  }),
  withPostalCodeField()
)(PersonnelForm);
