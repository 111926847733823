import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 2,
    alignItems: 'flex-end'
  },
  item: {
    display: 'flex'
  },
  prev: {
    justifyContent: 'flex-start'
  },
  next: {
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
});

const JumpPageWrapper = props => {
  const { classes, prev, next } = props;

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={6} className={classNames(classes.item, classes.prev)}>
        {prev}
      </Grid>
      <Grid item xs={6} className={classNames(classes.item, classes.next)}>
        {next}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(JumpPageWrapper);
