import moment from 'moment';

export const getCurrentDateTime = () => {
  return moment().format(`YYYY-MM-DDTHH:mm:ssZ`);
};

export const convertIntoDateFormat = date => {
  const format = 'YYYY-MM-DD';
  if (date) {
    return moment(date).format(format);
  } else {
    return moment().format(format);
  }
};

export const convertIntoDateTimeFormat = date => {
  const format = 'YYYY-MM-DD HH:mm';
  if (date) {
    return moment(date).format(format);
  } else {
    return moment().format(format);
  }
};

export function timeSpan(startHours, startMinutes, endHours, endMinutes) {
  const allInteger = [startHours, startMinutes, endHours, endMinutes].every(ele =>
    Number.isInteger(ele)
  );
  if (!allInteger) return 0;
  const minutesHour = ((endMinutes - startMinutes) * (5 / 3)) / 100;
  return endHours - startHours + minutesHour;
}
