import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { graphql, compose } from 'react-apollo';

import { SEARCH_PERSONNELS, FETCH_CUSTOMER } from 'gqls/customer';
import { redirect } from 'browser';
import withUbs from 'hocs/withUbs';
import wrapMenu from 'hocs/wrapMenu';

import CustomerPanel from 'elements/CustomerPanel';
import PersonnelTable from 'elements/PersonnelTable';
import FloatingAddButton from 'elements/FloatingAddButton';

const styles = theme => ({
  main: {
    flex: '1 1 auto',
    paddingTop: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  }
});

const defaultSearchParams = {
  order: 'desc',
  orderBy: 'registerDate',
  page: '0',
  rowsPerPage: '50'
};

class PersonnelList extends React.Component {
  render() {
    const {
      classes,
      fetchCustomer,
      list,
      count,
      order,
      orderBy,
      rowsPerPage,
      page,
      updateSearchParams
    } = this.props;

    if (fetchCustomer.loading) return null;

    return (
      <main className={classes.main}>
        <CustomerPanel customer={fetchCustomer.fetchCustomer} />
        <PersonnelTable
          order={order}
          orderBy={orderBy}
          rowsPerPage={rowsPerPage}
          page={page}
          count={count}
          personnels={list}
          updateSearchParams={updateSearchParams}
        />
        <FloatingAddButton
          handleClick={() => {
            redirect(`/customers/${fetchCustomer.fetchCustomer.id}/personnels/new`);
          }}
        />
      </main>
    );
  }
}

const convert = ({ props }) => {
  const {
    match: {
      params: { customerId }
    }
  } = props;

  const searchParams = {};
  searchParams['AND'] = [];
  searchParams['AND'].push({ customer: { id: customerId } });

  return searchParams;
};

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: '顧客情報 - 担当者リスト' }),
  withUbs({
    defaultSearchParams,
    gql: SEARCH_PERSONNELS,
    convertGraphqlParams: convert,
    takeResult: ({ data }) => {
      const {
        searchPersonnels: { personnels, count }
      } = data;
      return {
        list: personnels,
        count: count
      };
    }
  }),
  graphql(FETCH_CUSTOMER, {
    name: 'fetchCustomer',
    options: props => ({
      variables: {
        where: { id: props.match.params.customerId }
      }
    })
  })
)(PersonnelList);
