import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

import { ProgressContext } from 'contexts';
import { FETCH_VOUCHER, EDIT_VOUCHER } from 'gqls/program';
import { convertIntoEditVoucherForm } from 'formConvertors/program';
import { newWindow, redirect } from 'browser';
import { getIntegratedVouchers, getOtherVouchers } from 'modules';
import { styles as baseStyles } from './EditReportScreen';
import wrapMenu from 'hocs/wrapMenu';
import { hoursField, minutesField } from 'schemas';

import JumpPageWrapper from 'elements/JumpPageWrapper';
import MessageIcon from 'elements/MessageIcon';
import VoucherForm from 'forms/VoucherForm';
import VoucherMessagesColumn from 'elements/VoucherMessagesColumn';

const styles = theme => ({
  ...baseStyles(theme),
  formControl: {
    width: 300
  }
});

function SelectIntegratedVouchers({ classes, vouchers, onSelecte }) {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel shrink>統合元の作業伝票に移動</InputLabel>
      <NativeSelect onChange={onSelecte}>
        <option value="" />
        {vouchers.map(voucher => (
          <option
            key={voucher.id}
            value={voucher.id}
          >{`${voucher.title} (No.${voucher.program.programNo})`}</option>
        ))}
      </NativeSelect>
    </FormControl>
  );
}

function SelectArchivedVouchers({ classes, vouchers, onSelecte }) {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel shrink>他のアーカイブバージョンに移動</InputLabel>
      <NativeSelect onChange={onSelecte}>
        <option value="" />
        {vouchers.map(voucher => (
          <option key={voucher.id} value={voucher.id}>{`第${voucher.revision}版`}</option>
        ))}
      </NativeSelect>
    </FormControl>
  );
}

class EditVoucher extends React.Component {
  state = { messageColumnShowState: false, editVoucher: undefined };

  createHandleClick = report => () => {
    newWindow(`/reports/${report.id}`);
  };

  handleAnotherVoucherSelected = e => {
    const value = e.target.value;
    redirect(`/vouchers/${value}`);
  };

  handleToggleMessageColumnShowState = () => {
    this.setState({ messageColumnShowState: !this.state.messageColumnShowState });
  };

  onSucceed = ({ data }) => {
    this.setValues(data.editVoucher);
  };

  setValues = values => {
    this.setState({ editVoucher: values });
  };

  render() {
    const { classes, data, mutate } = this.props;

    if (data.loading) return null;

    const voucher = this.state.editVoucher ? this.state.editVoucher : data.fetchVoucher;

    const program = voucher.program;
    const customer = voucher.customer;
    const report = voucher.program.report;
    const initialValues = convertIntoEditVoucherForm(voucher);

    const linkText = '<< ワークレポートへ';

    const integratedVouchers = getIntegratedVouchers(voucher.program.integratedFroms);

    const otherVouchers = getOtherVouchers(voucher, program.vouchers);

    return (
      <ProgressContext.Consumer>
        {({ updateProgressState }) => (
          <div className={classes.root}>
            <div className={classes.mainColumn}>
              <main className={classes.main}>
                <JumpPageWrapper
                  prev={
                    report && (
                      <Button size="small" color="primary" onClick={this.createHandleClick(report)}>
                        {linkText}
                      </Button>
                    )
                  }
                  next={
                    <React.Fragment>
                      {voucher.program.integratedFroms.length > 0 && (
                        <SelectIntegratedVouchers
                          classes={classes}
                          vouchers={integratedVouchers}
                          onSelecte={this.handleAnotherVoucherSelected}
                        />
                      )}
                      {otherVouchers.length > 0 && (
                        <SelectArchivedVouchers
                          classes={classes}
                          vouchers={otherVouchers}
                          onSelecte={this.handleAnotherVoucherSelected}
                        />
                      )}
                      <IconButton
                        className={classes.mailButton}
                        onClick={this.handleToggleMessageColumnShowState}
                      >
                        <Badge badgeContent={program.voucherMessages.length} color="primary">
                          <MessageIcon />
                        </Badge>
                      </IconButton>
                    </React.Fragment>
                  }
                />
                {data.fetchVoucher.voucherStatus === 'Integrated' && (
                  <Typography variant="h5" color="secondary">
                    ※ この作業伝票は統合されたものです。
                    <br />
                    変更することはできません。
                  </Typography>
                )}
                {data.fetchVoucher.voucherStatus === 'Archived' && (
                  <Typography variant="h5" color="secondary">
                    ※ この作業伝票は改訂されたものです。
                    <br />
                    変更することはできません。
                  </Typography>
                )}
                <VoucherForm
                  action="EditVoucher"
                  schema={schema}
                  initialValues={initialValues}
                  mutate={mutate}
                  onSucceed={this.onSucceed}
                  updateProgressState={updateProgressState}
                  customer={customer}
                  paymentDay={data.getVoucherPaymentDay}
                />
              </main>
            </div>
            {this.state.messageColumnShowState && (
              <div className={classes.rightColumn}>
                <VoucherMessagesColumn program={program} setValues={this.setValues} />
              </div>
            )}
          </div>
        )}
      </ProgressContext.Consumer>
    );
  }
}

const schema = yup.object().shape({
  customerRelatedId: yup.string().required(),
  personnelRelatedId: yup.string(),
  userRelatedId: yup.string().required(),
  voucherOperations: yup.array().of(
    yup.object().shape({
      date: yup.date().required(),
      section: yup.string(),
      operationRelatedId: yup.string(),
      startHours: hoursField,
      startMinutes: minutesField,
      endHours: hoursField,
      endMinutes: minutesField,
      unitLabel: yup.string(),
      amount: yup.string(),
      userRelatedId: yup.string(),
      charge: yup.string(),
      rating: yup.string(),
      detail: yup.string()
    })
  ),
  title: yup.string().required(),
  subTitle: yup.string(),
  memo: yup.string()
});

export default compose(
  withStyles(styles),
  wrapMenu({
    appBarText: '作業伝票',
    colorOrange: true
  }),
  graphql(FETCH_VOUCHER, {
    options: props => ({
      variables: {
        where: { id: props.match.params.voucherId }
      }
    })
  }),
  graphql(EDIT_VOUCHER)
)(EditVoucher);
