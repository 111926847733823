import gql from 'graphql-tag';

export const GET_MONTHLY_EARNINGS = gql`
  query GetMonthlyEarnings($term: Int!, $customerId: String) {
    getMonthlyEarnings(term: $term, customerId: $customerId) {
      yearMonth
      summary
      count
    }
  }
`;

export const GET_CUSTOMER_EARNINGS = gql`
  query GetCustomerEarnings($term: Int!, $month: Int) {
    getCustomerEarnings(term: $term, month: $month) {
      customerName
      summary
      count
      rate
    }
  }
`;
