import React from 'react';
import { compose } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import { SEARCH_REPORT_MEDIAS } from 'gqls/program';
import { getPeriodRange, getYearMonthRange } from 'utils/period';
import { convertIntoDateFormat } from 'utils/date';
import enums from 'enums';
import withUbs from 'hocs/withUbs';
import { downloadCsv } from 'browser';
import wrapMenu from 'hocs/wrapMenu';

import SearchReportMediaForm from 'forms/SearchReportMediaForm';
import ReportMediaTable from 'elements/ReportMediaTable';

const styles = theme => ({
  main: {
    flex: '1 1 auto',
    minWidth: 0,
    paddingTop: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  }
});

const defaultSearchParams = {
  order: 'desc',
  orderBy: 'date',
  page: '0',
  rowsPerPage: '50'
};

class ReportMediaList extends React.Component {
  formatMediaFields = ([...medias]) => {
    return medias.map(media => ({
      id: media.id,
      reportId: media.report.id,
      programNo: media.report.program.programNo,
      date: convertIntoDateFormat(media.date),
      title: media.report.title,
      mediaName: media.media ? media.media.name : '',
      amount: media.amount,
      user: media.user ? media.user.name : '',
      mediaType: enums.REPORT_MEDIA_TYPE[media.mediaType],
      base: enums.REPORT_MEDIA_BASE[media.base],
      check: enums.CHECK[media.check],
      customerName: media.report.customer ? media.report.customer.name : ''
    }));
  };

  createHandleCopyClick = medias => () => {
    const head =
      '"No.","日付","PROGRAM","メディア","数量","作業者","種類","場所","チェック","クライアント"';
    const csvContent =
      head +
      '\n' +
      medias
        .map(media => {
          delete media.id;
          delete media.reportId;
          return Object.values(media)
            .map(v => {
              if (v) {
                return typeof v === 'string' ? `"${v.replace('"', '""')}"` : `"${v}"`;
              } else {
                return '""';
              }
            })
            .join(',');
        })
        .join('\n');

    downloadCsv('メディアリスト.csv', csvContent);
  };

  render() {
    const { classes } = this.props;
    const { list, count, order, orderBy, rowsPerPage, page, updateSearchParams } = this.props;

    const medias = this.formatMediaFields(list);

    return (
      <main className={classes.main}>
        <SearchReportMediaForm
          statusEnum={enums.REPORT_STATUS}
          updateSearchParams={updateSearchParams}
          onCopyClick={this.createHandleCopyClick(medias)}
        />
        <ReportMediaTable
          order={order}
          orderBy={orderBy}
          rowsPerPage={rowsPerPage}
          page={page}
          count={count}
          medias={medias}
          updateSearchParams={updateSearchParams}
        />
      </main>
    );
  }
}

const convert = ({ params }) => {
  const searchParams = {};
  searchParams['AND'] = [];

  Object.entries(params).forEach(([key, value]) => {
    switch (key) {
      case 'period':
        const periodRange = getPeriodRange(+value);
        searchParams['AND'].push({ date_gte: periodRange.begin });
        searchParams['AND'].push({ date_lte: periodRange.end });
        break;
      case 'yearMonth':
        const yearMonthRange = getYearMonthRange(value);
        searchParams['AND'].push({ date_gte: yearMonthRange.begin });
        searchParams['AND'].push({ date_lte: yearMonthRange.end });
        break;
      case 'title':
        const titleParams = [];
        titleParams.push({ title_contains: value });
        titleParams.push({ subTitle_contains: value });
        searchParams['AND'].push({ report: { OR: titleParams } });
        break;
      case 'programNo':
        searchParams['AND'].push({ report: { program: { programNo: +value } } });
        break;
      case 'mediaRelatedId':
        searchParams['AND'].push({ media: { id: value } });
        break;
      case 'mediaType':
        searchParams['AND'].push({ mediaType: value });
        break;
      case 'base':
        searchParams['AND'].push({ base: value });
        break;
      default:
        throw new Error();
    }
  });

  return searchParams;
};

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: 'メディアリスト' }),
  withUbs({
    defaultSearchParams,
    gql: SEARCH_REPORT_MEDIAS,
    convertGraphqlParams: convert,
    takeResult: ({ data }) => {
      const {
        searchReportMedias: { reportMedias, count }
      } = data;
      return {
        list: reportMedias,
        count: count
      };
    }
  })
)(ReportMediaList);
