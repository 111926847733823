import React from 'react';
import { withFormik } from 'formik';
import { compose, graphql } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { SECTION_LIST } from 'gqls/section';
import constants from 'myConstants';
import enums from 'enums';
import { convertIntoSelectFieldValues, relateFields, takeOptionField } from 'utils/form';

import MyTextField from 'elements/MyTextField';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  form: {
    marginTop: theme.spacing.unit
  },
  menu: {
    width: 200
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing.unit / 4
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center'
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

const UserForm = props => {
  const {
    classes,
    action,
    data,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit
  } = props;

  if (data.loading) return null;

  const sectionOptions = convertIntoSelectFieldValues(data.sections);

  let formLabel;
  let buttomLabel;
  switch (action) {
    case 'SignUp':
      formLabel = 'サインアップ';
      buttomLabel = 'Sign up';
      break;
    case 'EditMyself':
      formLabel = 'あなたの情報';
      buttomLabel = '変更';
      break;
    case 'EditUser':
      formLabel = 'スタッフの情報';
      buttomLabel = '変更';
      break;
    default:
      throw new Error();
  }

  return (
    <Paper className={classes.paper}>
      <Typography component="h1" variant="h4" align="center">
        {formLabel}
      </Typography>
      <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>
            <MyTextField
              name="name"
              values={values}
              touched={touched}
              errors={errors}
              label="名前"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MyTextField
              name="nameKana"
              values={values}
              touched={touched}
              errors={errors}
              label="名前（カナ）"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MyTextField
              name="loginId"
              values={values}
              touched={touched}
              errors={errors}
              label="Login ID"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MyTextField
              name="email"
              values={values}
              touched={touched}
              errors={errors}
              label="Email Address"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="nope"
              required
              fullWidth
            />
          </Grid>
          {action === 'SignUp' && (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <MyTextField
                  name="password"
                  type="password"
                  values={values}
                  touched={touched}
                  errors={errors}
                  label="パスワード"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="new-password"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextField
                  name="passwordConfirm"
                  type="password"
                  values={values}
                  touched={touched}
                  errors={errors}
                  label="パスワード（確認）"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="new-password"
                  required
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          )}

          <Grid item xs={12} sm={6}>
            <MyTextField
              name="signName"
              values={values}
              touched={touched}
              errors={errors}
              label="サインネーム"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
            />
          </Grid>

          {['SignUp', 'EditUser'].includes(action) && (
            <Grid item xs={12} sm={6}>
              <MyTextField
                name="employeeId"
                values={values}
                touched={touched}
                errors={errors}
                label="社員番号"
                margin="normal"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12}>
            <MyTextField
              name="sectionsRelatedIds"
              values={values}
              touched={touched}
              errors={errors}
              label="部署"
              onChange={handleChange}
              onBlur={handleBlur}
              SelectProps={{
                multiple: true,
                MenuProps: {
                  className: classes.menu
                },
                renderValue: selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={takeOptionField(sectionOptions, value, 'label')}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )
              }}
              fullWidth
              select
            >
              {sectionOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </MyTextField>
          </Grid>

          {['SignUp', 'EditUser'].includes(action) && (
            <Grid item xs={12} sm={6}>
              <MyTextField
                name="joinedDate"
                type="date"
                values={values}
                touched={touched}
                errors={errors}
                label="入社日"
                margin="normal"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
              />
            </Grid>
          )}

          {action === 'EditUser' && (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <MyTextField
                  name="userLevel"
                  values={values}
                  touched={touched}
                  errors={errors}
                  label="権限レベル"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  select
                >
                  {Object.entries(enums.USER_LEVEL).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextField
                  name="isRetired"
                  values={values}
                  touched={touched}
                  errors={errors}
                  label="在職状況"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  select
                >
                  <MenuItem value={false}>在職中</MenuItem>
                  <MenuItem value={true}>退職済み</MenuItem>
                </MyTextField>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        <div className={classes.buttons}>
          <Button type="submit" variant="contained" color="primary" className={classes.submit}>
            {buttomLabel}
          </Button>
        </div>
      </form>
    </Paper>
  );
};

const takeSignUpValues = values => {
  const { passwordConfirm: _, ...rest } = values;
  const newValues = relateFields(rest);
  return {
    data: newValues
  };
};

const takeEditMyselfValues = (values, initialValues) => {
  const { id, ...rest } = values;
  const newValues = relateFields(rest, initialValues);
  return {
    data: newValues,
    where: {
      id
    }
  };
};

const takeEditUserValues = (values, initialValues) => {
  const { id, ...rest } = values;
  return {
    data: relateFields(rest, initialValues),
    where: {
      id
    }
  };
};

export default compose(
  withStyles(styles),
  graphql(SECTION_LIST),
  withFormik({
    mapPropsToValues: props => props.initialValues,
    validationSchema: props => props.schema,
    handleSubmit: (values, { props, setSubmitting, setFieldError }) => {
      const { action, initialValues, mutate, onSucceed } = props;

      let variables;
      switch (action) {
        case 'SignUp':
          variables = takeSignUpValues(values);
          break;
        case 'EditMyself':
          variables = takeEditMyselfValues(values, initialValues);
          break;
        case 'EditUser':
          variables = takeEditUserValues(values, initialValues);
          break;
        default:
          throw new Error();
      }

      console.log(variables);

      mutate({
        variables
      })
        .then(onSucceed)
        .catch(error => {
          if (error.graphQLErrors) {
            error.graphQLErrors.forEach(error => {
              if (error.code === 3010) {
                if (error.message.includes('loginId')) {
                  setFieldError('loginId', constants.MESSAGE.UNIQUE_CONSTRAINT_VIOLATION);
                } else if (error.message.includes('email')) {
                  setFieldError('email', constants.MESSAGE.UNIQUE_CONSTRAINT_VIOLATION);
                } else if (error.message.includes('employeeId')) {
                  setFieldError('employeeId', constants.MESSAGE.UNIQUE_CONSTRAINT_VIOLATION);
                }
              }
            });
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    displayName: 'UserForm'
  })
)(UserForm);
