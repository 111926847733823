import React from 'react';
import { compose, graphql } from 'react-apollo';
import * as yup from 'yup';

import { ADD_REPORT_MESSAGE, REMOVE_REPORT_MESSAGE } from 'gqls/program';
import { UserContext } from 'contexts';

import MessagesColumn from 'elements/MessagesColumn';

class ReportMessagesColumn extends React.Component {
  createHandler = fieldName => data => {
    const { setValues } = this.props;
    setValues(data[fieldName]);
  };

  render() {
    const { program, addMessage, removeMessage } = this.props;

    return (
      <UserContext.Consumer>
        {value => (
          <MessagesColumn
            messages={program.reportMessages}
            schema={schema}
            initialValues={initialValues}
            useSection
            user={value}
            program={program}
            addMessage={addMessage}
            removeMessage={removeMessage}
            onAdd={this.createHandler('addReportMessage')}
            onRemove={this.createHandler('removeReportMessage')}
          />
        )}
      </UserContext.Consumer>
    );
  }
}

const schema = yup.object().shape({
  content: yup.string().required(),
  section: yup.string().required()
});

const initialValues = {
  content: '',
  section: ''
};

export default compose(
  graphql(ADD_REPORT_MESSAGE, { name: 'addMessage' }),
  graphql(REMOVE_REPORT_MESSAGE, { name: 'removeMessage' })
)(ReportMessagesColumn);
