import React from 'react';
import { compose, graphql } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import { LABEL_LIST, ADD_LABEL, EDIT_LABEL, REMOVE_LABEL, ARRANGE_LABELS } from 'gqls/label';
import wrapDragDropList from 'hocs/wrapDragDropList';
import wrapMenu from 'hocs/wrapMenu';

import LabelForm from 'forms/LabelForm';

const DragDropList = compose(
  graphql(ADD_LABEL, { name: 'addLabel' }),
  graphql(EDIT_LABEL, { name: 'editLabel' }),
  graphql(REMOVE_LABEL, { name: 'removeLabel' }),
  graphql(ARRANGE_LABELS, { name: 'arrangeLabels' }),
  wrapDragDropList({
    label: 'ラベル',
    defaultValues: {
      name: 'ラベル',
      color: '#f44336'
    },
    convertIntoForm: values => values,
    convertIntoGql: values => values,
    handleAddName: 'addLabel',
    handleEditName: 'editLabel',
    handleRemoveName: 'removeLabel',
    handleArrangeName: 'arrangeLabels'
  })
)(LabelForm);

const styles = theme => ({
  main: {
    width: 600,
    marginTop: theme.spacing.unit * 8,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 10,
    right: theme.spacing.unit * 10
  }
});

class LabelList extends React.Component {
  render() {
    const { classes, data } = this.props;

    if (data.loading) return null;

    return (
      <main className={classes.main}>
        <DragDropList list={data.labels} />
      </main>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  wrapMenu({ appBarText: 'ラベルマスタ' }),
  graphql(LABEL_LIST)
)(LabelList);
