import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'react-apollo';

import { SEARCH_PDFS } from 'gqls/mail';
import withUbs from 'hocs/withUbs';
import { redirect } from 'browser';
import wrapMenu from 'hocs/wrapMenu';

import SearchPdfForm from 'forms/SearchPdfForm';
import PdfTable from 'elements/PdfTable';

const styles = theme => ({
  main: {
    flex: '1 1 auto',
    paddingTop: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  }
});

const defaultSearchParams = {
  order: 'desc',
  orderBy: 'registerDate',
  page: '0',
  rowsPerPage: '50'
};

class PdfList extends React.Component {
  createHandleClick = pdf => () => {
    redirect(`/pdfs/${pdf.id}`);
  };

  render() {
    const { classes } = this.props;
    const { list, count, order, orderBy, rowsPerPage, page, updateSearchParams } = this.props;

    return (
      <main className={classes.main}>
        <SearchPdfForm updateSearchParams={updateSearchParams} />
        <PdfTable
          order={order}
          orderBy={orderBy}
          rowsPerPage={rowsPerPage}
          page={page}
          count={count}
          pdfs={list}
          createHandleClick={this.createHandleClick}
          updateSearchParams={updateSearchParams}
        />
      </main>
    );
  }
}

const convert = ({ params }) => {
  const searchParams = {};
  searchParams['AND'] = [];

  Object.entries(params).forEach(([key, value]) => {
    switch (key) {
      case 'pdfType':
        searchParams['AND'].push({ pdfType: value });
        break;
      case 'customer':
        searchParams['AND'].push({
          voucher: { customer: { OR: [{ name_contains: value }, { nameKana_contains: value }] } }
        });
        break;
      case 'title':
        const titleParams = [];
        titleParams.push({ title_contains: value });
        titleParams.push({ subTitle_contains: value });
        searchParams['AND'].push({ voucher: { OR: titleParams } });
        break;
      default:
        throw new Error();
    }
  });

  return searchParams;
};

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: 'PDFリスト' }),
  withUbs({
    defaultSearchParams,
    gql: SEARCH_PDFS,
    convertGraphqlParams: convert,
    takeResult: ({ data }) => {
      const {
        searchPdfs: { pdfs, count }
      } = data;
      return {
        list: pdfs,
        count: count
      };
    }
  })
)(PdfList);
