import React from 'react';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { redirect } from 'browser';
import wrapTablePagination from 'hocs/wrapTablePagination';
import { convertIntoDateFormat } from 'utils/date';

import SortableTableHead from 'elements/SortableTableHead';

const columns = [
  { id: 'name', align: 'left', disablePadding: false, label: '名前', sortable: true },
  { id: 'nameKana', align: 'left', disablePadding: false, label: '名前（カナ）', sortable: true },
  { id: 'section', align: 'left', disablePadding: false, label: '部署', sortable: false },
  { id: 'tel', align: 'left', disablePadding: false, label: 'TEL', sortable: false },
  { id: 'email', align: 'left', disablePadding: false, label: 'Email', sortable: false },
  { id: 'address', align: 'left', disablePadding: false, label: '住所', sortable: false },
  { id: 'registerDate', align: 'left', disablePadding: false, label: '登録日', sortable: true }
];

class HeadBody extends React.Component {
  handleClick = personnel => {
    redirect(`/customers/${personnel.customer.id}/personnels/${personnel.id}/edit`);
  };

  render() {
    const { order, orderBy, personnels, handleRequestSort } = this.props;
    return (
      <React.Fragment>
        <SortableTableHead
          order={order}
          orderBy={orderBy}
          columns={columns}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {personnels.map(personnel => (
            <TableRow
              hover
              onClick={() => this.handleClick(personnel)}
              tabIndex={-1}
              key={personnel.id}
            >
              <TableCell component="th" scope="row">
                {personnel.name}
              </TableCell>
              <TableCell>{personnel.nameKana}</TableCell>
              <TableCell>{personnel.department}</TableCell>
              <TableCell>{personnel.tel}</TableCell>
              <TableCell>{personnel.email}</TableCell>
              <TableCell>{personnel.address}</TableCell>
              <TableCell>{convertIntoDateFormat(personnel.registerDate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </React.Fragment>
    );
  }
}

export default wrapTablePagination()(HeadBody);
