const BASE_VOUCHER_STATUS = Object.freeze({
  Provisional: '（仮）',
  Registered: '入力',
  Checked: '確認',
  Mailed: 'メール',
  Fixed: '確定',
  Demanded: '請求書発行',
  Gray: '★',
  DarkGray: '★★',
  Completed: '終了'
});

export default Object.freeze({
  USER_LEVEL: {
    User: 'ユーザー',
    Admin: '管理者',
    System: 'システム'
  },
  REPORT_SECTION: {
    Eizo: '映像部',
    Onkyo: '音響部',
    Gaiga: '外画部',
    Eigyo: '営業部',
    Other: '請求関連'
  },
  CUSTOMER_TYPE: {
    Individual: '個人事業主',
    Corporation: '会社',
    TradeName: '屋号'
  },
  CUSTOMER_PAYMENT_TYPE: {
    MonthLater: '後払い',
    Other: 'その他'
  },
  REPORT_STATUS: {
    Registered: '作業待機',
    Finished: '作業終了',
    ToMa: 'toMA',
    ToGaiga: 'to外画',
    ToEed: 'toEED',
    Checked: '済',
    Approved: '承認',
    Completed: '終了'
  },
  UNIT_LABEL: {
    Hour: 'H',
    TotalHour: 'H（合計）',
    Point: '件'
  },
  CHARGE: {
    ResponsiblePerson: '責',
    Operator: 'OP',
    Assistant: 'アシ',
    Follow: 'フォロー'
  },
  SYSTEM_CHECK: {
    Check: '✔',
    NoCheck: 'ー'
  },
  CHECK: {
    Check: '✔'
  },
  REPORT_MEDIA_TYPE: {
    Sale: '販売',
    CarryOn: '持込',
    NoCharge: '請求ナシ'
  },
  REPORT_MEDIA_BASE: {
    Base: 'B',
    Yakumo: 'Y'
  },
  APPLY_UNIT_PRICE: {
    MaPrice: 'MaPrice',
    EedPrice: 'EedPrice'
  },
  SELECTABLE_VOUCHER_STATUS: {
    ...BASE_VOUCHER_STATUS
  },
  VOUCHER_STATUS: {
    ...BASE_VOUCHER_STATUS,
    Integrated: '統合済み',
    Archived: '履歴'
  },
  PDF_TYPE: {
    Estimate: '見積書',
    Specification: '作業明細',
    Bill: '請求書',
    Delivery: '納品書'
  }
});
