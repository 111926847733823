import * as yup from 'yup';

export const hoursField = yup.mixed().when('unitLabel', {
  is: 'Hour',
  then: yup.number().required(),
  otherwise: yup.string()
});

export const minutesField = yup.mixed().when('unitLabel', {
  is: 'Hour',
  then: yup.mixed().oneOf([0, 15, 30, 45]),
  otherwise: yup.string()
});
