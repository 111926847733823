import gql from 'graphql-tag';

import { UserFieldsFragment } from './fragments';

export const LOGIN = gql`
  mutation Login($loginId: String!, $password: String!) {
    login(loginId: $loginId, password: $password) {
      token
    }
  }
`;

export const MYSELF = gql`
  query Myself {
    myself {
      ...UserFields
    }
  }
  ${UserFieldsFragment}
`;

export const FETCH_USER = gql`
  query FetchUser($where: UserWhereUniqueInput!) {
    fetchUser(where: $where) {
      ...UserFields
    }
  }
  ${UserFieldsFragment}
`;

export const SIGN_UP = gql`
  mutation SignUp($data: UserCreateInput!) {
    signUp(data: $data) {
      token
    }
  }
`;

export const ADD_USER = gql`
  mutation AddUser($data: UserCreateInput!) {
    addUser(data: $data) {
      id
    }
  }
`;

export const EDIT_USER = gql`
  mutation EditUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    editUser(data: $data, where: $where) {
      id
    }
  }
`;

export const EDIT_PASSWORD = gql`
  mutation EditPassword($password: String!, $newPassword: String!) {
    editPassword(password: $password, newPassword: $newPassword) {
      token
    }
  }
`;

export const RESET_PASSWORD_STEP1 = gql`
  mutation ResetPasswordStep1($email: String!) {
    resetPasswordStep1(email: $email)
  }
`;

export const RESET_PASSWORD_STEP2 = gql`
  mutation ResetPasswordStep2($resetKey: String!, $newPassword: String!) {
    resetPasswordStep2(resetKey: $resetKey, newPassword: $newPassword)
  }
`;

export const GET_USERS = gql`
  query GetUsers($where: UserWhereInput, $orderBy: UserOrderByInput) {
    getUsers(where: $where, orderBy: $orderBy) {
      ...UserFields
    }
  }
  ${UserFieldsFragment}
`;

export const SEARCH_USERS = gql`
  query SearchUsers($where: UserWhereInput, $skip: Int, $first: Int, $orderBy: UserOrderByInput) {
    searchUsers(where: $where, skip: $skip, first: $first, orderBy: $orderBy) {
      count
      users {
        ...UserFields
      }
    }
  }
  ${UserFieldsFragment}
`;
