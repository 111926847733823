import Encoding from 'encoding-japanese';

export const redirect = url => {
  if (window) {
    window.location.href = url;
  }
};

export const newWindow = url => {
  if (window) {
    window.open(url);
  }
};

export const reload = () => {
  if (window) {
    window.location.reload(true);
  }
};

export const confirm = message => {
  if (window) {
    if (process.env.NODE_ENV === 'production') {
      return window.confirm(message);
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const storeToken = token => {
  if (localStorage) {
    localStorage.setItem('id_token', token);
  }
};

export const fetchToken = () => {
  if (localStorage) {
    return localStorage.getItem('id_token');
  }
};

export const flushToken = () => {
  if (localStorage) {
    localStorage.removeItem('id_token');
  }
};

export const downloadCsv = (filename, content, sjis = true) => {
  if (document) {
    let blob;
    if (sjis) {
      const strArray = Encoding.stringToCode(content);
      const sjisArray = Encoding.convert(strArray, 'SJIS', 'UNICODE');
      const uint8Array = new Uint8Array(sjisArray);
      blob = new Blob([uint8Array], { type: 'text/csv; charset=Shift_JIS;' });
    } else {
      blob = new Blob([content], { type: 'text/csv; charset=utf-8;' });
    }

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const logout = () => {
  flushToken();
  redirect('/');
};
