import React from 'react';
import classNames from 'classnames';
import { withFormik } from 'formik';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import enums from 'enums';
import { getCurrentDateTime } from 'utils/date';
import { notifySuccess } from 'notifications';

import MyTextField from 'elements/MyTextField';
import Message from 'elements/Message';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
  },
  filtering: {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing.unit
  },
  filteringButton: {
    minWidth: 0
  },
  list: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.unit,
    overflowY: 'auto'
  },
  form: {
    flex: '0 0 auto',
    padding: theme.spacing.unit,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#fff'
  },
  formControl: {
    '&:not(:first-child)': {
      marginTop: theme.spacing.unit
    }
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center'
  }
});

function FilteringButton(props) {
  const { className, onClick, selected, children } = props;
  return (
    <Button
      className={className}
      size="small"
      color={selected ? 'primary' : 'default'}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

class MessagesColumn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteringKey: 'ALL'
    };
  }

  createHandleFilter = filteringKey => () => {
    this.setState({ filteringKey });
  };

  createHandleRemove = message => () => {
    const { removeMessage, onRemove } = this.props;

    const variables = {
      where: {
        id: message.id
      }
    };

    removeMessage({
      variables
    })
      .then(({ data }) => {
        onRemove(data);
        notifySuccess('メッセージが削除されました。');
      })
      .catch(error => {})
      .finally(() => {});
  };

  render() {
    const {
      classes,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      messages,
      useSection
    } = this.props;

    const filteredMessages = messages.filter(message => {
      if (this.state.filteringKey === 'ALL') {
        return true;
      } else {
        return this.state.filteringKey === message.section;
      }
    });

    return (
      <div className={classes.root}>
        {useSection && (
          <div className={classes.filtering}>
            <FilteringButton
              className={classes.filteringButton}
              onClick={this.createHandleFilter('ALL')}
              selected={this.state.filteringKey === 'ALL'}
            >
              ALL
            </FilteringButton>
            <FilteringButton
              className={classes.filteringButton}
              onClick={this.createHandleFilter('Eizo')}
              selected={this.state.filteringKey === 'Eizo'}
            >
              映像
            </FilteringButton>
            <FilteringButton
              className={classes.filteringButton}
              onClick={this.createHandleFilter('Onkyo')}
              selected={this.state.filteringKey === 'Onkyo'}
            >
              音響
            </FilteringButton>
            <FilteringButton
              className={classes.filteringButton}
              onClick={this.createHandleFilter('Gaiga')}
              selected={this.state.filteringKey === 'Gaiga'}
            >
              外画
            </FilteringButton>
            <FilteringButton
              className={classes.filteringButton}
              onClick={this.createHandleFilter('Eigyo')}
              selected={this.state.filteringKey === 'Eigyo'}
            >
              営業
            </FilteringButton>
            <FilteringButton
              className={classes.filteringButton}
              onClick={this.createHandleFilter('Other')}
              selected={this.state.filteringKey === 'Other'}
            >
              請求
            </FilteringButton>
          </div>
        )}
        <div className={classes.list}>
          {filteredMessages.map(message => (
            <Message
              key={message.id}
              message={message}
              onDelete={this.createHandleRemove(message)}
            />
          ))}
        </div>
        <form className={classes.form} onSubmit={handleSubmit}>
          {useSection && (
            <MyTextField
              className={classes.formControl}
              name="section"
              values={values}
              touched={touched}
              errors={errors}
              label="部署"
              margin="none"
              smallFont
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
              select
            >
              <MenuItem key="" value="" />
              {Object.entries(enums.REPORT_SECTION).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </MyTextField>
          )}
          <MyTextField
            className={classes.formControl}
            name="content"
            values={values}
            touched={touched}
            errors={errors}
            label="メッセージ"
            margin="none"
            smallFont
            multiline
            rowsMax="20"
            onChange={handleChange}
            onBlur={handleBlur}
            required
            fullWidth
          />
          <div className={classNames(classes.formControl, classes.buttonWrapper)}>
            <Button type="submit" size="small" variant="contained" color="primary">
              送信
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withFormik({
    mapPropsToValues: props => props.initialValues,
    validationSchema: props => props.schema,
    handleSubmit: (values, { props, setSubmitting }) => {
      const { user, program, addMessage, onAdd } = props;

      const variables = {
        data: {
          program: { connect: { id: program.id } },
          user: { connect: { id: user.id } },
          registerDateTime: getCurrentDateTime(),
          ...values
        }
      };

      addMessage({
        variables
      })
        .then(({ data }) => {
          onAdd(data);
          notifySuccess('メッセージが追加されました。');
        })
        .catch(error => {})
        .finally(() => {
          setSubmitting(false);
        });
    },
    displayName: 'MessageForm'
  })
)(MessagesColumn);
