import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import wrapTablePagination from 'hocs/wrapTablePagination';
import { newWindow } from 'browser';

import SortableTableHead from 'elements/SortableTableHead';

const columns = [
  {
    id: 'programNo',
    align: 'center',
    disablePadding: true,
    label: 'No.',
    sortable: false,
    width: 48
  },
  { id: 'date', align: 'center', disablePadding: true, label: '日付', sortable: true, width: 80 },
  {
    id: 'title',
    align: 'left',
    disablePadding: true,
    label: 'PROGRAM',
    sortable: false,
    width: 200
  },
  {
    id: 'subTitle',
    align: 'left',
    disablePadding: true,
    label: 'サブタイトル',
    sortable: false,
    width: 200
  },
  {
    id: 'section',
    align: 'center',
    disablePadding: true,
    label: '部署',
    sortable: false,
    width: 80
  },
  {
    id: 'operation',
    align: 'center',
    disablePadding: true,
    label: '作業内容',
    sortable: false,
    width: 120
  },
  {
    id: 'user',
    align: 'center',
    disablePadding: true,
    label: '作業者',
    sortable: false,
    width: 80
  },
  {
    id: 'charge',
    align: 'center',
    disablePadding: true,
    label: '担当',
    sortable: false,
    width: 80
  },
  { id: 'rating', align: 'center', disablePadding: true, label: '評価', sortable: true, width: 68 },
  {
    id: 'timeSpan',
    align: 'center',
    disablePadding: true,
    label: '合計',
    sortable: false,
    width: 80
  },
  {
    id: 'amount',
    align: 'center',
    disablePadding: true,
    label: '実働',
    sortable: false,
    width: 80
  },
  {
    id: 'unit',
    align: 'center',
    disablePadding: true,
    label: '単位',
    sortable: false,
    width: 80
  },
  {
    id: 'systemCheck',
    align: 'center',
    disablePadding: true,
    label: 'システム',
    sortable: false,
    width: 80
  },
  {
    id: 'customer',
    align: 'left',
    disablePadding: true,
    label: 'クライアント',
    sortable: false,
    width: 120
  },
  {
    id: 'personnel',
    align: 'left',
    disablePadding: true,
    label: '担当者',
    sortable: false,
    width: 80
  },
  {
    id: 'detail',
    align: 'left',
    disablePadding: true,
    label: '詳細',
    sortable: false,
    width: 200
  }
];

const styles = theme => ({
  tableBodyCell: {
    paddingRight: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit / 2
  }
});

class HeadBody extends React.Component {
  createHandleClick = operation => () => {
    newWindow(`/reports/${operation.reportId}`);
  };

  render() {
    const { classes, order, orderBy, operations, handleRequestSort } = this.props;

    return (
      <React.Fragment>
        <SortableTableHead
          order={order}
          orderBy={orderBy}
          columns={columns}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {operations.map(operation => (
            <TableRow
              hover
              tabIndex={-1}
              key={operation.id}
              onClick={this.createHandleClick(operation)}
            >
              <TableCell className={classes.tableBodyCell} align="left" component="th" scope="row">
                {operation.programNo}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {operation.date}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {operation.title}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {operation.subTitle}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {operation.section}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {operation.operationName}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {operation.userName}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {operation.charge}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {operation.rating}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {operation.timeSpan}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {operation.amount}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {operation.unitLabel}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {operation.systemCheck}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {operation.customerName}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {operation.personnelName}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {operation.detail}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </React.Fragment>
    );
  }
}
const EnhancedHeadBody = withStyles(styles)(HeadBody);

export default wrapTablePagination({ fixed: true })(EnhancedHeadBody);
