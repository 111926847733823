import { convertIntoDateFormat } from 'utils/date';

export const userNewInitialValues = {
  name: '',
  nameKana: '',
  loginId: '',
  email: '',
  password: '',
  passwordConfirm: '',
  signName: '',
  employeeId: '',
  joinedDate: convertIntoDateFormat(),
  sectionsRelatedIds: [],
  userLevel: 'User'
};

export const userEditInitialValues = {
  name: '',
  nameKana: '',
  loginId: '',
  email: '',
  signName: '',
  sectionsRelatedIds: [],
  employeeId: '',
  joinedDate: convertIntoDateFormat(),
  userLevel: 'User',
  isRetired: false
};

export const customerInitialValues = {
  name: '',
  nameKana: '',
  customerType: 'Client',
  postalCode: '',
  prefecture: '',
  address: '',
  email: '',
  tel: '',
  paymentClosingDay: 31,
  paymentType: 'MonthLater',
  paymentMonthLater: 1,
  paymentDay: 31,
  maPrice: 0,
  eedPrice: 0,
  labelsRelatedIds: [],
  memo: '',
  registerDate: convertIntoDateFormat(),
  isFreezed: false
};

export const personnelInitialValues = {
  name: '',
  nameKana: '',
  department: '',
  postalCode: '',
  prefecture: '',
  address: '',
  email: '',
  tel: '',
  memo: '',
  registerDate: convertIntoDateFormat(),
  isRetired: false
};

export const reportNewInitialValues = Object.freeze({
  customerRelatedId: '',
  personnelRelatedId: '',
  userRelatedId: '',
  title: '',
  subTitle: '',
  memo: '',
  registerDate: convertIntoDateFormat()
});

export const reportEditInitialValues = Object.freeze({
  ...reportNewInitialValues,
  reportOperations: [],
  reportExpenses: [],
  reportMedias: []
});

export const reportOperationInitialValues = Object.freeze({
  userRelatedId: '',
  operationRelatedId: '',
  date: convertIntoDateFormat(),
  section: '',
  startHours: 10,
  startMinutes: 0,
  endHours: 10,
  endMinutes: 0,
  timeSpan: 0,
  amount: 0,
  unitLabel: 'Hour',
  charge: '',
  rating: '',
  detail: '',
  systemCheck: ''
});

export const reportMediaInitialValues = Object.freeze({
  userRelatedId: '',
  mediaRelatedId: '',
  date: convertIntoDateFormat(),
  amount: 1,
  mediaType: '',
  base: '',
  detail: '',
  systemCheck: '',
  check: false
});

export const reportExpenseInitialValues = Object.freeze({
  userRelatedId: '',
  expenseRelatedId: '',
  date: convertIntoDateFormat(),
  amount: 1,
  unitPrice: 0,
  price: 0,
  detail: '',
  systemCheck: '',
  check: false
});

export const voucherNewInitialValues = Object.freeze({
  customerRelatedId: '',
  personnelRelatedId: '',
  userRelatedId: '',
  voucherStatus: 'Provisional',
  title: '',
  subTitle: '',
  memo: '',
  billDate: '',
  voucherOperations: [],
  voucherExpenses: [],
  voucherMedias: []
});

export const voucherEditInitialValues = Object.freeze({
  ...voucherNewInitialValues
});

export const voucherOperationInitialValues = Object.freeze({
  date: convertIntoDateFormat(),
  operationRelatedId: '',
  startHours: 10,
  startMinutes: 0,
  endHours: 10,
  endMinutes: 0,
  timeSpan: 0,
  amount: 0,
  unitLabel: 'Hour',
  unitPrice: 0,
  price: 0,
  detail: ''
});

export const voucherMediaInitialValues = Object.freeze({
  date: convertIntoDateFormat(),
  mediaRelatedId: '',
  amount: 1,
  unitPrice: 0,
  detail: ''
});

export const voucherExpenseInitialValues = Object.freeze({
  date: convertIntoDateFormat(),
  expenseRelatedId: '',
  amount: 1,
  unitPrice: 0,
  detail: ''
});

export const mailTemplateInitialValues = Object.freeze({
  name: '',
  subject: '',
  body: '',
  pdfType: '',
  title: '',
  greeting: '',
  payment: '',
  order: ''
});

export const sendMailInitialValues = Object.freeze({
  mailTemplate: '',
  personnel: '',
  from: '',
  tos: '',
  ccs: '',
  bccs: '',
  subject: '',
  mainWhere: '',
  allWhere: [],
  pdfType: '',
  date: '',
  title: '',
  greeting: '',
  payment: '',
  companyStamp: true
});
