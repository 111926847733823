import gql from 'graphql-tag';

import { SectionFieldsFragment } from './fragments';

export const SECTION_LIST = gql`
  query Sections {
    sections(orderBy: order_ASC) {
      ...SectionFields
    }
  }
  ${SectionFieldsFragment}
`;

export const ADD_SECTION = gql`
  mutation AddSection($data: SectionCreateInput!) {
    addSection(data: $data) {
      ...SectionFields
    }
  }
  ${SectionFieldsFragment}
`;

export const EDIT_SECTION = gql`
  mutation EditSection($data: SectionUpdateInput!, $where: SectionWhereUniqueInput!) {
    editSection(data: $data, where: $where) {
      ...SectionFields
    }
  }
  ${SectionFieldsFragment}
`;

export const REMOVE_SECTION = gql`
  mutation RemoveSection($where: SectionWhereUniqueInput!) {
    removeSection(where: $where) {
      ...SectionFields
    }
  }
  ${SectionFieldsFragment}
`;

export const ARRANGE_SECTIONS = gql`
  mutation ArrangeSections($list: [ArrangeInput!]!) {
    arrangeSections(list: $list) {
      ...SectionFields
    }
  }
  ${SectionFieldsFragment}
`;
