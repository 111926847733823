import React from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { compose, graphql } from 'react-apollo';
import { keys, take } from 'lodash';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import enums from 'enums';
import { MEDIA_LIST } from 'gqls/media';
import withChangeSubmit from 'hocs/withChangeSubmit';
import withPeriodFields from 'hocs/withPeriodFields';
import { takeSpecificKeys } from 'utils/util';
import { takeSearchParams } from 'utils/search';
import { convertIntoSelectFieldValues } from 'utils/form';

import MyTextField from 'elements/MyTextField';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit
  },
  form: {
    marginTop: theme.spacing.unit
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100%'
  }
});

class SearchReportMediaForm extends React.Component {
  render() {
    const {
      classes,
      data,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      onCopyClick
    } = this.props;
    const { handleChangeSF, handleBlurSF, handleKeyPress } = this.props;
    const { periods, yearMonths, handlePeriodChange } = this.props;

    if (data.loading) return null;

    const mediaOptions = convertIntoSelectFieldValues(data.medias);

    return (
      <Paper className={classes.paper}>
        <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={8}>
            <Grid item xs={2}>
              <MyTextField
                name="period"
                values={values}
                touched={touched}
                errors={errors}
                label="決算期"
                margin="none"
                onChange={handlePeriodChange}
                onBlur={handleBlur}
                fullWidth
                select
              >
                <MenuItem key="" value="" />
                {take(periods, 4).map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>
            <Grid item xs={2}>
              <MyTextField
                name="yearMonth"
                values={values}
                touched={touched}
                errors={errors}
                label="年月"
                margin="none"
                onChange={handleChangeSF}
                onBlur={handleBlur}
                fullWidth
                select
              >
                <MenuItem key="" value="" />
                {yearMonths.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                name="title"
                values={values}
                touched={touched}
                errors={errors}
                label="PROGRAM"
                margin="none"
                onChange={handleChange}
                onBlur={handleBlurSF}
                onKeyPress={handleKeyPress}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <MyTextField
                name="programNo"
                values={values}
                touched={touched}
                errors={errors}
                label="No."
                margin="none"
                onChange={handleChange}
                onBlur={handleBlurSF}
                onKeyPress={handleKeyPress}
                fullWidth
              />
            </Grid>
            <Grid item xs={3} />

            <Grid item xs={3}>
              <MyTextField
                name="mediaRelatedId"
                values={values}
                touched={touched}
                errors={errors}
                label="メディア"
                margin="none"
                onChange={handleChangeSF}
                onBlur={handleBlur}
                fullWidth
                select
              >
                <MenuItem key="" value="" />
                {mediaOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>

            <Grid item xs={2}>
              <MyTextField
                name="mediaType"
                values={values}
                touched={touched}
                errors={errors}
                label="種類"
                margin="none"
                onChange={handleChangeSF}
                onBlur={handleBlur}
                fullWidth
                select
              >
                <MenuItem key="" value="" />
                {Object.entries(enums.REPORT_MEDIA_TYPE).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>

            <Grid item xs={2}>
              <MyTextField
                name="base"
                values={values}
                touched={touched}
                errors={errors}
                label="場所"
                margin="none"
                onChange={handleChangeSF}
                onBlur={handleBlur}
                fullWidth
                select
              >
                <MenuItem key="" value="" />
                {Object.entries(enums.REPORT_MEDIA_BASE).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>

            <Grid item xs={3} />

            <Grid item xs={2}>
              <div className={classes.buttonWrapper}>
                <Button size="small" variant="text" onClick={onCopyClick}>
                  CSVでダウンロード
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
}

const initialValues = {
  period: '',
  yearMonth: '',
  mediaRelatedId: '',
  title: '',
  programNo: '',
  mediaType: '',
  base: ''
};

const schema = yup.object().shape({
  period: yup.string(),
  yearMonth: yup.string(),
  mediaRelatedId: yup.string(),
  title: yup.string(),
  programNo: yup.string(),
  mediaType: yup.string(),
  base: yup.string()
});

export default compose(
  withStyles(styles),
  graphql(MEDIA_LIST),
  withRouter,
  withFormik({
    mapPropsToValues: props => {
      const { location } = props;
      const params = takeSearchParams(location);
      return Object.assign({}, initialValues, takeSpecificKeys(params, keys(initialValues)));
    },
    validationSchema: schema,
    handleSubmit: (values, { props, setSubmitting }) => {
      const { updateSearchParams } = props;
      setSubmitting(false);
      updateSearchParams(values);
    }
  }),
  withChangeSubmit(),
  withPeriodFields({ willSubmit: true })
)(SearchReportMediaForm);
