import React from 'react';
import { compose } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import { SEARCH_VOUCHER_OPERATIONS } from 'gqls/program';
import { getPeriodRange, getYearMonthRange } from 'utils/period';
import { convertIntoDateFormat } from 'utils/date';
import { convertOperationTimeFieldsIntoForm } from 'formConvertors/program';
import enums from 'enums';
import withUbs from 'hocs/withUbs';
import wrapMenu from 'hocs/wrapMenu';
import { downloadCsv } from 'browser';

import SearchVoucherOperationForm from 'forms/SearchVoucherOperationForm';
import VoucherOperationTable from 'elements/VoucherOperationTable';

const styles = theme => ({
  main: {
    flex: '1 1 auto',
    minWidth: 0,
    paddingTop: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  }
});

const defaultSearchParams = {
  order: 'desc',
  orderBy: 'date',
  page: '0',
  rowsPerPage: '50'
};

class VoucherOperationList extends React.Component {
  formatOperationFields = ([...operations]) => {
    operations = operations.map(operation => convertOperationTimeFieldsIntoForm(operation));

    return operations.map(operation => ({
      id: operation.id,
      voucherId: operation.voucher.id,
      programNo: operation.voucher.program.programNo,
      date: convertIntoDateFormat(operation.date),
      customerName: operation.voucher.customer ? operation.voucher.customer.name : '',
      title: operation.voucher.title,
      operationName: operation.operation ? operation.operation.name : '',
      timeSpan: operation.timeSpan ? operation.timeSpan : '-',
      amount: operation.amount,
      unitLabel: operation.unitLabel ? enums.UNIT_LABEL[operation.unitLabel] : '',
      unitPrice: operation.unitPrice,
      price: operation.unitPrice * operation.amount,
      detail: operation.detail
    }));
  };

  createHandleCopyClick = operations => () => {
    const head =
      '"No.","日付","クライアント","PROGRAM","作業内容","合計","実働","単位","単価","金額","詳細"';
    const csvContent =
      head +
      '\n' +
      operations
        .map(({ ...operation }) => {
          delete operation.id;
          delete operation.voucherId;
          return Object.values(operation)
            .map(v => {
              if (v) {
                return typeof v === 'string' ? `"${v.replace('"', '""')}"` : `"${v}"`;
              } else {
                return '""';
              }
            })
            .join(',');
        })
        .join('\n');

    downloadCsv('オペレーションリスト.csv', csvContent);
  };

  render() {
    const { classes } = this.props;
    const { list, count, order, orderBy, rowsPerPage, page, updateSearchParams } = this.props;

    const operations = this.formatOperationFields(list);

    return (
      <main className={classes.main}>
        <SearchVoucherOperationForm
          statusEnum={enums.VOUCHER_STATUS}
          updateSearchParams={updateSearchParams}
          operations={operations}
          onCopyClick={this.createHandleCopyClick(operations)}
        />
        <VoucherOperationTable
          order={order}
          orderBy={orderBy}
          rowsPerPage={rowsPerPage}
          page={page}
          count={count}
          operations={operations}
          updateSearchParams={updateSearchParams}
        />
      </main>
    );
  }
}

const convert = ({ params }) => {
  const searchParams = {};
  searchParams['AND'] = [];

  Object.entries(params).forEach(([key, value]) => {
    switch (key) {
      case 'period':
        const periodRange = getPeriodRange(+value);
        searchParams['AND'].push({ date_gte: periodRange.begin });
        searchParams['AND'].push({ date_lte: periodRange.end });
        break;
      case 'yearMonth':
        const yearMonthRange = getYearMonthRange(value);
        searchParams['AND'].push({ date_gte: yearMonthRange.begin });
        searchParams['AND'].push({ date_lte: yearMonthRange.end });
        break;
      case 'status':
        searchParams['AND'].push({ voucher: { voucherStatus: value } });
        break;
      case 'title':
        const titleParams = [];
        titleParams.push({ title_contains: value });
        titleParams.push({ subTitle_contains: value });
        searchParams['AND'].push({ voucher: { OR: titleParams } });
        break;
      case 'programNo':
        searchParams['AND'].push({ voucher: { program: { programNo: +value } } });
        break;
      case 'customer':
        searchParams['AND'].push({
          voucher: { customer: { OR: [{ name_contains: value }, { nameKana_contains: value }] } }
        });
        break;
      case 'operationRelatedId':
        searchParams['AND'].push({ operation: { id: value } });
        break;
      default:
        throw new Error();
    }
  });

  if (!params['status']) {
    searchParams['AND'].push({
      voucher: { voucherStatus_not_in: ['Provisional', 'Integrated', 'Archived'] }
    });
  }

  return searchParams;
};

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: 'オペレーションリスト', colorOrange: true }),
  withUbs({
    defaultSearchParams,
    gql: SEARCH_VOUCHER_OPERATIONS,
    convertGraphqlParams: convert,
    takeResult: ({ data }) => {
      const {
        searchVoucherOperations: { voucherOperations, count }
      } = data;
      return {
        list: voucherOperations,
        count: count
      };
    }
  })
)(VoucherOperationList);
