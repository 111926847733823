import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { compose, graphql } from 'react-apollo';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import constants from 'myConstants';
import { LOGIN } from 'gqls/user';
import { storeToken, redirect } from 'browser';
import catImage from 'cat.jpg';

import MyTextField from 'elements/MyTextField';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    marginTop: theme.spacing.unit
  },
  textLink: {
    textAlign: 'right'
  },
  submit: {
    marginTop: theme.spacing.unit
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing.unit * 2
  }
});

const LoginForm = props => {
  const { classes, values, errors, touched, handleChange, handleBlur, handleSubmit } = props;
  return (
    <Paper className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <MyTextField
          name="loginId"
          values={values}
          touched={touched}
          errors={errors}
          label="LoginID or Email Address"
          margin="normal"
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="loginId"
          required
          fullWidth
        />
        <MyTextField
          name="password"
          type="password"
          values={values}
          touched={touched}
          errors={errors}
          label="Password"
          margin="normal"
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="current-password"
          required
          fullWidth
        />
        <div className={classes.textLink}>
          <Button
            color="secondary"
            size="small"
            variant="text"
            tabIndex="-1"
            onClick={() => {
              redirect('/resetPasswordStep1');
            }}
          >
            パスワードを再発行
          </Button>
        </div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          tabIndex="3"
        >
          Sign in
        </Button>
        <div className={classes.imageWrapper}>
          <img alt="cat" src={catImage} />
        </div>
      </form>
    </Paper>
  );
};

const initialValues = { loginId: '', password: '' };

const schema = yup.object().shape({
  loginId: yup.string().required(constants.MESSAGE.REQUIRED),
  password: yup.string().required(constants.MESSAGE.REQUIRED)
});

export default compose(
  withStyles(styles),
  graphql(LOGIN),
  withFormik({
    mapPropsToValues: () => initialValues,
    validationSchema: schema,
    handleSubmit: (values, { props, setSubmitting, setFieldError }) => {
      const { mutate } = props;
      mutate({ variables: values })
        .then(({ data }) => {
          const {
            login: { token }
          } = data;
          storeToken(token);
          redirect('/dashboard');
        })
        .catch(error => {
          if (error.message === 'GraphQL error: No such user found') {
            setFieldError('loginId', constants.MESSAGE.INVALID_LOGIN_ID);
          } else if (error.message === 'GraphQL error: Invalid password') {
            setFieldError('password', constants.MESSAGE.INVALID_PASSWORD);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    displayName: 'LoginForm'
  })
)(LoginForm);
