import React from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { compose, graphql } from 'react-apollo';
import { keys, take, sumBy } from 'lodash';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { GET_SEARCH_VOUCHER_OPERATIONS_FORM_OPTIONS } from 'gqls/program';
import withChangeSubmit from 'hocs/withChangeSubmit';
import withPeriodFields from 'hocs/withPeriodFields';
import withReactSelectHandler from 'hocs/withReactSelectHandler';
import { takeSpecificKeys, numberWithCommas } from 'utils/util';
import { takeSearchParams } from 'utils/search';
import { convertIntoSelectFieldValues } from 'utils/form';

import MyTextField from 'elements/MyTextField';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit
  },
  form: {
    marginTop: theme.spacing.unit
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100%'
  }
});

class SearchVoucherOperationForm extends React.Component {
  render() {
    const {
      statusEnum,
      operations,
      classes,
      data,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      onCopyClick
    } = this.props;
    const { handleChangeSF, handleBlurSF, handleKeyPress } = this.props;
    const { periods, yearMonths, handlePeriodChange } = this.props;

    if (data.loading) return null;

    const operationOptions = [
      ...convertIntoSelectFieldValues(data.eizoOperations),
      ...convertIntoSelectFieldValues(data.onkyoOperations),
      ...convertIntoSelectFieldValues(data.gaigaOperations),
      ...convertIntoSelectFieldValues(data.eigyoOperations),
      ...convertIntoSelectFieldValues(data.otherOperations)
    ];

    const totalPrice = sumBy(operations, operation => operation.price);

    return (
      <Paper className={classes.paper}>
        <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={8}>
            <Grid item xs={2}>
              <MyTextField
                name="period"
                values={values}
                touched={touched}
                errors={errors}
                label="決算期"
                margin="none"
                onChange={handlePeriodChange}
                onBlur={handleBlur}
                fullWidth
                select
              >
                <MenuItem key="" value="" />
                {take(periods, 4).map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>
            <Grid item xs={2}>
              <MyTextField
                name="yearMonth"
                values={values}
                touched={touched}
                errors={errors}
                label="年月"
                margin="none"
                onChange={handleChangeSF}
                onBlur={handleBlur}
                fullWidth
                select
              >
                <MenuItem key="" value="" />
                {yearMonths.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>
            <Grid item xs={2}>
              <MyTextField
                name="status"
                values={values}
                touched={touched}
                errors={errors}
                label="ステータス"
                margin="none"
                onChange={handleChangeSF}
                onBlur={handleBlur}
                fullWidth
                select
              >
                <MenuItem key="" value="" />
                {Object.entries(statusEnum).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                name="title"
                values={values}
                touched={touched}
                errors={errors}
                label="PROGRAM"
                margin="none"
                onChange={handleChange}
                onBlur={handleBlurSF}
                onKeyPress={handleKeyPress}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <MyTextField
                name="programNo"
                values={values}
                touched={touched}
                errors={errors}
                label="No."
                margin="none"
                onChange={handleChange}
                onBlur={handleBlurSF}
                onKeyPress={handleKeyPress}
                fullWidth
              />
            </Grid>
            <Grid item xs={1} />

            <Grid item xs={4}>
              <MyTextField
                name="customer"
                values={values}
                touched={touched}
                errors={errors}
                label="顧客"
                margin="none"
                onChange={handleChange}
                onBlur={handleBlurSF}
                onKeyPress={handleKeyPress}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <MyTextField
                name="operationRelatedId"
                values={values}
                touched={touched}
                errors={errors}
                label="作業"
                margin="none"
                onChange={handleChangeSF}
                onBlur={handleBlur}
                fullWidth
                select
              >
                <MenuItem key="" value="" />
                {operationOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={2}>
              <div className={classes.buttonWrapper}>
                <Button size="small" variant="text" onClick={onCopyClick}>
                  CSVでダウンロード
                </Button>
              </div>
            </Grid>
            {operations.length > 0 && (
              <Grid item xs={12}>
                <Typography component="h3" variant="subtitle1">
                  合計金額: {numberWithCommas(totalPrice)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </form>
      </Paper>
    );
  }
}

const initialValues = {
  period: '',
  yearMonth: '',
  status: '',
  title: '',
  programNo: '',
  customer: '',
  operationRelatedId: ''
};

const schema = yup.object().shape({
  period: yup.string(),
  yearMonth: yup.string(),
  status: yup.string(),
  title: yup.string(),
  programNo: yup.number(),
  customer: yup.string(),
  operationRelatedId: yup.string()
});

export default compose(
  withStyles(styles),
  graphql(GET_SEARCH_VOUCHER_OPERATIONS_FORM_OPTIONS),
  withRouter,
  withFormik({
    mapPropsToValues: props => {
      const { location } = props;
      const params = takeSearchParams(location);
      return Object.assign({}, initialValues, takeSpecificKeys(params, keys(initialValues)));
    },
    validationSchema: schema,
    handleSubmit: (values, { props, setSubmitting }) => {
      const { updateSearchParams } = props;
      setSubmitting(false);
      updateSearchParams(values);
    }
  }),
  withChangeSubmit(),
  withReactSelectHandler(),
  withPeriodFields({ willSubmit: true })
)(SearchVoucherOperationForm);
