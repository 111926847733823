import React from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { compose } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import constants from 'myConstants';
import { takeSpecificKeys } from 'utils/util';
import { notifyError } from 'notifications';

import MyTextField from 'elements/MyTextField';

const styles = theme => ({
  root: {
    position: 'relative'
  },
  form: {
    display: 'flex',
    padding: theme.spacing.unit
  },
  textField: {
    '&:not(:first-child)': {
      marginLeft: theme.spacing.unit * 2
    }
  },
  button: {
    position: 'absolute',
    bottom: 0,
    right: 0
  }
});

class SectionForm extends React.Component {
  createHandleBlur = () => event => {
    const { handleBlur, submitForm } = this.props;
    handleBlur(event);
    submitForm();
  };

  handleDelete = () => {
    const { values, setList, handleRemoveName, handleRemove } = this.props;

    handleRemove({
      variables: {
        where: { id: values.id }
      }
    }).then(({ data }) => {
      const { [handleRemoveName]: newList } = data;
      setList(newList);
    });
  };

  render() {
    const { classes, values, errors, touched, handleChange, handleSubmit } = this.props;
    return (
      <Paper className={classes.root} square>
        <form className={classes.form} onSubmit={handleSubmit}>
          <MyTextField
            name="name"
            values={values}
            touched={touched}
            errors={errors}
            label="部署名"
            margin="none"
            onChange={handleChange}
            onBlur={this.createHandleBlur()}
            className={classes.textField}
            InputLabelProps={{
              shrink: true
            }}
            required
          />
        </form>
        <IconButton
          className={classes.button}
          aria-label="Delete"
          color="secondary"
          onClick={this.handleDelete}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Paper>
    );
  }
}

const schema = yup.object().shape({
  name: yup.string().required('Required')
});

export default compose(
  withStyles(styles),
  withFormik({
    displayName: 'SectionForm',
    mapPropsToValues: props => props.convertIntoForm(props.values),
    validationSchema: schema,
    enableReinitialize: true,
    handleSubmit: (values, { props, setSubmitting }) => {
      const { handleEditName, handleEdit } = props;

      handleEdit({
        variables: {
          data: takeSpecificKeys(props.convertIntoGql(values), ['name', 'lockVersion']),
          where: { id: values.id }
        }
      })
        .then(({ data }) => {
          const { [handleEditName]: values } = data;
          props.setList(values);
        })
        .catch(error => {
          if (error.message === 'GraphQL error: Conflicts occured') {
            notifyError(constants.MESSAGE.LOCK_VERSION_VIOLATION);
          } else {
            notifyError(constants.MESSAGE.SERVER_ERROR);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  })
)(SectionForm);
