import gql from 'graphql-tag';

import { CustomerFieldsFragment, PersonnelFieldsFragment } from './fragments';

export const NEW_CUSTOMER = gql`
  mutation AddCustomer($data: CustomerCreateInput!) {
    addCustomer(data: $data) {
      id
    }
  }
`;

export const FETCH_CUSTOMER = gql`
  query FetchCustomer($where: CustomerWhereUniqueInput!) {
    fetchCustomer(where: $where) {
      ...CustomerFields
    }
  }
  ${CustomerFieldsFragment}
`;

export const EDIT_CUSTOMER = gql`
  mutation EditCustomer($data: CustomerUpdateInput!, $where: CustomerWhereUniqueInput!) {
    editCustomer(data: $data, where: $where) {
      id
    }
  }
`;

export const SEARCH_CUSTOMERS = gql`
  query CustomerList(
    $where: CustomerWhereInput
    $skip: Int
    $first: Int
    $orderBy: CustomerOrderByInput
  ) {
    searchCustomers(where: $where, skip: $skip, first: $first, orderBy: $orderBy) {
      count
      customers {
        ...CustomerFields
      }
    }
  }
  ${CustomerFieldsFragment}
`;

export const NEW_PERSONNEL = gql`
  mutation AddPersonnel($data: PersonnelCreateInput!) {
    addPersonnel(data: $data) {
      id
      customer {
        id
      }
    }
  }
`;

export const FETCH_PERSONNEL = gql`
  query FetchPersonnel(
    $customerWhere: CustomerWhereUniqueInput!
    $personnelWhere: PersonnelWhereUniqueInput!
  ) {
    fetchCustomer(where: $customerWhere) {
      ...CustomerFields
    }
    fetchPersonnel(where: $personnelWhere) {
      ...PersonnelFields
    }
  }
  ${CustomerFieldsFragment}
  ${PersonnelFieldsFragment}
`;

export const EDIT_PERSONNEL = gql`
  mutation EditPersonnel($data: PersonnelUpdateInput!, $where: PersonnelWhereUniqueInput!) {
    editPersonnel(data: $data, where: $where) {
      id
      customer {
        id
      }
    }
  }
`;

export const GET_PERSONNELS = gql`
  query GetPersonnels($where: PersonnelWhereInput, $orderBy: PersonnelOrderByInput) {
    getPersonnels(where: $where, orderBy: $orderBy) {
      ...PersonnelFields
    }
  }
  ${PersonnelFieldsFragment}
`;

export const SEARCH_PERSONNELS = gql`
  query SearchPersonnels(
    $where: PersonnelWhereInput
    $skip: Int
    $first: Int
    $orderBy: PersonnelOrderByInput
  ) {
    searchPersonnels(where: $where, skip: $skip, first: $first, orderBy: $orderBy) {
      count
      personnels {
        ...PersonnelFields
      }
    }
  }
  ${PersonnelFieldsFragment}
`;
