import React from 'react';
import { get, sortBy } from 'lodash';

export const withFormFieldRow = (model, options = {}) => Component => {
  const { willSubmitWhenRemove = () => false } = options;

  class C extends React.Component {
    handleRemove = () => {
      const { index, values, arrayHelpers, setFieldValue, submitForm } = this.props;

      if (willSubmitWhenRemove(this.props)) {
        if (model.startsWith('voucher')) {
          arrayHelpers.remove(index);
        } else {
          const fields = get(values, `${model}`);
          let newValues = [];
          for (let i = 0; i < fields.length; i++) {
            if (i < index) {
              newValues.push({ ...fields[i] });
            } else if (i > index) {
              newValues.push({ ...fields[i], sortOrder: fields[i].sortOrder - 1 });
            }
          }
          setFieldValue(`${model}`, newValues);
        }

        submitForm();
      }
    };

    handleUp = () => {
      const { index, values, setFieldValue } = this.props;

      if (index !== 0) {
        const fields = get(values, `${model}`);
        let newValues = [];
        for (let i = 0; i < fields.length; i++) {
          if (index === i) {
            newValues.push({ ...fields[i], sortOrder: index });
          } else if (index - 1 === i) {
            newValues.push({ ...fields[i], sortOrder: index + 1 });
          } else {
            newValues.push({ ...fields[i] });
          }
        }
        newValues = sortBy(newValues, ['sortOrder'], ['asc']);
        setFieldValue(`${model}`, newValues);
      }
    };

    handleDown = () => {
      const { index, values, setFieldValue } = this.props;
      const fields = get(values, `${model}`);

      let count = 0;
      for (let i = 0; i < fields.length; i++) {
        if (get(values, `${model}.${i}.id`) !== undefined) {
          count++;
        }
      }

      if (index !== count - 1) {
        let newValues = [];
        for (let i = 0; i < fields.length; i++) {
          if (index === i) {
            newValues.push({ ...fields[i], sortOrder: index + 2 });
          } else if (index + 1 === i) {
            newValues.push({ ...fields[i], sortOrder: index + 1 });
          } else {
            newValues.push({ ...fields[i] });
          }
        }
        newValues = sortBy(newValues, ['sortOrder'], ['asc']);
        setFieldValue(`${model}`, newValues);
      }
    };

    updatePrice = amount => {
      const { index, values, setFieldValue } = this.props;

      if (amount === undefined) {
        amount = get(values, `${model}.${index}.amount`);
      }
      const unitPrice = get(values, `${model}.${index}.unitPrice`);

      setFieldValue(`${model}.${index}.price`, Math.floor(+amount * +unitPrice));
    };

    handleAmountBlur = e => {
      const { handleBlur } = this.props;
      handleBlur(e);

      this.updatePrice();
    };

    handleUnitPriceBlur = e => {
      const { handleBlur } = this.props;
      handleBlur(e);

      this.updatePrice();
    };

    render() {
      return (
        <Component
          {...this.props}
          model={model}
          handleRemove={this.handleRemove}
          handleUp={this.handleUp}
          handleDown={this.handleDown}
          updatePrice={this.updatePrice}
          handleAmountBlur={this.handleAmountBlur}
          handleUnitPriceBlur={this.handleUnitPriceBlur}
        />
      );
    }
  }

  C.displayName = `withFormFieldRow(${Component.displayName || Component.name})`;

  return C;
};

export default withFormFieldRow;
