import { sumBy } from 'lodash';
import moment from 'moment';

// const moment = require('moment');
// moment(`1970-01-01`).diff(moment(`1970-01-01`)) => 0
// moment(`1970-04-01`).diff(moment(`1970-03-31`)) => 86400000
// moment(`1970-03-31`).diff(moment(`1970-04-01`)) => -86400000

const taxTable = [
  { begin: moment(`1970-01-01`), end: moment(`1989-03-31`), percent: 0 },
  { begin: moment(`1989-04-01`), end: moment(`1997-03-31`), percent: 0.03 },
  { begin: moment(`1997-04-01`), end: moment(`2014-03-31`), percent: 0.05 },
  { begin: moment(`2014-04-01`), end: moment(`2019-09-30`), percent: 0.08 },
  { begin: moment(`2019-10-01`), end: moment(`2050-12-31`), percent: 0.1 }
];

export const calcSubtotal = (voucherOperations, voucherExpenses, voucherMedias) => {
  const subtotal =
    sumBy(voucherOperations, 'price') +
    sumBy(voucherExpenses, 'price') +
    sumBy(voucherMedias, 'price');
  return subtotal;
};

export const taxPercent = date => {
  let m;
  if (date) {
    m = moment(`${date}`);
  } else {
    m = moment();
  }

  let taxPercent = 0;

  for (let i = 0; i < taxTable.length; i++) {
    const { begin, end, percent } = taxTable[i];
    if (m.diff(begin) >= 0 && m.diff(end) <= 0) {
      taxPercent = percent;
      break;
    }
  }

  return taxPercent;
};

export const totalPriceWithTax = (voucherOperations, voucherExpenses, voucherMedias, billDate) => {
  const subtotal = calcSubtotal(voucherOperations, voucherExpenses, voucherMedias);
  const percent = taxPercent(billDate);
  const tax = Math.floor(subtotal * percent);
  const total = subtotal + tax;
  return total;
};
