import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';
import * as yup from 'yup';

import { ProgressContext } from 'contexts';
import { NEW_REPORT } from 'gqls/program';
import { reportNewInitialValues } from 'initialValues';
import { redirect } from 'browser';
import wrapMenu from 'hocs/wrapMenu';

import ReportForm from 'forms/ReportForm';

const styles = theme => ({
  main: {
    width: 800,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

class NewReport extends React.Component {
  onSucceed = ({ data }) => {
    const {
      addReport: { id }
    } = data;
    redirect(`/reports/${id}`);
  };

  render() {
    const { classes, mutate } = this.props;
    return (
      <ProgressContext.Consumer>
        {({ updateProgressState }) => (
          <main className={classes.main}>
            <ReportForm
              action="NewReport"
              schema={schema}
              initialValues={reportNewInitialValues}
              mutate={mutate}
              onSucceed={this.onSucceed}
              updateProgressState={updateProgressState}
            />
          </main>
        )}
      </ProgressContext.Consumer>
    );
  }
}

const schema = yup.object().shape({
  customerRelatedId: yup.string().required(),
  personnelRelatedId: yup.string(),
  userRelatedId: yup.string().required(),
  title: yup.string().required(),
  subTitle: yup.string(),
  memo: yup.string()
});

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: 'ワークレポート' }),
  graphql(NEW_REPORT)
)(NewReport);
