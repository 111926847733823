import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { convertIntoDateTimeFormat } from 'utils/date';
import { confirm } from 'browser';
import enums from 'enums';

const styles = theme => ({
  root: {
    '&:not(:first-child)': {
      marginTop: theme.spacing.unit * 2
    }
  },
  message: {
    padding: `0 ${theme.spacing.unit / 2}px 0`,
    fontSize: '0.8rem',
    whiteSpace: 'pre-line'
  },
  actionsArea: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.unit,
    paddingLeft: theme.spacing.unit / 2
  },
  section: {},
  singName: {
    marginLeft: theme.spacing.unit / 2
  },
  registerDateTime: {
    flex: '1 0 auto',
    marginLeft: theme.spacing.unit / 2
  },
  button: {
    padding: theme.spacing.unit / 2
  }
});

class Message extends React.Component {
  handleDelete = () => {
    const { onDelete } = this.props;

    if (confirm('メッセージを削除してもよろしいですか？')) {
      onDelete();
    }
  };

  render() {
    const { classes, message } = this.props;

    return (
      <Paper className={classes.root} square>
        <Typography className={classes.message}>{message.content}</Typography>
        <div className={classes.actionsArea}>
          {message.section && (
            <Typography className={classes.section}>
              {enums.REPORT_SECTION[message.section]}
            </Typography>
          )}
          <Typography className={classes.singName}>{message.user.signName}</Typography>
          <Typography className={classes.registerDateTime}>
            {convertIntoDateTimeFormat(message.registerDateTime)}
          </Typography>
          <IconButton aria-label="Delete" className={classes.button} onClick={this.handleDelete}>
            <DeleteIcon fontSize="small" color="secondary" />
          </IconButton>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(Message);
