import gql from 'graphql-tag';

import {
  MailTemplateFieldsFragment,
  PdfFieldsOfListFragment,
  PdfFieldsFragment
} from './fragments';

export const FETCH_MAIL_TEMPLATE = gql`
  query FetchMailTemplate($where: MailTemplateWhereUniqueInput!) {
    fetchMailTemplate(where: $where) {
      ...MailTemplateFields
    }
  }
  ${MailTemplateFieldsFragment}
`;

export const NEW_MAIL_TEMPLATE = gql`
  mutation AddMailTemplate($data: MailTemplateCreateInput!) {
    addMailTemplate(data: $data) {
      id
    }
  }
`;

export const EDIT_MAIL_TEMPLATE = gql`
  mutation EditMailTemplate(
    $data: MailTemplateUpdateInput!
    $where: MailTemplateWhereUniqueInput!
  ) {
    editMailTemplate(data: $data, where: $where) {
      id
    }
  }
`;

export const REMOVE_MAIL_TEMPLATE = gql`
  mutation RemoveMailTemplate($where: MailTemplateWhereUniqueInput!) {
    removeMailTemplate(where: $where) {
      id
    }
  }
`;

export const GET_MAIL_TEMPLATES = gql`
  query GetMailTemplates($where: MailTemplateWhereInput, $orderBy: MailTemplateOrderByInput) {
    mailTemplates(where: $where, orderBy: $orderBy) {
      ...MailTemplateFields
    }
  }
  ${MailTemplateFieldsFragment}
`;

export const GET_SEND_MAIL_FORM_OPTIONS = gql`
  query GetSendMailFormOptions(
    $voucherWhere: VoucherWhereUniqueInput!
    $customerWhere: CustomerWhereInput!
  ) {
    mailTemplates(where: {}, orderBy: order_ASC) {
      ...MailTemplateFields
    }
    fetchVoucher(where: $voucherWhere) {
      id
      user {
        id
        name
        nameKana
        email
      }
      customer {
        id
        name
        nameKana
        email
        customerType
        personnels {
          id
          name
          nameKana
          email
        }
      }
      personnel {
        id
        name
        nameKana
        email
      }
      title
      subTitle
      billDate
    }
    getBillableVouchers(where: $customerWhere) {
      id
      title
      subTitle
      billDate
      program {
        programNo
      }
      personnel {
        id
        name
      }
    }
  }
  ${MailTemplateFieldsFragment}
`;

export const PREVIEW_PDF = gql`
  query PreviewPdfFromVouchers($data: PdfInput!) {
    previewPdfFromVouchers(data: $data)
  }
`;

export const SEND_MAIL = gql`
  mutation SendEmail($data: MailSendInput!) {
    sendMail(data: $data) {
      isSuccess
      statusCode
    }
  }
`;

export const SEARCH_PDFS = gql`
  query SearchPdfs($where: PdfWhereInput, $skip: Int, $first: Int, $orderBy: PdfOrderByInput) {
    searchPdfs(where: $where, skip: $skip, first: $first, orderBy: $orderBy) {
      count
      pdfs {
        ...PdfFieldsOfList
      }
    }
  }
  ${PdfFieldsOfListFragment}
`;

export const FETCH_PDF = gql`
  query FetchPdf($where: PdfWhereUniqueInput!) {
    fetchPdf(where: $where) {
      ...PdfFields
    }
  }
  ${PdfFieldsFragment}
`;
