import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { convertIntoDateFormat } from 'utils/date';

import Typography from '@material-ui/core/Typography';

const Item = ({ classes, label, user, date }) => {
  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <Typography>{label}</Typography>
      </div>
      <div className={classes.body}>
        {user ? (
          <React.Fragment>
            <Typography>{user.signName}</Typography>
            <Typography>{convertIntoDateFormat(date)}</Typography>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};
const EnhancedItem = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 80,
    border: `1.2px solid ${theme.palette.divider}`
  },
  head: {
    textAlign: 'center',
    borderBottom: `1.2px solid ${theme.palette.divider}`
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 50
  }
}))(Item);

const styles = theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    width: 500,
    backgroundColor: theme.palette.background.paper
  }
});

class ReportStatusFlow extends React.Component {
  render() {
    const {
      classes,
      report: {
        registerUser,
        registerDate,
        finishUser,
        finishDate,
        checkUser,
        checkDate,
        approveUser,
        approveDate,
        completeUser,
        completeDate
      }
    } = this.props;

    return (
      <div className={classes.wrapper}>
        <div className={classes.root}>
          <EnhancedItem label="作業待機" user={registerUser} date={registerDate} />
          <EnhancedItem label="作業終了" user={finishUser} date={finishDate} />
          <EnhancedItem label="済" user={checkUser} date={checkDate} />
          <EnhancedItem label="承認" user={approveUser} date={approveDate} />
          <EnhancedItem label="終了" user={completeUser} date={completeDate} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ReportStatusFlow);
