import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { RESET_PASSWORD_STEP1 } from 'gqls/user';
import { redirect } from 'browser';

import ResetPasswordStep1Form from 'forms/ResetPasswordStep1Form';

const styles = theme => ({
  main: {
    width: 800,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  sent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing.unit * 3
  }
});

class ResetPasswordStep1 extends React.Component {
  state = {
    sent: false
  };

  handleSucceed = () => {
    this.setState({ sent: true });
  };

  handleClose = () => {
    redirect('/');
  };

  render() {
    const { classes, mutate } = this.props;
    return (
      <main className={classes.main}>
        {!this.state.sent ? (
          <ResetPasswordStep1Form mutate={mutate} onSucceed={this.handleSucceed} />
        ) : (
          <div className={classes.sent}>
            <Typography component="h2" variant="h6" align="center">
              メールが送信されました。
            </Typography>
            <Button size="small" variant="text" onClick={this.handleClose}>
              TOPへ
            </Button>
          </div>
        )}
      </main>
    );
  }
}

export default compose(
  withStyles(styles),
  graphql(RESET_PASSWORD_STEP1)
)(ResetPasswordStep1);
