import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import TableRow from '@material-ui/core/TableRow';

import { redirect } from 'browser';
import wrapTablePagination from 'hocs/wrapTablePagination';

import SortableTableHead from 'elements/SortableTableHead';

const columns = [
  { id: 'name', align: 'left', disablePadding: false, label: '名前', sortable: true },
  { id: 'nameKana', align: 'left', disablePadding: false, label: '名前（カナ）', sortable: true },
  { id: 'employeeId', align: 'left', disablePadding: false, label: '社員番号', sortable: true },
  { id: 'sections', align: 'left', disablePadding: false, label: '部署', sortable: false }
];

class HeadBody extends React.Component {
  handleClick = user => {
    redirect(`/users/${user.id}`);
  };

  render() {
    const { classes, order, orderBy, users, handleRequestSort } = this.props;
    return (
      <React.Fragment>
        <SortableTableHead
          order={order}
          orderBy={orderBy}
          columns={columns}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {users.map(user => (
            <TableRow hover onClick={() => this.handleClick(user)} tabIndex={-1} key={user.id}>
              <TableCell component="th" scope="row">
                {user.name}
              </TableCell>
              <TableCell>{user.nameKana}</TableCell>
              <TableCell>{user.employeeId}</TableCell>
              <TableCell>
                <div className={classes.chips}>
                  {user.sections.map(({ id, name }) => (
                    <Chip key={id} label={name} className={classes.chip} />
                  ))}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </React.Fragment>
    );
  }
}

const ConnectedHeadBody = withStyles(theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing.unit / 4
  }
}))(HeadBody);

export default wrapTablePagination()(ConnectedHeadBody);
