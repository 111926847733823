import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import LoginForm from 'forms/LoginForm';

const styles = theme => ({
  main: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  }
});

class Login extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <LoginForm />
      </main>
    );
  }
}

export default withStyles(styles)(Login);
