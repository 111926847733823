import React from 'react';
import { compose, graphql } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { UserContext } from 'contexts';
import { GET_PROGRAM_STATUSES } from 'gqls/gql';
import { redirect } from 'browser';
import wrapMenu from 'hocs/wrapMenu';

import { ReportStatus, VoucherStatus } from 'elements/ProgramStatus';

const takeValues = data => {
  const reports = [];
  const vouchers = [];

  Object.entries(data).forEach(([name, value]) => {
    if (name.startsWith('R_')) {
      reports.push({
        status: name.replace('R_', ''),
        count: value
      });
    } else if (name.startsWith('V_')) {
      vouchers.push({
        status: name.replace('V_', ''),
        count: value
      });
    }
  });

  return {
    reports,
    vouchers
  };
};

const styles = theme => ({
  main: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4
  },
  divider: {
    flex: '0 0 1px',
    height: '100%'
  },
  panel: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2
  },
  head: {
    display: 'flex',
    alignItems: 'center'
  },
  addButton: {
    marginLeft: theme.spacing.unit * 2
  }
});

const Head = props => {
  const { classes, children, onAddClick } = props;

  return (
    <div className={classes.head}>
      <Typography component="h2" variant="h6">
        {children}
      </Typography>
      <Fab size="small" color="secondary" className={classes.addButton} onClick={onAddClick}>
        <AddIcon />
      </Fab>
    </div>
  );
};

const Dashboard = props => {
  const { classes, data } = props;

  if (data.loading) return null;

  const { reports, vouchers } = takeValues(data);

  return (
    <UserContext.Consumer>
      {value => (
        <main className={classes.main}>
          <div className={classes.panel}>
            <Head
              classes={classes}
              onAddClick={() => {
                redirect('/reports/new');
              }}
            >
              ワークレポート
            </Head>
            {reports.map(info => (
              <ReportStatus key={info.status} info={info} />
            ))}
          </div>

          {value.userLevel !== 'User' && (
            <React.Fragment>
              <Divider className={classes.divider} />

              <div className={classes.panel}>
                <Head
                  classes={classes}
                  onAddClick={() => {
                    redirect('/vouchers/new');
                  }}
                >
                  作業伝票
                </Head>
                {vouchers.map(info => (
                  <VoucherStatus key={info.status} info={info} />
                ))}
              </div>
            </React.Fragment>
          )}
        </main>
      )}
    </UserContext.Consumer>
  );
};

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: 'ダッシュボード' }),
  graphql(GET_PROGRAM_STATUSES)
)(Dashboard);
