import React from 'react';
import queryString from 'query-string';

import wrapMenu from 'hocs/wrapMenu';

import NewVoucherPurely from './NewVoucherPurelyScreen';
import NewVoucherByReport from './NewVoucherByReportScreen';
import NewVoucherOthers from './NewVoucherOthersScreen';

function NewVoucher(props) {
  if (queryString.parse(props.location.search).reportId) {
    return <NewVoucherByReport {...props} />;
  } else if (queryString.parse(props.location.search).voucherId) {
    return <NewVoucherOthers {...props} />;
  } else {
    return <NewVoucherPurely {...props} />;
  }
}

export default wrapMenu({
  appBarText: '作業伝票',
  colorOrange: true
})(NewVoucher);
