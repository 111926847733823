import React from 'react';
import { compose, graphql } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import { MEDIA_LIST, ADD_MEDIA, EDIT_MEDIA, REMOVE_MEDIA, ARRANGE_MEDIAS } from 'gqls/media';
import wrapDragDropList from 'hocs/wrapDragDropList';
import wrapMenu from 'hocs/wrapMenu';

import MediaForm from 'forms/MediaForm';

const DragDropList = compose(
  graphql(ADD_MEDIA, { name: 'addMedia' }),
  graphql(EDIT_MEDIA, { name: 'editMedia' }),
  graphql(REMOVE_MEDIA, { name: 'removeMedia' }),
  graphql(ARRANGE_MEDIAS, { name: 'arrangeMedias' }),
  wrapDragDropList({
    label: 'メディア',
    defaultValues: {
      name: '',
      unitPrice: 0
    },
    convertIntoForm: values => values,
    convertIntoGql: ({ ...values }) => {
      values.unitPrice = +values.unitPrice;
      return values;
    },
    handleAddName: 'addMedia',
    handleEditName: 'editMedia',
    handleRemoveName: 'removeMedia',
    handleArrangeName: 'arrangeMedias'
  })
)(MediaForm);

const styles = theme => ({
  main: {
    width: 600,
    marginTop: theme.spacing.unit * 8,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 10,
    right: theme.spacing.unit * 10
  }
});

class MediaList extends React.Component {
  render() {
    const { classes, data } = this.props;

    if (data.loading) return null;

    return (
      <main className={classes.main}>
        <DragDropList list={data.medias} />
      </main>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  wrapMenu({ appBarText: 'メディアマスタ' }),
  graphql(MEDIA_LIST)
)(MediaList);
