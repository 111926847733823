import { remove, sortBy, maxBy } from 'lodash';

export const getIntegratedVouchers = programs => {
  const newVouchers = [];
  for (let i = 0; i < programs.length; i++) {
    const program = programs[i];
    if (program.vouchers.length > 0) {
      const voucher = maxBy(program.vouchers, 'revision');
      voucher.program = program;
      newVouchers.push(voucher);
    }
  }
  return newVouchers;
};

export const getOtherVouchers = (mainVoucher, [...allVouchers]) => {
  const otherVouchers = remove(allVouchers, voucher => voucher.id !== mainVoucher.id);
  const sortedVouchers = sortBy(otherVouchers, ['revision']);
  sortedVouchers.reverse();
  return sortedVouchers;
};
