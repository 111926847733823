import { userEditInitialValues } from 'initialValues';
import { mergeInitialValues } from 'utils/form';
import { convertIntoDateFormat } from 'utils/date';

export const convertIntoUserForm = ({ ...values }) => {
  values.sectionsRelatedIds = values.sections.map(section => section.id);
  values.joinedDate = convertIntoDateFormat(values.joinedDate);

  delete values.sections;
  delete values.password;
  delete values.passwordConfirm;

  return mergeInitialValues(userEditInitialValues, values);
};

export const convertIntoMyselfForm = ({ ...values }) => {
  const newValues = convertIntoUserForm(values);

  delete newValues.userLevel;

  return newValues;
};
