import { mailTemplateInitialValues, sendMailInitialValues } from 'initialValues';

import { mergeInitialValues } from 'utils/form';
import { convertIntoDateFormat } from 'utils/date';

const convertEmailsStrToArr = value => {
  if (!value) return [];

  const arr = value.split(',');
  return arr.map(email => ({
    email
  }));
};

export const convertIntoTemplateForm = values => {
  return mergeInitialValues(mailTemplateInitialValues, values);
};

export const convertIntoMailForm = (voucher, user) => {
  const values = {
    date: convertIntoDateFormat(voucher.billDate),
    personnel: voucher.personnel ? voucher.personnel.id : '',
    from: user.email,
    tos: voucher.personnel ? voucher.personnel.email : '',
    mainWhere: voucher.id
  };
  return mergeInitialValues(sendMailInitialValues, values);
};

export const convertIntoPreviewPdfGql = ({ ...values }, overwrite) => {
  delete values.bccs;
  delete values.ccs;
  delete values.from;
  delete values.mailTemplate;
  delete values.personnel;
  delete values.subject;
  delete values.tos;
  delete values.attachmentFiles;
  delete values.text;

  values = { ...values, ...overwrite };

  if (values.allWhere.length === 0) {
    values.allWhere = [values.mainWhere];
  }

  values.mainWhere = { id: values.mainWhere };
  values.allWhere = { id_in: values.allWhere };
  values.companyStamp =
    values.companyStamp === true || values.companyStamp === 'true' ? true : false;

  return values;
};

export const convertIntoSendMailGql = ({ ...values }, files) => {
  delete values.mailTemplate;
  delete values.personnel;

  values.from = { email: values.from };

  values.tos = convertEmailsStrToArr(values.tos);
  values.ccs = convertEmailsStrToArr(values.ccs);
  values.bccs = convertEmailsStrToArr(values.bccs);

  values.attachmentFiles = files;

  values.pdfInput = convertIntoPreviewPdfGql(values, {});
  delete values.mainWhere;
  delete values.allWhere;
  delete values.date;
  delete values.greeting;
  delete values.payment;
  delete values.pdfType;
  delete values.title;
  delete values.companyStamp;

  return values;
};
