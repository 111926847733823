import React from 'react';
import { find } from 'lodash';

import { withApollo } from 'react-apollo';

import { SEARCH_CUSTOMERS, GET_PERSONNELS } from 'gqls/customer';
import { notifyError } from 'notifications';

export const withCustomerPersonnelField = (options = {}) => Component => {
  const { willSubmit = false, takeInitialCustomer = () => undefined } = options;

  class C extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        selectedCustomer: takeInitialCustomer(this.props),
        customers: [],
        personnels: []
      };
    }

    setSelectedCustomer = customer => {
      if (customer) {
        this.setState({ selectedCustomer: customer });
      }
    };

    setCustomers = customers => {
      if (customers) {
        this.setState({ customers });
      }
    };

    setPersonnels = personnels => {
      if (personnels) {
        this.setState({ personnels });
      }
    };

    resetPersonnelField = () => {
      const { setFieldTouched, setFieldValue } = this.props;

      setFieldValue('personnelRelatedId', '');
      setFieldTouched('personnelRelatedId', false);
    };

    handleCustomerInputChange = value => {
      const { setFieldValue } = this.props;

      if (value === '') {
        setFieldValue('customerRelatedId', '');
      }

      this.resetPersonnelField();
    };

    handleCustomerInputDebounceChange = value => {
      const { client } = this.props;

      const variables = {
        where: {
          AND: {
            OR: [{ name_contains: value }, { nameKana_contains: value }],
            isFreezed: false
          }
        },
        skip: 0,
        first: 20,
        orderBy: 'nameKana_DESC'
      };

      client
        .query({
          query: SEARCH_CUSTOMERS,
          variables
        })
        .then(({ data }) => {
          const {
            searchCustomers: { customers }
          } = data;
          this.setState({ customers });
        })
        .catch(() => {});
    };

    handleCustomerChange = ({ id }) => {
      const { setFieldValue, submitForm, client } = this.props;
      const { customers } = this.state;

      setFieldValue('customerRelatedId', id);

      const customer = find(customers, { id });
      this.setSelectedCustomer(customer);

      const variables = {
        where: { customer: { id }, isRetired: false }
      };
      client
        .query({
          query: GET_PERSONNELS,
          variables
        })
        .then(({ data: { getPersonnels } }) => {
          if (getPersonnels.length > 0) {
            this.setState({ personnels: getPersonnels });
          } else {
            notifyError('先に顧客の担当者を登録してください。');
          }
        })
        .finally(() => {
          this.resetPersonnelField();
        })
        .catch(() => {});

      if (willSubmit) {
        submitForm();
      }
    };

    render() {
      const { selectedCustomer, customers, personnels } = this.state;

      return (
        <Component
          {...this.props}
          selectedCustomer={selectedCustomer}
          customers={customers}
          personnels={personnels}
          setCustomers={this.setCustomers}
          setPersonnels={this.setPersonnels}
          handleCustomerInputChange={this.handleCustomerInputChange}
          handleCustomerInputDebounceChange={this.handleCustomerInputDebounceChange}
          handleCustomerChange={this.handleCustomerChange}
        />
      );
    }
  }

  C.displayName = `withCustomerPersonnelField(${Component.displayName || Component.name})`;

  return withApollo(C);
};

export default withCustomerPersonnelField;
