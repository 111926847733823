import { pickBy } from 'lodash';

export const takeSpecificKeys = (obj, keys) => pickBy(obj, (_, k) => keys.includes(k));

export const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeVoidProps = ({ ...obj }) => {
  Object.entries(obj).forEach(([name, value]) => {
    if (name === '__typename') {
      delete obj[name];
    } else {
      (value === null || value === undefined || value === '') && delete obj[name];
    }
  });
  return obj;
};

export const convertNullOrUndefinedToEmptyStr = ({ ...obj }) => {
  Object.entries(obj).forEach(([name, value]) => {
    if (value === null || value === undefined) {
      obj[name] = '';
    }
  });
  return obj;
};
