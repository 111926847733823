import React from 'react';
import queryString from 'query-string';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';

import { ProgressContext } from 'contexts';
import { FETCH_REPORT, NEW_VOUCHER } from 'gqls/program';
import { convertIntoNewVoucherForm } from 'formConvertors/program';
import { redirect } from 'browser';
import { styles, schema } from './NewVoucherPurelyScreen';

import VoucherForm from 'forms/VoucherForm';

class NewVoucher extends React.Component {
  onSucceed = ({ data }) => {
    const {
      addVoucher: { id }
    } = data;
    redirect(`/vouchers/${id}`);
  };

  render() {
    const { classes, data, mutate } = this.props;

    if (data.loading) return null;

    const initialValues = convertIntoNewVoucherForm(data.fetchReport, data.fetchReport.customer);

    return (
      <ProgressContext.Consumer>
        {({ updateProgressState }) => (
          <main className={classes.main}>
            <VoucherForm
              action="NewVoucherByReport"
              schema={schema}
              initialValues={initialValues}
              mutate={mutate}
              onSucceed={this.onSucceed}
              updateProgressState={updateProgressState}
              customer={data.fetchReport.customer}
            />
          </main>
        )}
      </ProgressContext.Consumer>
    );
  }
}

export default compose(
  withStyles(styles),
  graphql(FETCH_REPORT, {
    options: props => ({
      variables: {
        where: { id: queryString.parse(props.location.search).reportId }
      }
    })
  }),
  graphql(NEW_VOUCHER)
)(NewVoucher);
