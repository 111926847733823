import React from 'react';
import { Switch, Route } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'react-apollo';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { getCurrentPeriod } from 'utils/period';
import wrapMenu from 'hocs/wrapMenu';

import SalesScreen from 'screens/SalesScreen';
import RankingsScreen from 'screens/RankingsScreen';

const styles = theme => ({
  main: {
    flex: '1 1 auto',
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  },
  page: {
    paddingTop: theme.spacing.unit
  }
});

class Statistics extends React.Component {
  handleChange = (_, value) => {
    const { history } = this.props;

    let tab;
    switch (value) {
      case 0:
        tab = 'sales';
        break;
      case 1:
        tab = 'rankings';
        break;
      default:
        throw new Error();
    }

    const period = getCurrentPeriod();
    history.replace(`/statistics/${tab}?period=${period.value}`);
  };

  render() {
    const { classes, match } = this.props;

    let tabIndex;
    switch (match.params.tab) {
      case 'sales':
        tabIndex = 0;
        break;
      case 'rankings':
        tabIndex = 1;
        break;
      default:
        throw new Error();
    }

    return (
      <main className={classes.main}>
        <Tabs value={tabIndex} onChange={this.handleChange}>
          <Tab label="売上" />
          <Tab label="ランキング" />
        </Tabs>
        <div className={classes.page}>
          <Switch>
            <Route exact path="/statistics/sales" component={SalesScreen} />
            <Route exact path="/statistics/rankings" component={RankingsScreen} />
          </Switch>
        </div>
      </main>
    );
  }
}

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: '統計' })
)(Statistics);
