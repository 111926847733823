import React from 'react';
import { get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import { takeErrorState, takeHelperText } from 'utils/form';

const MyTextField = props => {
  const {
    name,
    type = 'text',
    values,
    touched,
    errors,
    label,
    margin = 'dense',
    smallFont = false,
    redFont = false,
    InputLabelProps = { shrink: true },
    InputProps = {},
    inputProps = {},
    onChange,
    onBlur,
    theme,
    ...rest
  } = props;

  if (type === 'time') {
    inputProps['step'] = 60 * 15;
  }

  if (smallFont) {
    InputProps['style'] = { ...InputProps['style'], fontSize: '0.8rem' };
  }

  if (redFont) {
    InputProps['style'] = { ...InputProps['style'], color: theme.palette.error.main };
  }

  const common = {
    label,
    margin,
    InputLabelProps,
    InputProps,
    inputProps,
    onChange,
    onBlur,
    error: takeErrorState(errors, touched, name),
    helperText: takeHelperText(errors, touched, name),
    ...rest
  };

  if (type !== 'div') {
    return <TextField id={name} name={name} type={type} value={get(values, name)} {...common} />;
  } else {
    return <TextField {...common} />;
  }
};

export default withStyles(() => {}, { withTheme: true })(MyTextField);
