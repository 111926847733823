export default Object.freeze({
  VALUE: {
    START_DAY: '2003-09-01',
    DRAWER_WIDTH: 240
  },
  MESSAGE: {
    UPDATED: '変更されました。',
    REQUIRED: '必ず入力してください。',
    SERVER_ERROR: 'サーバーエラーが発生しました。',
    NETWORK_ERROR: '通信エラーが発生しました。',
    UNIQUE_CONSTRAINT_VIOLATION: 'すでに他で値が使用されています。',
    INVALID_LOGIN_ID: 'LoginID が 見つかりませんでした。',
    INVALID_PASSWORD: 'パスワードが間違っています。',
    LOCK_VERSION_VIOLATION: '変更が競合しました。リロードしてください。'
  }
});
