import React from 'react';
import classNames from 'classnames';
import { withFormik } from 'formik';
import { compose, graphql } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';
import { isEmpty } from 'lodash';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import DoneIcon from '@material-ui/icons/Done';
import Chip from '@material-ui/core/Chip';

import { GET_REPORT_FORM_OPTIONS, EDIT_REPORT_STATUS, REMOVE_REPORT } from 'gqls/program';
import { convertIntoEditReportGql } from 'formConvertors/program';
import {
  convertIntoSelectFieldValues,
  takeOption,
  takeOptionField,
  relateFields
} from 'utils/form';
import withCustomerPersonnelField from 'hocs/withCustomerPersonnelField';
import withReactSelectHandler from 'hocs/withReactSelectHandler';
import withBlurSubmit from 'hocs/withBlurSubmit';
import enums from 'enums';
import { confirm, reload, redirect } from 'browser';

import MyTextField from 'elements/MyTextField';
import SearchSelectField from 'elements/SearchSelectField';
import SuggestSelectField from 'elements/SuggestSelectField';
import ReportOperationFieldsForm from 'forms/ReportOperationFieldsForm';
import ReportExpenseFieldsForm from 'forms/ReportExpenseFieldsForm';
import ReportMediaFieldsForm from 'forms/ReportMediaFieldsForm';
import ReportStatusFlow from 'elements/ReportStatusFlow';
import OrangeButton from 'elements/OrangeButton';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(theme.breakpoints.values.md + theme.spacing.unit * 2 * 2)]: {
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 2
    }
  },
  form: {
    marginTop: theme.spacing.unit * 2
  },
  actionBottons: {
    marginTop: theme.spacing.unit * 3,
    alignItems: 'flex-end'
  },
  statusesWrapper: {
    marginTop: theme.spacing.unit * 3
  },
  buttonWrapper: {
    display: 'flex'
  },
  positionCenter: {
    justifyContent: 'center'
  },
  positionRight: {
    justifyContent: 'flex-end'
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  labels: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  chip: {
    marginLeft: theme.spacing.unit
  }
});

class ReportForm extends React.Component {
  state = {
    movable: false
  };

  createHandleCheckReport = reportSection => e => {
    const {
      values: { id },
      handleChange,
      checkReport
    } = this.props;

    if (confirm('ステータスを変更してもよろしいですか？')) {
      handleChange(e);

      let reportCheck;
      const value = e.target.value;
      reportCheck = value !== '' ? value : null;

      checkReport({
        variables: {
          where: { id },
          reportSection,
          reportCheck
        }
      })
        .then(() => {
          reload();
        })
        .catch(error => {});
    }
  };

  componentDidMount() {
    const { action, setCustomers, setPersonnels } = this.props;

    if (action === 'EditReport') {
      const { customer } = this.props;
      if (customer) {
        setCustomers([customer]);
        setPersonnels(customer.personnels);
      }
    }
  }

  handleStatusChange = e => {
    const {
      values: { id },
      handleChange,
      editReportStatus
    } = this.props;

    if (confirm('ステータスを変更してもよろしいですか？')) {
      handleChange(e);

      const value = e.target.value;

      editReportStatus({
        variables: {
          where: { id },
          reportStatus: value
        }
      })
        .then(() => {
          reload();
        })
        .catch(error => {});
    }
  };

  handleDeleteClick = () => {
    const {
      values: { id },
      removeReport
    } = this.props;

    if (confirm('このワークレポートを削除してもよろしいですか？')) {
      removeReport({
        variables: {
          where: { id }
        }
      })
        .then(() => {
          redirect('/reports');
        })
        .catch(error => {});
    }
  };

  handleNewVoucherClick = () => {
    const {
      values: { id }
    } = this.props;

    if (confirm('作業伝票登録画面に移行しますか？')) {
      redirect(`/vouchers/new?reportId=${id}`);
    }
  };

  handleToggleMovable = () => {
    const { submitForm } = this.props;
    const b = !this.state.movable;
    this.setState({
      movable: b
    });
    if (!b) {
      submitForm();
    }
  };

  render() {
    const {
      classes,
      initialValues,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      submitForm
    } = this.props;
    const { movable } = this.state;

    const { action, data, hasVoucher } = this.props;

    const {
      customers,
      personnels,
      handleCustomerInputChange,
      handleCustomerInputDebounceChange,
      handleCustomerChange
    } = this.props;

    const { createHandleSelect } = this.props;

    const formProps = {
      action,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      submitForm
    };

    if (data.loading) return null;

    const userOptions = convertIntoSelectFieldValues(data.getUsers);
    const customerOptions = convertIntoSelectFieldValues(customers);
    const personnelOptions = convertIntoSelectFieldValues(personnels);
    const categorizedOperationOptions = {
      Eizo: convertIntoSelectFieldValues(data.eizoOperations),
      Onkyo: convertIntoSelectFieldValues(data.onkyoOperations),
      Gaiga: convertIntoSelectFieldValues(data.gaigaOperations),
      Eigyo: convertIntoSelectFieldValues(data.eigyoOperations),
      Other: convertIntoSelectFieldValues(data.otherOperations)
    };
    const mediaOptions = convertIntoSelectFieldValues(data.medias);
    const expenseOptions = convertIntoSelectFieldValues(data.expenses);

    const { selectedCustomer } = this.props;
    const labels = selectedCustomer ? selectedCustomer.labels : [];

    return (
      <Paper className={classes.paper}>
        <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={8}>
            {action === 'NewReport' ? (
              <React.Fragment>
                <Grid item xs={3}>
                  <SuggestSelectField
                    TextFieldProps={{
                      name: 'userRelatedId',
                      touched: touched,
                      errors: errors,
                      label: '管理者',
                      margin: 'none',
                      smallFont: true,
                      required: true,
                      fullWidth: true
                    }}
                    options={userOptions}
                    selectedOption={{
                      value: values['userRelatedId'],
                      label: takeOptionField(userOptions, values['userRelatedId'], 'name')
                    }}
                    onChange={createHandleSelect('userRelatedId')}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={9}>
                  <div className={classes.labels}>
                    {labels.map(label => (
                      <Chip
                        key={label.color}
                        style={{ backgroundColor: label.color, fontWeight: 400 }}
                        label={label.name}
                        color="primary"
                        className={classes.chip}
                      />
                    ))}
                  </div>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid item xs={2}>
                  <MyTextField
                    name="programNo"
                    values={values}
                    touched={touched}
                    errors={errors}
                    label="No."
                    margin="none"
                    smallFont
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyTextField
                    name="reportStatus"
                    values={values}
                    touched={touched}
                    errors={errors}
                    label="ステータス"
                    margin="none"
                    smallFont
                    onChange={this.handleStatusChange}
                    fullWidth
                    select
                  >
                    {Object.entries(enums.REPORT_STATUS).map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </MyTextField>
                </Grid>
                <Grid item xs={2}>
                  <SuggestSelectField
                    TextFieldProps={{
                      name: 'userRelatedId',
                      touched: touched,
                      errors: errors,
                      label: '管理者',
                      margin: 'none',
                      smallFont: true,
                      required: true,
                      fullWidth: true
                    }}
                    options={userOptions}
                    selectedOption={{
                      value: values['userRelatedId'],
                      label: takeOptionField(userOptions, values['userRelatedId'], 'name')
                    }}
                    onChange={createHandleSelect('userRelatedId')}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.labels}>
                    {labels.map(label => (
                      <Chip
                        key={label.color}
                        style={{ backgroundColor: label.color, fontWeight: 400 }}
                        label={label.name}
                        color="primary"
                        className={classes.chip}
                      />
                    ))}
                  </div>
                </Grid>
              </React.Fragment>
            )}
            <Grid item xs={6}>
              <SearchSelectField
                TextFieldProps={{
                  name: 'customerRelatedId',
                  values: values,
                  touched: touched,
                  errors: errors,
                  label: '顧客',
                  margin: 'none',
                  smallFont: true,
                  onBlur: handleBlur,
                  required: true,
                  fullWidth: true
                }}
                defaultValue={takeOption(customerOptions, values['customerRelatedId'])}
                options={customerOptions}
                onInputChange={handleCustomerInputChange}
                onInputDebounceChange={handleCustomerInputDebounceChange}
                onChange={handleCustomerChange}
              />
            </Grid>
            <Grid item xs={6}>
              <SuggestSelectField
                TextFieldProps={{
                  name: 'personnelRelatedId',
                  touched: touched,
                  errors: errors,
                  label: '担当者',
                  margin: 'none',
                  smallFont: true,
                  fullWidth: true
                }}
                options={personnelOptions}
                selectedOption={{
                  value: values['personnelRelatedId'],
                  label: takeOptionField(personnelOptions, values['personnelRelatedId'], 'name')
                }}
                onChange={createHandleSelect('personnelRelatedId')}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="title"
                values={values}
                touched={touched}
                errors={errors}
                label="PROGRAM"
                margin="none"
                smallFont
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="subTitle"
                values={values}
                touched={touched}
                errors={errors}
                label="サブタイトル"
                margin="none"
                smallFont
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MyTextField
                name="memo"
                values={values}
                touched={touched}
                errors={errors}
                label="備考"
                margin="none"
                smallFont
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                multiline
                rowsMax="10"
              />
            </Grid>
          </Grid>
          {action === 'EditReport' ? (
            <React.Fragment>
              <ReportOperationFieldsForm
                {...formProps}
                movable={movable}
                onToggleMovable={this.handleToggleMovable}
                userOptions={userOptions}
                categorizedOperationOptions={categorizedOperationOptions}
              />
              <ReportMediaFieldsForm
                {...formProps}
                movable={movable}
                onToggleMovable={this.handleToggleMovable}
                userOptions={userOptions}
                mediaOptions={mediaOptions}
              />
              <ReportExpenseFieldsForm
                {...formProps}
                movable={movable}
                onToggleMovable={this.handleToggleMovable}
                userOptions={userOptions}
                expenseOptions={expenseOptions}
              />
            </React.Fragment>
          ) : null}
          <Grid container spacing={0} className={classes.actionBottons}>
            <Grid item xs={4} />
            <Grid item xs={4} className={classNames(classes.buttonWrapper, classes.positionCenter)}>
              {action === 'NewReport' && (
                <Button type="submit" variant="contained" color="primary">
                  登録
                </Button>
              )}
            </Grid>
            <Grid item xs={4} className={classNames(classes.buttonWrapper, classes.positionRight)}>
              {action === 'EditReport' ? (
                <Button size="small" color="secondary" onClick={this.handleDeleteClick}>
                  このワークレポートを削除
                </Button>
              ) : null}
            </Grid>
          </Grid>
          {action === 'EditReport' ? (
            <div className={classes.statusesWrapper}>
              <ReportStatusFlow report={initialValues} />
            </div>
          ) : null}
          <Grid container spacing={0} className={classes.actionBottons}>
            <Grid item xs={4} />
            <Grid item xs={4} className={classNames(classes.buttonWrapper, classes.positionCenter)}>
              {!hasVoucher && values.reportStatus === 'Approved' ? (
                <OrangeButton variant="contained" onClick={this.handleNewVoucherClick}>
                  作業伝票を登録
                  <DoneIcon className={classes.rightIcon} />
                </OrangeButton>
              ) : null}
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </form>
      </Paper>
    );
  }
}

const takeNewValues = values => {
  return {
    data: relateFields(values)
  };
};

const takeEditValues = (values, initialValues) => {
  return {
    data: convertIntoEditReportGql(values, initialValues),
    where: {
      id: values.id
    }
  };
};

export default compose(
  withStyles(styles),
  graphql(GET_REPORT_FORM_OPTIONS),
  graphql(EDIT_REPORT_STATUS, { name: 'editReportStatus' }),
  graphql(REMOVE_REPORT, { name: 'removeReport' }),
  withFormik({
    mapPropsToValues: props => props.initialValues,
    validationSchema: props => props.schema,
    handleSubmit: (values, { props, setSubmitting }) => {
      const { action, initialValues, mutate, updateProgressState, onSucceed } = props;

      let variables;
      switch (action) {
        case 'NewReport':
          variables = takeNewValues(values);
          break;
        case 'EditReport':
          variables = takeEditValues(values, initialValues);
          break;
        default:
          throw new Error();
      }

      if (isEmpty(variables.data)) {
        return;
      }

      console.log(variables);

      updateProgressState(true);

      mutate({
        variables
      })
        .then(onSucceed)
        .catch(error => {})
        .finally(() => {
          setSubmitting(false);
          updateProgressState(false);
        });
    },
    displayName: 'ReportForm'
  }),
  withReactSelectHandler(),
  withCustomerPersonnelField({
    takeInitialCustomer: props => (props.customer ? props.customer : undefined)
  }),
  withBlurSubmit({ willSubmit: props => props.action === 'EditReport' })
)(ReportForm);
