import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';

import { redirect } from 'browser';
import { numberWithCommas } from 'utils/util';
import { convertIntoDateFormat } from 'utils/date';
import enums from 'enums';

const styles = theme => ({
  root: {
    position: 'relative'
  },
  grid: {
    padding: theme.spacing.unit
  },
  chips: {
    marginTop: theme.spacing.unit,
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing.unit / 4
  },
  button: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  marginTop: {
    marginTop: theme.spacing.unit
  },
  memo: {
    whiteSpace: 'pre-line'
  }
});

class CustomerPanel extends React.Component {
  render() {
    const {
      classes,
      customer: {
        id,
        name,
        postalCode,
        prefecture,
        address,
        tel,
        email,
        paymentType,
        paymentMonthLater,
        paymentDay,
        paymentClosingDay,
        maPrice,
        eedPrice,
        labels,
        memo,
        registerDate
      }
    } = this.props;

    if (!id) return null;

    return (
      <Paper className={classes.root}>
        <Grid container>
          <Grid item xs={6} className={classes.grid}>
            <Typography variant="h6" gutterBottom>
              基本情報
            </Typography>
            <Typography component="p">{name}</Typography>
            <div className={classes.marginTop}>
              <Typography component="p">{postalCode ? `〒 ${postalCode}` : null}</Typography>
              <Typography component="p">
                {prefecture} {address}
              </Typography>
            </div>
            <div className={classes.marginTop}>
              <Typography component="p">{tel ? `TEL: ${tel}` : null}</Typography>
              <Typography component="p">{email ? `Email: ${email}` : null}</Typography>
            </div>
          </Grid>
          <Grid item xs={6} className={classes.grid}>
            <Typography variant="h6" gutterBottom>
              お支払い情報
            </Typography>
            <Typography component="p">
              支払い条件: {paymentClosingDay}日締め
              {paymentType === 'MonthLater'
                ? ` ${enums.CUSTOMER_PAYMENT_TYPE[paymentType]} ${paymentMonthLater}ヶ月後 ${paymentDay}日`
                : ` ${enums.CUSTOMER_PAYMENT_TYPE[paymentType]}`}
            </Typography>
            <div className={classes.marginTop}>
              <Typography component="p">
                {maPrice ? `MA単価: ${numberWithCommas(maPrice)}円` : null}
              </Typography>
              <Typography component="p">
                {eedPrice ? `EED単価: ${numberWithCommas(eedPrice)}円` : null}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            <Typography component="p">
              {registerDate ? `登録日: ${convertIntoDateFormat(registerDate)}` : null}
            </Typography>
            <Typography className={classes.memo} component="p">
              {memo && `備考:\n${memo}`}
            </Typography>
            <div className={classes.chips}>
              {labels &&
                _.orderBy(labels, ['order']).map(({ id, name, color }) => (
                  <Chip
                    key={id}
                    label={name}
                    className={classes.chip}
                    color="primary"
                    style={{ backgroundColor: color }}
                  />
                ))}
            </div>
          </Grid>
        </Grid>
        <IconButton
          className={classes.button}
          onClick={() => {
            redirect(`/customers/${id}/edit`);
          }}
        >
          <EditIcon />
        </IconButton>
      </Paper>
    );
  }
}

export default withStyles(styles)(CustomerPanel);
