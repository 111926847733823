import React from 'react';

export const withChangeSubmit = () => Component => {
  class C extends React.Component {
    // SF = Search Field
    handleChangeSF = e => {
      const { handleChange, submitForm } = this.props;
      handleChange(e);
      submitForm();
    };

    handleBlurSF = e => {
      const { handleBlur, submitForm } = this.props;
      handleBlur(e);
      submitForm();
    };

    handleKeyPress = e => {
      const { submitForm } = this.props;
      const code = e.keyCode || e.which;
      if (code === 13) {
        submitForm();
      }
    };

    render() {
      return (
        <Component
          {...this.props}
          handleChangeSF={this.handleChangeSF}
          handleBlurSF={this.handleBlurSF}
          handleKeyPress={this.handleKeyPress}
        />
      );
    }
  }

  C.displayName = `withChangeSubmit(${Component.displayName || Component.name})`;

  return C;
};

export default withChangeSubmit;
