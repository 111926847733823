import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 4
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  table: {
    minWidth: 1020
  },
  tableFixed: {
    tableLayout: 'fixed'
  }
});

const wrapTablePagination = (options = {}) => Component => {
  const { fixed } = options;

  class C extends React.Component {
    handleRequestSort = (_, property) => {
      const { order: currentOrder, orderBy: currentOrderBy, updateSearchParams } = this.props;

      const orderBy = property;
      let order = 'desc';

      if (currentOrderBy === property && currentOrder === 'desc') {
        order = 'asc';
      }

      updateSearchParams({ order, orderBy });
    };

    handleChangePage = (_, page) => {
      const { updateSearchParams } = this.props;
      updateSearchParams({ page });
    };

    handleChangeRowsPerPage = e => {
      const { updateSearchParams } = this.props;
      const rowsPerPage = e.target.value;
      updateSearchParams({ rowsPerPage, page: undefined });
    };

    render() {
      const { classes, rowsPerPage, page, count, ...rest } = this.props;
      return (
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <Table
              className={classNames({ [classes.table]: true, [classes.tableFixed]: fixed })}
              aria-labelledby="tableTitle"
            >
              <Component handleRequestSort={this.handleRequestSort} {...rest} />
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
            component="div"
            count={count}
            rowsPerPage={+rowsPerPage}
            page={+page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      );
    }
  }

  C.displayName = `wrapTablePagination(${Component.displayName || Component.name})`;

  return withStyles(styles)(C);
};

export default wrapTablePagination;
