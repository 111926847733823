import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';

import { GET_MAIL_TEMPLATES } from 'gqls/mail';
import { redirect } from 'browser';
import wrapMenu from 'hocs/wrapMenu';

import MailTemplateTable from 'elements/MailTemplateTable';
import FloatingAddButton from 'elements/FloatingAddButton';

const styles = theme => ({
  main: {
    flex: '1 1 auto',
    paddingTop: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  }
});

class MailTemplateList extends React.Component {
  render() {
    const { classes, data } = this.props;

    if (data.loading) return null;

    const { mailTemplates = [] } = data;

    return (
      <main className={classes.main}>
        <MailTemplateTable mailTemplates={mailTemplates} />
        <FloatingAddButton
          handleClick={() => {
            redirect(`/mailTemplates/new`);
          }}
        />
      </main>
    );
  }
}

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: 'メールテンプレートマスタ' }),
  graphql(GET_MAIL_TEMPLATES, { options: { variables: { where: {}, orderBy: 'order_ASC' } } })
)(MailTemplateList);
