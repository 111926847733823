import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { newWindow } from 'browser';
import wrapTablePagination from 'hocs/wrapTablePagination';
import enums from 'enums';
import { convertIntoDateFormat } from 'utils/date';

import SortableTableHead from 'elements/SortableTableHead';

const columns = [
  {
    id: 'programNo',
    align: 'center',
    disablePadding: true,
    label: 'No.',
    sortable: false,
    width: 48
  },
  {
    id: 'registerDate',
    align: 'center',
    disablePadding: true,
    label: '登録日',
    sortable: true,
    width: 100
  },
  {
    id: 'customer',
    align: 'left',
    disablePadding: true,
    label: 'クライアント',
    sortable: false,
    width: 120
  },
  {
    id: 'personnel',
    align: 'left',
    disablePadding: true,
    label: '担当者',
    sortable: false,
    width: 80
  },
  {
    id: 'title',
    align: 'left',
    disablePadding: true,
    label: 'PROGRAM',
    sortable: true,
    width: 200
  },
  {
    id: 'subTitle',
    align: 'left',
    disablePadding: true,
    label: 'サブタイトル',
    sortable: true,
    width: 200
  },
  {
    id: 'reportStatus',
    align: 'center',
    disablePadding: true,
    label: 'ステータス',
    sortable: true,
    width: 80
  },
  {
    id: 'user',
    align: 'center',
    disablePadding: true,
    label: '管理者',
    sortable: false,
    width: 80
  }
];

const styles = theme => ({
  tableBodyCell: {
    paddingRight: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit / 2
  }
});

class HeadBody extends React.Component {
  handleClick = report => {
    newWindow(`/reports/${report.id}`);
  };

  render() {
    const { classes, order, orderBy, reports, handleRequestSort } = this.props;

    return (
      <React.Fragment>
        <SortableTableHead
          order={order}
          orderBy={orderBy}
          columns={columns}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {reports.map(report => (
            <TableRow hover onClick={() => this.handleClick(report)} tabIndex={-1} key={report.id}>
              <TableCell className={classes.tableBodyCell} align="left" component="th" scope="row">
                {report.program.programNo}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {convertIntoDateFormat(report.registerDate)}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {report.customer.name}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {report.personnel && report.personnel.name}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {report.title}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {report.subTitle}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {enums.REPORT_STATUS[report.reportStatus]}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {report.user.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </React.Fragment>
    );
  }
}
const EnhancedHeadBody = withStyles(styles)(HeadBody);

export default wrapTablePagination({ fixed: true })(EnhancedHeadBody);
