import React from 'react';
import { compose } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import { SEARCH_REPORT_EXPENSES } from 'gqls/program';
import { getPeriodRange, getYearMonthRange } from 'utils/period';
import { convertIntoDateFormat } from 'utils/date';
import enums from 'enums';
import withUbs from 'hocs/withUbs';
import { downloadCsv } from 'browser';
import wrapMenu from 'hocs/wrapMenu';

import SearchReportExpenseForm from 'forms/SearchReportExpenseForm';
import ReportExpenseTable from 'elements/ReportExpenseTable';

const styles = theme => ({
  main: {
    flex: '1 1 auto',
    minWidth: 0,
    paddingTop: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  }
});

const defaultSearchParams = {
  order: 'desc',
  orderBy: 'date',
  page: '0',
  rowsPerPage: '50'
};

class ReportExpenseList extends React.Component {
  formatExpenseFields = ([...expenses]) => {
    return expenses.map(expense => ({
      id: expense.id,
      reportId: expense.report.id,
      programNo: expense.report.program.programNo,
      date: convertIntoDateFormat(expense.date),
      title: expense.report.title,
      expenseName: expense.expense ? expense.expense.name : '',
      amount: expense.amount,
      unitPrice: expense.unitPrice,
      price: Math.floor(expense.amount * expense.unitPrice),
      user: expense.user ? expense.user.name : '',
      check: enums.CHECK[expense.check]
    }));
  };

  createHandleCopyClick = expenses => () => {
    const head = '"No.","日付","PROGRAM","内容","数量","単価","金額","作業者","チェック"';
    const csvContent =
      head +
      '\n' +
      expenses
        .map(expense => {
          delete expense.id;
          delete expense.reportId;
          return Object.values(expense)
            .map(v => {
              if (v) {
                return typeof v === 'string' ? `"${v.replace('"', '""')}"` : `"${v}"`;
              } else {
                return '""';
              }
            })
            .join(',');
        })
        .join('\n');

    downloadCsv('経費リスト.csv', csvContent);
  };

  render() {
    const { classes } = this.props;
    const { list, count, order, orderBy, rowsPerPage, page, updateSearchParams } = this.props;

    const expenses = this.formatExpenseFields(list);

    return (
      <main className={classes.main}>
        <SearchReportExpenseForm
          statusEnum={enums.REPORT_STATUS}
          updateSearchParams={updateSearchParams}
          onCopyClick={this.createHandleCopyClick(expenses)}
        />
        <ReportExpenseTable
          order={order}
          orderBy={orderBy}
          rowsPerPage={rowsPerPage}
          page={page}
          count={count}
          expenses={expenses}
          updateSearchParams={updateSearchParams}
        />
      </main>
    );
  }
}

const convert = ({ params }) => {
  const searchParams = {};
  searchParams['AND'] = [];

  Object.entries(params).forEach(([key, value]) => {
    switch (key) {
      case 'period':
        const periodRange = getPeriodRange(+value);
        searchParams['AND'].push({ date_gte: periodRange.begin });
        searchParams['AND'].push({ date_lte: periodRange.end });
        break;
      case 'yearMonth':
        const yearMonthRange = getYearMonthRange(value);
        searchParams['AND'].push({ date_gte: yearMonthRange.begin });
        searchParams['AND'].push({ date_lte: yearMonthRange.end });
        break;
      case 'title':
        const titleParams = [];
        titleParams.push({ title_contains: value });
        titleParams.push({ subTitle_contains: value });
        searchParams['AND'].push({ report: { OR: titleParams } });
        break;
      case 'programNo':
        searchParams['AND'].push({ report: { program: { programNo: +value } } });
        break;
      case 'expenseRelatedId':
        searchParams['AND'].push({ expense: { id: value } });
        break;
      case 'userRelatedId':
        searchParams['AND'].push({ user: { id: value } });
        break;
      default:
        throw new Error();
    }
  });

  return searchParams;
};

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: '経費リスト' }),
  withUbs({
    defaultSearchParams,
    gql: SEARCH_REPORT_EXPENSES,
    convertGraphqlParams: convert,
    takeResult: ({ data }) => {
      const {
        searchReportExpenses: { reportExpenses, count }
      } = data;
      return {
        list: reportExpenses,
        count: count
      };
    }
  })
)(ReportExpenseList);
