import React from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo, compose } from 'react-apollo';
import { isEqual } from 'lodash';

import { takeMergedSearchParams, newHashHistory, convertPaginationParams } from 'utils/search';

const withUbs = ({
  defaultSearchParams,
  gql,
  convertGraphqlParams,
  takeResult,
  useWhere = true
}) => Component => {
  class C extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        list: [],
        count: 0,
        searchParams: takeMergedSearchParams(defaultSearchParams, this.props.location)
      };
    }

    static getDerivedStateFromProps(props, currentState) {
      const { searchParams: currentSearchParams } = currentState;

      const searchParams = takeMergedSearchParams(defaultSearchParams, props.location);
      if (!isEqual(searchParams, currentSearchParams)) {
        return { searchParams };
      } else {
        return null;
      }
    }

    componentDidMount() {
      this.doSearch(this.state.searchParams);
    }

    componentDidUpdate(_, prevState) {
      const { searchParams: prevSearchParams } = prevState;
      const { searchParams } = this.state;

      if (!isEqual(searchParams, prevSearchParams)) {
        this.doSearch(searchParams);
      }
    }

    updateSearchParams = params => {
      const { location, history } = this.props;
      history.replace(newHashHistory(params, location));
    };

    doSearch = searchParams => {
      console.log('%cQuery', 'color: green; font-weight: bold;');
      const { client } = this.props;
      const { order, orderBy, rowsPerPage, page, ...rest } = searchParams;

      let variables;
      if (useWhere) {
        variables = {
          ...convertPaginationParams({ order, orderBy, rowsPerPage, page }),
          where: convertGraphqlParams({ props: this.props, params: rest })
        };
      } else {
        variables = {
          ...convertGraphqlParams({ props: this.props, params: rest })
        };
      }

      console.log(variables);

      client
        .query({
          query: gql,
          variables
        })
        .then(response => {
          const { list, count } = takeResult(response);
          this.setState({ list, count });
        })
        .catch(() => {})
        .finally(() => {});
    };

    render() {
      const {
        list,
        count,
        loading,
        searchParams: { order, orderBy, rowsPerPage, page }
      } = this.state;

      return (
        <Component
          list={list}
          count={count}
          loading={loading}
          order={order}
          orderBy={orderBy}
          rowsPerPage={rowsPerPage}
          page={page}
          updateSearchParams={this.updateSearchParams}
          {...this.props}
        />
      );
    }
  }

  C.displayName = `withUbs(${Component.displayName || Component.name})`;

  return compose(
    withRouter,
    withApollo
  )(C);
};

export default withUbs;
