import gql from 'graphql-tag';

import { ExpenseFieldsFragment } from './fragments';

export const EXPENSE_LIST = gql`
  query Expenses {
    expenses(orderBy: order_ASC) {
      ...ExpenseFields
    }
  }
  ${ExpenseFieldsFragment}
`;

export const ADD_EXPENSE = gql`
  mutation AddExpense($data: ExpenseCreateInput!) {
    addExpense(data: $data) {
      ...ExpenseFields
    }
  }
  ${ExpenseFieldsFragment}
`;

export const EDIT_EXPENSE = gql`
  mutation EditExpense($data: ExpenseUpdateInput!, $where: ExpenseWhereUniqueInput!) {
    editExpense(data: $data, where: $where) {
      ...ExpenseFields
    }
  }
  ${ExpenseFieldsFragment}
`;

export const REMOVE_EXPENSE = gql`
  mutation RemoveExpense($where: ExpenseWhereUniqueInput!) {
    removeExpense(where: $where) {
      ...ExpenseFields
    }
  }
  ${ExpenseFieldsFragment}
`;

export const ARRANGE_EXPENSES = gql`
  mutation ArrangeExpenses($list: [ArrangeInput!]!) {
    arrangeExpenses(list: $list) {
      ...ExpenseFields
    }
  }
  ${ExpenseFieldsFragment}
`;
