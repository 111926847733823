import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';
import * as yup from 'yup';

import { NEW_CUSTOMER } from 'gqls/customer';
import { redirect } from 'browser';
import { convertIntoNewCustomerForm } from 'formConvertors/customer';
import wrapMenu from 'hocs/wrapMenu';

import CustomerForm from 'forms/CustomerForm';

const styles = theme => ({
  main: {
    width: 800,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

class NewCustomer extends React.Component {
  handleSucceed = ({ data }) => {
    const {
      addCustomer: { id }
    } = data;
    redirect(`/customers/${id}/personnels`);
  };

  render() {
    const { classes, mutate } = this.props;
    const initialValues = convertIntoNewCustomerForm();

    return (
      <main className={classes.main}>
        <CustomerForm
          action="NewCustomer"
          schema={schema}
          initialValues={initialValues}
          mutate={mutate}
          onSucceed={this.handleSucceed}
        />
      </main>
    );
  }
}

const schema = yup.object().shape({
  name: yup.string().required(),
  nameKana: yup.string().required(),
  customerType: yup.string().required(),
  postalCode: yup.string(),
  prefecture: yup.string(),
  address: yup.string(),
  email: yup.string().email(),
  tel: yup.string(),
  paymentClosingDay: yup.number(),
  paymentType: yup.string(),
  paymentMonthLater: yup.number(),
  paymentDay: yup.number(),
  maPrice: yup.number(),
  eedPrice: yup.number(),
  labelsRelatedIds: yup.array().of(yup.string()),
  memo: yup.string(),
  registerDate: yup.date().required(),
  isFreezed: yup.bool().required()
});

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: '顧客情報' }),
  graphql(NEW_CUSTOMER)
)(NewCustomer);
