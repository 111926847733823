import React from 'react';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
  root: {
    width: 600
  },
  dropzone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    borderWidth: 2,
    borderColor: '#666',
    borderStyle: 'dashed',
    borderRadius: 5
  },
  list: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 4
  },
  iconButton: {
    padding: 4
  }
});

class SelectFiles extends React.Component {
  handleDrop = (acceptedFiles, rejectedFiles) => {
    const { onDrop } = this.props;
    onDrop(acceptedFiles, rejectedFiles);
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  createHandleDelete = index => () => {
    const { onDelete } = this.props;
    onDelete(index);
  };

  render() {
    const { classes, files } = this.props;
    return (
      <div className={classes.root}>
        <Dropzone maxSize={1048576} onDrop={this.handleDrop} onFileDialogCancel={this.handleCancel}>
          {({ getRootProps, getInputProps }) => (
            <div className={classes.dropzone} {...getRootProps()}>
              <input {...getInputProps()} />
              <Typography>添付ファイルを追加してください。（1ファイルあたり1MBまで）</Typography>
            </div>
          )}
        </Dropzone>
        <List className={classes.list} dense>
          {files.map((file, index) => (
            <ListItem key={index}>
              <ListItemText primary={file.name} />
              <ListItemSecondaryAction>
                <IconButton
                  className={classes.iconButton}
                  aria-label="Delete"
                  onClick={this.createHandleDelete(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(SelectFiles);
