import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, BrowserRouter, withRouter } from 'react-router-dom';

import { ApolloProvider, withApollo, compose } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/pink';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToastContainer } from 'react-toastify';

import { MYSELF } from 'gqls/user';
import { UserContext } from 'contexts';
import { notifyError } from 'notifications';
import { fetchToken, logout } from 'browser';
import constants from 'myConstants';
import * as serviceWorker from './serviceWorker';

import DashboardScreen from 'screens/DashboardScreen';

import LoginScreen from 'screens/LoginScreen';

import StaffListScreen from 'screens/StaffListScreen';
import SignUpScreen from 'screens/SignUpScreen';
import EditMyselfScreen from 'screens/EditMyselfScreen';
import EditUserScreen from 'screens/EditUserScreen';
import EditPasswordScreen from 'screens/EditPasswordScreen';

import CustomerListScreen from 'screens/CustomerListScreen';
import NewCustomerScreen from 'screens/NewCustomerScreen';
import EditCustomerScreen from 'screens/EditCustomerScreen';
import PersonnelListScreen from 'screens/PersonnelListScreen';
import NewPersonnelScreen from 'screens/NewPersonnelScreen';
import EditPersonnelScreen from 'screens/EditPersonnelScreen';

import ReportListScreen from 'screens/ReportListScreen';
import NewReportScreen from 'screens/NewReportScreen';
import EditReportScreen from 'screens/EditReportScreen';

import VoucherListScreen from 'screens/VoucherListScreen';
import ProvisionalVoucherListScreen from 'screens/ProvisionalVoucherListScreen';
import NewVoucherScreen from 'screens/NewVoucherScreen';
import EditVoucherScreen from 'screens/EditVoucherScreen';
import SendMailScreen from 'screens/SendMailScreen';

import SettingScreen from 'screens/SettingScreen';
import MediaListScreen from 'screens/MediaListScreen';
import LabelListScreen from 'screens/LabelListScreen';
import OperationListScreen from 'screens/OperationListScreen';
import ExpenseListScreen from 'screens/ExpenseListScreen';
import SectionListScreen from 'screens/SectionListScreen';
import MailTemplateListScreen from 'screens/MailTemplateListScreen';
import NewMailTemplateScreen from 'screens/NewMailTemplateScreen';
import EditMailTemplateScreen from 'screens/EditMailTemplateScreen';

import PdfListScreen from 'screens/PdfListScreen';
import ShowPdfScreen from 'screens/ShowPdfScreen';

import ReportOperationListScreen from 'screens/ReportOperationListScreen';
import ReportMediaListScreen from 'screens/ReportMediaListScreen';
import ReportExpenseListScreen from 'screens/ReportExpenseListScreen';

import VoucherOperationListScreen from 'screens/VoucherOperationListScreen';

import StatisticsScreen from 'screens/StatisticsScreen';

import ResetPasswordStep1Screen from 'screens/ResetPasswordStep1Screen';
import ResetPasswordStep2Screen from 'screens/ResetPasswordStep2Screen';

import 'react-toastify/dist/ReactToastify.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.css';

const guestPathnames = ['/', '/signUp', '/resetPasswordStep1', '/resetPasswordStep2'];

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
          if (message === 'Not authenticated' || message === 'jwt malformed') {
            logout();
          }
        });
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        notifyError(constants.MESSAGE.NETWORK_ERROR);
      }
    }),
    setContext((_, { headers }) => {
      const token = fetchToken();
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : ''
        }
      };
    }),
    new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URI
    })
  ]),
  cache: new InMemoryCache()
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: blue,
    secondary: pink
  }
});

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: undefined
    };
  }

  componentDidMount() {
    const { client, location } = this.props;
    if (!guestPathnames.includes(location.pathname)) {
      client
        .query({ query: MYSELF })
        .then(({ data }) => {
          const { myself } = data;
          this.setState({ user: myself });
        })
        .catch(() => {});
    }
  }

  render() {
    const { location } = this.props;
    if (!guestPathnames.includes(location.pathname) && !this.state.user) {
      return null;
    }

    return (
      <UserContext.Provider value={this.state.user}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Switch>
            <Route exact path="/" component={LoginScreen} />
            <Route exact path="/signUp" component={SignUpScreen} />
            <Route exact path="/resetPasswordStep1" component={ResetPasswordStep1Screen} />
            <Route exact path="/resetPasswordStep2" component={ResetPasswordStep2Screen} />

            <Route exact path="/dashboard" component={DashboardScreen} />
            <Route exact path="/myself" component={EditMyselfScreen} />
            <Route exact path="/password" component={EditPasswordScreen} />
            <Route exact path="/users" component={StaffListScreen} />
            <Route exact path="/users/:userId" component={EditUserScreen} />
            <Route exact path="/customers" component={CustomerListScreen} />
            <Route exact path="/customers/new" component={NewCustomerScreen} />
            <Route exact path="/customers/:customerId/edit" component={EditCustomerScreen} />
            <Route exact path="/customers/:customerId/personnels" component={PersonnelListScreen} />
            <Route
              exact
              path="/customers/:customerId/personnels/new"
              component={NewPersonnelScreen}
            />
            <Route
              exact
              path="/customers/:customerId/personnels/:personnelId/edit"
              component={EditPersonnelScreen}
            />
            <Route exact path="/reports" component={ReportListScreen} />
            <Route exact path="/reports/new" component={NewReportScreen} />
            <Route exact path="/reports/:reportId" component={EditReportScreen} />
            <Route exact path="/vouchers" component={VoucherListScreen} />
            <Route exact path="/provisionalVouchers" component={ProvisionalVoucherListScreen} />
            <Route exact path="/vouchers/new" component={NewVoucherScreen} />
            <Route exact path="/vouchers/:voucherId" component={EditVoucherScreen} />
            <Route exact path="/vouchers/:voucherId/sendMail" component={SendMailScreen} />
            <Route exact path="/settings" component={SettingScreen} />
            <Route exact path="/medias" component={MediaListScreen} />
            <Route exact path="/labels" component={LabelListScreen} />
            <Route exact path="/operations" component={OperationListScreen} />
            <Route exact path="/expenses" component={ExpenseListScreen} />
            <Route exact path="/sections" component={SectionListScreen} />
            <Route exact path="/mailTemplates" component={MailTemplateListScreen} />
            <Route exact path="/mailTemplates/new" component={NewMailTemplateScreen} />
            <Route exact path="/mailTemplates/:mailTemplateId" component={EditMailTemplateScreen} />
            <Route exact path="/pdfs" component={PdfListScreen} />
            <Route exact path="/pdfs/:pdfId" component={ShowPdfScreen} />
            <Route exact path="/reportOperations" component={ReportOperationListScreen} />
            <Route exact path="/reportMedias" component={ReportMediaListScreen} />
            <Route exact path="/reportExpenses" component={ReportExpenseListScreen} />
            <Route exact path="/voucherOperations" component={VoucherOperationListScreen} />
            <Route path="/statistics/:tab" component={StatisticsScreen} />
          </Switch>
          <ToastContainer />
        </MuiThemeProvider>
      </UserContext.Provider>
    );
  }
}

const EnhancedPage = compose(
  withRouter,
  withApollo
)(Page);

const App = () => (
  <BrowserRouter>
    <ApolloProvider client={client}>
      <EnhancedPage />
    </ApolloProvider>
  </BrowserRouter>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
