import React from 'react';
import { compose } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import { GET_CUSTOMER_EARNINGS } from 'gqls/statistics';
import withUbs from 'hocs/withUbs';

import SearchRankingForm from 'forms/SearchRankingForm';
import RankingTable from 'elements/RankingTable';

const styles = theme => ({
  root: {}
});

const defaultSearchParams = {
  order: 'desc',
  orderBy: 'date',
  page: '0',
  rowsPerPage: '50'
};

class Rankings extends React.Component {
  render() {
    const { classes } = this.props;
    const { list, updateSearchParams } = this.props;

    return (
      <div className={classes.root}>
        <SearchRankingForm updateSearchParams={updateSearchParams} />
        <RankingTable values={list} />
      </div>
    );
  }
}

const convert = ({ params }) => {
  const searchParams = {};

  Object.entries(params).forEach(([key, value]) => {
    switch (key) {
      case 'period':
        searchParams['term'] = +value;
        break;
      case 'yearMonth':
        searchParams['month'] = +value.slice(5, 7);
        break;
      default:
        throw new Error();
    }
  });

  return searchParams;
};

export default compose(
  withStyles(styles),
  withUbs({
    defaultSearchParams,
    gql: GET_CUSTOMER_EARNINGS,
    convertGraphqlParams: convert,
    takeResult: ({ data }) => {
      const { getCustomerEarnings } = data;
      return {
        list: getCustomerEarnings,
        count: 0
      };
    },
    useWhere: false
  })
)(Rankings);
