import React from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { compose } from 'react-apollo';
import { keys } from 'lodash';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import withChangeSubmit from 'hocs/withChangeSubmit';
import withReactSelectHandler from 'hocs/withReactSelectHandler';
import { takeSpecificKeys } from 'utils/util';
import { takeSearchParams } from 'utils/search';
import enums from 'enums';

import MyTextField from 'elements/MyTextField';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit
  },
  form: {
    marginTop: theme.spacing.unit
  }
});

class SearchPdfForm extends React.Component {
  handleCustomerChange = value => {
    const { handleCustomerChange, submitForm } = this.props;
    handleCustomerChange(value);
    submitForm();
  };

  render() {
    const { classes, values, errors, touched, handleChange, handleBlur, handleSubmit } = this.props;
    const { handleChangeSF, handleBlurSF, handleKeyPress } = this.props;

    return (
      <Paper className={classes.paper}>
        <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={8}>
            <Grid item xs={2}>
              <MyTextField
                name="pdfType"
                values={values}
                touched={touched}
                errors={errors}
                label="種類"
                margin="none"
                onChange={handleChangeSF}
                onBlur={handleBlur}
                fullWidth
                select
              >
                <MenuItem key="" value="" />
                {Object.entries(enums.PDF_TYPE).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                name="customer"
                values={values}
                touched={touched}
                errors={errors}
                label="顧客"
                margin="none"
                onChange={handleChange}
                onBlur={handleBlurSF}
                onKeyPress={handleKeyPress}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                name="title"
                values={values}
                touched={touched}
                errors={errors}
                label="PROGRAM"
                margin="none"
                onChange={handleChange}
                onBlur={handleBlurSF}
                onKeyPress={handleKeyPress}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </form>
      </Paper>
    );
  }
}

const initialValues = {
  pdfType: '',
  title: '',
  customer: ''
};

const schema = yup.object().shape({
  pdfType: yup.string(),
  title: yup.string(),
  customer: yup.string()
});

export default compose(
  withStyles(styles),
  withRouter,
  withFormik({
    mapPropsToValues: props => {
      const { location } = props;
      const params = takeSearchParams(location);
      return Object.assign({}, initialValues, takeSpecificKeys(params, keys(initialValues)));
    },
    validationSchema: schema,
    handleSubmit: (values, { props, setSubmitting }) => {
      const { updateSearchParams } = props;
      setSubmitting(false);
      updateSearchParams(values);
    }
  }),
  withChangeSubmit(),
  withReactSelectHandler()
)(SearchPdfForm);
