import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'react-apollo';

import { SEARCH_USERS } from 'gqls/user';
import withUbs from 'hocs/withUbs';
import wrapMenu from 'hocs/wrapMenu';

import SearchUserForm from 'forms/SearchUserForm';
import UserTable from 'elements/UserTable';

const styles = theme => ({
  main: {
    flex: '1 1 auto',
    paddingTop: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  }
});

const defaultSearchParams = {
  order: 'asc',
  orderBy: 'name',
  page: '0',
  rowsPerPage: '50'
};

class StaffList extends React.Component {
  render() {
    const { classes } = this.props;
    const { list, count, order, orderBy, rowsPerPage, page, updateSearchParams } = this.props;

    return (
      <main className={classes.main}>
        <SearchUserForm updateSearchParams={updateSearchParams} />
        <UserTable
          order={order}
          orderBy={orderBy}
          rowsPerPage={rowsPerPage}
          page={page}
          count={count}
          users={list}
          updateSearchParams={updateSearchParams}
        />
      </main>
    );
  }
}

const convert = ({ params }) => {
  const searchParams = {};
  searchParams['AND'] = [];

  Object.entries(params).forEach(([key, value]) => {
    switch (key) {
      case 'name':
        const nameParams = [];
        nameParams.push({ name_contains: value });
        nameParams.push({ nameKana_contains: value });
        searchParams['AND'].push({ OR: nameParams });
        break;
      case 'isRetired':
        searchParams['AND'].push({ isRetired: value === 'true' });
        break;
      default:
        throw new Error();
    }
  });

  return searchParams;
};

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: '従業員リスト' }),
  withUbs({
    defaultSearchParams,
    gql: SEARCH_USERS,
    convertGraphqlParams: convert,
    takeResult: ({ data }) => {
      const {
        searchUsers: { users, count }
      } = data;
      return {
        list: users,
        count: count
      };
    }
  })
)(StaffList);
