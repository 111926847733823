import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

import { takeSpecificKeys } from 'utils/util';
import { reorderList } from 'utils/form';

const styles = theme => ({
  root: {},
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  content: {
    marginTop: theme.spacing.unit
  },
  draggable: {
    '&:not(:first-child)': {
      marginTop: theme.spacing.unit * 2
    }
  }
});

const wrapDragDropList = ({
  label,
  defaultValues,
  convertIntoForm,
  convertIntoGql,
  handleAddName,
  handleEditName,
  handleRemoveName,
  handleArrangeName,
  withHeadDesc = true
}) => Component => {
  class C extends React.Component {
    constructor(props) {
      super(props);
      this.state = { list: props.list };
    }

    handleAddIconClick = () => {
      const { [handleAddName]: handleAdd } = this.props;

      handleAdd({
        variables: {
          data: convertIntoGql(defaultValues)
        }
      })
        .then(({ data }) => {
          const { [handleAddName]: newList } = data;
          this.setList(newList);
        })
        .catch(() => {});
    };

    handleDragEnd = result => {
      const { [handleArrangeName]: handleArrange } = this.props;

      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const newList = reorderList(this.state.list, result.source.index, result.destination.index);

      this.setList(newList);

      handleArrange({
        variables: {
          list: newList.map((item, index) =>
            takeSpecificKeys({ ...item, order: index + 1 }, ['id', 'order', 'lockVersion'])
          )
        }
      })
        .then(({ data }) => {
          const { [handleArrangeName]: newList } = data;
          this.setList(newList);
        })
        .catch(() => {});
    };

    setList = newList => {
      this.setState({
        list: newList
      });
    };

    render() {
      const { classes } = this.props;
      const { [handleEditName]: handleEdit, [handleRemoveName]: handleRemove } = this.props;
      const { list } = this.state;
      return (
        <div className={classes.root}>
          <div className={classes.head}>
            <Typography component="h2" variant="subtitle1">
              {withHeadDesc ? `${label}の追加・変更・削除・移動が行えます。` : label}
            </Typography>
            <Fab size="small" color="primary" aria-label="Add" onClick={this.handleAddIconClick}>
              <AddIcon />
            </Fab>
          </div>
          <div className={classes.content}>
            <DragDropContext onDragEnd={this.handleDragEnd}>
              <Droppable droppableId="droppable">
                {provided => (
                  <div ref={provided.innerRef}>
                    {list.map((values, index) => (
                      <Draggable key={values.id} draggableId={values.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classes.draggable}
                          >
                            <Component
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              snapshot={snapshot}
                              values={values}
                              convertIntoForm={convertIntoForm}
                              convertIntoGql={convertIntoGql}
                              setList={this.setList}
                              handleEditName={handleEditName}
                              handleEdit={handleEdit}
                              handleRemoveName={handleRemoveName}
                              handleRemove={handleRemove}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      );
    }
  }

  C.displayName = `wrapDragDropList(${Component.displayName || Component.name})`;

  return withStyles(styles)(C);
};

export default wrapDragDropList;
