import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';
import * as yup from 'yup';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { UserContext } from 'contexts';
import { GET_SEND_MAIL_FORM_OPTIONS } from 'gqls/mail';
import { convertIntoMailForm } from 'formConvertors/mail';
import { redirect } from 'browser';
import wrapMenu from 'hocs/wrapMenu';

import JumpPageWrapper from 'elements/JumpPageWrapper';
import SendMailForm from 'forms/SendMailForm';

const styles = theme => ({
  main: {
    flex: '1 1 auto',
    paddingTop: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  }
});

class SendMail extends React.Component {
  createHandleClick = voucher => () => {
    redirect(`/vouchers/${voucher.id}`);
  };

  render() {
    const { classes, data, mutate } = this.props;

    if (data.loading) return null;

    const linkText = '<< 作業伝票へ';

    return (
      <UserContext.Consumer>
        {value => {
          const initialValues = convertIntoMailForm(data.fetchVoucher, value);

          return (
            <main className={classes.main}>
              <JumpPageWrapper
                prev={
                  <Button
                    size="small"
                    color="primary"
                    onClick={this.createHandleClick(data.fetchVoucher)}
                  >
                    {linkText}
                  </Button>
                }
              />
              <Typography component="h2" variant="h5">
                {data.fetchVoucher.customer.name} - {data.fetchVoucher.title}
              </Typography>
              <SendMailForm
                schema={schema}
                initialValues={initialValues}
                voucher={data.fetchVoucher}
                otherVouchers={data.getBillableVouchers}
                personnels={data.fetchVoucher.customer.personnels}
                mailTemplates={data.mailTemplates}
                user={value}
                mutate={mutate}
              />
            </main>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

const emailsValidationTest = value => {
  if (!value || value === '') return true;

  const emails = value.split(',');

  const schema = yup
    .string()
    .email()
    .required();

  return emails.every(email => schema.isValidSync(email));
};

const schema = yup.object().shape({
  mailTemplate: yup.string().required(),
  personnel: yup.string().required(),
  from: yup.string().required(),
  tos: yup
    .string()
    .test('emails', '続けてカンマで区切ってください', emailsValidationTest)
    .required(),
  ccs: yup.string().test('emails', '続けてカンマで区切ってください', emailsValidationTest),
  bccs: yup.string().test('emails', '続けてカンマで区切ってください', emailsValidationTest),
  subject: yup.string().required(),
  mainWhere: yup.string().required(),
  allWhere: yup.array().of(yup.string().required()),
  pdfType: yup.string().required(),
  date: yup.string().required(),
  title: yup.string(),
  greeting: yup.string(),
  payment: yup.string(),
  companyStamp: yup.boolean()
});

export default compose(
  withStyles(styles),
  wrapMenu({
    appBarText: 'メール',
    colorOrange: true
  }),
  graphql(GET_SEND_MAIL_FORM_OPTIONS, {
    options: props => {
      const urlParams = new URLSearchParams(props.location.search);
      return {
        variables: {
          voucherWhere: { id: props.match.params.voucherId },
          customerWhere: { id: urlParams.get('customerId') }
        }
      };
    }
  })
)(SendMail);
