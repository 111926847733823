import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { redirect } from 'browser';
import enums from 'enums';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  }
});

class MailTemplateTable extends React.Component {
  handleClick = mailTemplate => {
    redirect(`/mailTemplates/${mailTemplate.id}`);
  };

  render() {
    const { classes, mailTemplates } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>テンプレート名</TableCell>
              <TableCell>件名</TableCell>
              <TableCell>PDFタイプ</TableCell>
              <TableCell>並び順</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mailTemplates.map(mailTemplate => (
              <TableRow
                hover
                onClick={() => this.handleClick(mailTemplate)}
                tabIndex={-1}
                key={mailTemplate.id}
              >
                <TableCell component="th" scope="row">
                  {mailTemplate.name}
                </TableCell>
                <TableCell>{mailTemplate.subject}</TableCell>
                <TableCell>{enums.PDF_TYPE[mailTemplate.pdfType]}</TableCell>
                <TableCell>{mailTemplate.order}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default withStyles(styles)(MailTemplateTable);
