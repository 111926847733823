import React from 'react';
import { compose, graphql } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

import { GET_INTEGRATABLE_VOUCHERS } from 'gqls/program';
import { convertIntoDateFormat } from 'utils/date';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const styles = theme => ({
  form: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    marginTop: theme.spacing.unit
  },
  formControl: {
    flex: '1 1'
  },
  buttons: {
    marginLeft: theme.spacing.unit,
    [`& button:not(:first-child)`]: {
      marginLeft: theme.spacing.unit
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing.unit / 4
  }
});

class SelectIntegratableVouchersForm extends React.Component {
  state = {
    selectedVouchers: []
  };

  handleCreateBillClick = e => {
    const { onCreateBillClick } = this.props;
    onCreateBillClick(this.state.selectedVouchers.map(voucher => voucher.id));
  };

  handleIntegrateClick = e => {
    const { onIntegrateClick } = this.props;
    onIntegrateClick(this.state.selectedVouchers.map(voucher => voucher.id));
  };

  handleChange = e => {
    this.setState({ selectedVouchers: e.target.value });
  };

  render() {
    const { classes, data } = this.props;

    if (data.loading) return null;

    return (
      <form className={classes.form} onSubmit={this.handleSubmit}>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel htmlFor="select-vouchers">統合対象の作業伝票</InputLabel>
          <Select
            multiple
            value={this.state.selectedVouchers}
            onChange={this.handleChange}
            input={<Input id="select-vouchers" />}
            renderValue={selectedVouchers => (
              <div className={classes.chips}>
                {selectedVouchers.map(({ id, title }) => (
                  <Chip key={id} label={title} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {data.getIntegratableVouchers.map(voucher => (
              <MenuItem key={voucher.id} value={voucher}>
                {`${convertIntoDateFormat(voucher.registerDate)} ${voucher.title} (No.${
                  voucher.program.programNo
                })`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={classes.buttons}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            disabled={this.state.selectedVouchers.length === 0}
            onClick={this.handleIntegrateClick}
          >
            統合
          </Button>
        </div>
      </form>
    );
  }
}

export default compose(
  withStyles(styles),
  graphql(GET_INTEGRATABLE_VOUCHERS, {
    options: props => ({
      variables: {
        where: { id: props.customer.id }
      }
    })
  })
)(SelectIntegratableVouchersForm);
