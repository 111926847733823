import React from 'react';
import { compose, graphql } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  SECTION_LIST,
  ADD_SECTION,
  EDIT_SECTION,
  REMOVE_SECTION,
  ARRANGE_SECTIONS
} from 'gqls/section';
import wrapDragDropList from 'hocs/wrapDragDropList';
import wrapMenu from 'hocs/wrapMenu';

import SectionForm from 'forms/SectionForm';

const DragDropList = compose(
  graphql(ADD_SECTION, { name: 'addSection' }),
  graphql(EDIT_SECTION, { name: 'editSection' }),
  graphql(REMOVE_SECTION, { name: 'removeSection' }),
  graphql(ARRANGE_SECTIONS, { name: 'arrangeSections' }),
  wrapDragDropList({
    label: '部署',
    defaultValues: {
      name: ''
    },
    convertIntoForm: values => values,
    convertIntoGql: ({ ...values }) => {
      return values;
    },
    handleAddName: 'addSection',
    handleEditName: 'editSection',
    handleRemoveName: 'removeSection',
    handleArrangeName: 'arrangeSections'
  })
)(SectionForm);

const styles = theme => ({
  main: {
    width: 600,
    marginTop: theme.spacing.unit * 8,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 10,
    right: theme.spacing.unit * 10
  }
});

class SectionList extends React.Component {
  render() {
    const { classes, data } = this.props;

    if (data.loading) return null;

    return (
      <main className={classes.main}>
        <DragDropList list={data.sections} />
      </main>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  wrapMenu({ appBarText: '部署マスタ' }),
  graphql(SECTION_LIST)
)(SectionList);
