import React from 'react';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import wrapTablePagination from 'hocs/wrapTablePagination';
import { newWindow } from 'browser';

import SortableTableHead from 'elements/SortableTableHead';

const columns = [
  {
    id: 'programNo',
    align: 'center',
    disablePadding: true,
    label: 'No.',
    sortable: false,
    width: 48
  },
  { id: 'date', align: 'center', disablePadding: true, label: '日付', sortable: true, width: 80 },
  {
    id: 'title',
    align: 'left',
    disablePadding: true,
    label: 'PROGRAM',
    sortable: false,
    width: 200
  },
  {
    id: 'expenseRelatedId',
    align: 'center',
    disablePadding: true,
    label: '内容',
    sortable: false,
    width: 120
  },
  {
    id: 'amount',
    align: 'center',
    disablePadding: true,
    label: '数量',
    sortable: false,
    width: 80
  },
  {
    id: 'unitPrice',
    align: 'center',
    disablePadding: true,
    label: '単価',
    sortable: false,
    width: 80
  },
  {
    id: 'price',
    align: 'center',
    disablePadding: true,
    label: '金額',
    sortable: false,
    width: 80
  },
  {
    id: 'user',
    align: 'center',
    disablePadding: true,
    label: '作業者',
    sortable: false,
    width: 120
  },
  {
    id: 'check',
    align: 'center',
    disablePadding: true,
    label: 'チェック',
    sortable: true,
    width: 80
  }
];

const styles = theme => ({
  tableBodyCell: {
    paddingRight: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit / 2
  }
});

class HeadBody extends React.Component {
  createHandleClick = expense => () => {
    newWindow(`/reports/${expense.reportId}`);
  };

  render() {
    const { classes, order, orderBy, expenses, handleRequestSort } = this.props;

    return (
      <React.Fragment>
        <SortableTableHead
          order={order}
          orderBy={orderBy}
          columns={columns}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {expenses.map(expense => (
            <TableRow
              hover
              tabIndex={-1}
              key={expense.id}
              onClick={this.createHandleClick(expense)}
            >
              <TableCell className={classes.tableBodyCell} align="left" component="th" scope="row">
                {expense.programNo}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {expense.date}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {expense.title}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {expense.expenseName}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {expense.amount}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {expense.unitPrice}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {expense.price}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {expense.user}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {expense.check}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </React.Fragment>
    );
  }
}
const EnhancedHeadBody = withStyles(styles)(HeadBody);

export default compose(wrapTablePagination({ fixed: true }))(EnhancedHeadBody);
