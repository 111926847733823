import React from 'react';

import { withApollo } from 'react-apollo';

import { FETCH_CUSTOMER } from 'gqls/customer';
import { takeSearchParams } from 'utils/search';

export const withAutoFetchCustomer = () => Component => {
  class C extends React.Component {
    state = {
      uriCustomer: undefined
    };

    componentDidMount() {
      const { client, location, setPersonnels } = this.props;

      const params = takeSearchParams(location);
      if (params['customerRelatedId']) {
        const variables = {
          where: { id: params['customerRelatedId'] }
        };
        client
          .query({
            query: FETCH_CUSTOMER,
            variables
          })
          .then(({ data }) => {
            const { fetchCustomer } = data;

            this.setState({ uriCustomer: fetchCustomer });

            if (setPersonnels) {
              setPersonnels(fetchCustomer.personnels);
            }
          })
          .catch(() => {});
      }
    }

    render() {
      return <Component {...this.props} uriCustomer={this.state.uriCustomer} />;
    }
  }

  C.displayName = `withAutoFetchCustomer(${Component.displayName || Component.name})`;

  return withApollo(C);
};

export default withAutoFetchCustomer;
