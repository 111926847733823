import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import TableRow from '@material-ui/core/TableRow';

import { redirect } from 'browser';
import wrapTablePagination from 'hocs/wrapTablePagination';
import { convertIntoDateFormat } from 'utils/date';
import enums from 'enums';

import SortableTableHead from 'elements/SortableTableHead';

const columns = [
  { id: 'name', align: 'left', disablePadding: false, label: '名前', sortable: true },
  { id: 'customerType', align: 'left', disablePadding: false, label: '顧客種別', sortable: true },
  { id: 'tel', align: 'left', disablePadding: false, label: 'TEL', sortable: false },
  { id: 'label', align: 'left', disablePadding: false, label: 'ラベル', sortable: false },
  { id: 'registerDate', align: 'left', disablePadding: false, label: '登録日', sortable: true }
];

class HeadBody extends React.Component {
  handleClick = customer => {
    redirect(`/customers/${customer.id}/personnels`);
  };

  render() {
    const { classes, order, orderBy, customers, handleRequestSort } = this.props;
    return (
      <React.Fragment>
        <SortableTableHead
          order={order}
          orderBy={orderBy}
          columns={columns}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {customers.map(customer => (
            <TableRow
              hover
              onClick={() => this.handleClick(customer)}
              tabIndex={-1}
              key={customer.id}
            >
              <TableCell component="th" scope="row">
                {customer.name}
              </TableCell>
              <TableCell>{enums.CUSTOMER_TYPE[customer.customerType]}</TableCell>
              <TableCell>{customer.tel}</TableCell>
              <TableCell>
                <div className={classes.chips}>
                  {_.orderBy(customer.labels, ['order']).map(({ id, name, color }) => (
                    <Chip
                      key={id}
                      color="primary"
                      label={name}
                      className={classes.chip}
                      style={{ backgroundColor: color }}
                    />
                  ))}
                </div>
              </TableCell>
              <TableCell>{convertIntoDateFormat(customer.registerDate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </React.Fragment>
    );
  }
}

const ConnectedHeadBody = withStyles(theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing.unit / 4
  }
}))(HeadBody);

export default wrapTablePagination()(ConnectedHeadBody);
