import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import { compose } from 'react-apollo';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';

import { UserContext } from 'contexts';
import { redirect, logout } from 'browser';
import constants from 'myConstants';

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: constants.VALUE.DRAWER_WIDTH,
    width: `calc(100% - ${constants.VALUE.DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  progress: {
    marginRight: theme.spacing.unit,
    color: '#fff'
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  grow: {
    flexGrow: 1
  },
  colorOrange: {
    backgroundColor: orange[500]
  }
});

class MyAppBar extends React.Component {
  state = {
    anchorEl: null
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, open, appBarText, progressState, colorOrange, handleDrawerOpen } = this.props;

    const { anchorEl } = this.state;
    const accountMenuOpen = Boolean(anchorEl);

    return (
      <UserContext.Consumer>
        {value => (
          <AppBar
            position="fixed"
            color="primary"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: open,
              [classes.colorOrange]: colorOrange
            })}
          >
            <Toolbar disableGutters={!open}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={handleDrawerOpen}
                className={classNames(classes.menuButton, {
                  [classes.hide]: open
                })}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                className={classes.grow}
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
              >
                {appBarText}
              </Typography>
              {progressState && (
                <CircularProgress className={classes.progress} variant="indeterminate" />
              )}
              <Typography variant="subtitle2" color="inherit" noWrap>
                {value.name}さん
              </Typography>
              <div>
                <IconButton
                  aria-owns={accountMenuOpen ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={accountMenuOpen}
                  onClose={this.handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      redirect('/myself');
                    }}
                  >
                    アカウント情報を変更
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      redirect('/password');
                    }}
                  >
                    パスワードを変更
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      logout();
                    }}
                  >
                    ログアウト
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
        )}
      </UserContext.Consumer>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter
)(MyAppBar);
