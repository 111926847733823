import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';
import * as yup from 'yup';

import { NEW_MAIL_TEMPLATE } from 'gqls/mail';
import { redirect } from 'browser';
import { mailTemplateInitialValues } from 'initialValues';
import wrapMenu from 'hocs/wrapMenu';

import MailTemplateForm from 'forms/MailTemplateForm';

const styles = theme => ({
  main: {
    width: 800,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

class NewMailTemplate extends React.Component {
  handleSucceed = () => {
    redirect(`/mailTemplates`);
  };

  render() {
    const { classes, mutate } = this.props;

    return (
      <main className={classes.main}>
        <MailTemplateForm
          action="NewMailTemplate"
          schema={schema}
          initialValues={mailTemplateInitialValues}
          mutate={mutate}
          onSucceed={this.handleSucceed}
        />
      </main>
    );
  }
}

const schema = yup.object().shape({
  name: yup.string().required(),
  subject: yup.string().required(),
  body: yup.string().required(),
  pdfType: yup.string(),
  title: yup.string(),
  greeting: yup.string(),
  payment: yup.string(),
  order: yup.number().required()
});

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: 'メールテンプレートマスタ' }),
  graphql(NEW_MAIL_TEMPLATE)
)(NewMailTemplate);
