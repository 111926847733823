import React from 'react';
import { FieldArray } from 'formik';
import { get } from 'lodash';
import { compose } from 'react-apollo';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import enums from 'enums';
import withOperationFormFieldRow from 'hocs/withOperationFormFieldRow';
import { voucherOperationInitialValues } from 'initialValues';

import MyTextField from 'elements/MyTextField';
import FieldsFormToolbar from 'elements/FieldsFormToolbar';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  table: {
    tableLayout: 'fixed'
  },
  tableHeadRow: {
    height: 48
  },
  tableHeadCell: {
    paddingRight: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit / 2
  },
  tableBodyCell: {
    paddingRight: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit / 2
  },
  hoursField: {
    display: 'flex',
    alignItems: 'center',
    '& input': {
      textAlign: 'center'
    }
  },
  divider: {
    margin: `0 ${theme.spacing.unit / 2}px`
  }
});

class FieldRow extends React.Component {
  render() {
    const {
      operationOptions,
      index,
      classes,
      values,
      errors,
      touched,
      handleChange,
      handleBlur
    } = this.props;
    const {
      model,
      handleRemove,
      handleAmountBlur,
      handleUnitPriceBlur,
      createHandleTimeFieldBlur,
      handleUnitLabelChange
    } = this.props;

    const rowTouched = touched.voucherOperations ? touched.voucherOperations[index] : {};
    const rowErrors = errors.voucherOperations ? errors.voucherOperations[index] : {};

    const isHour = get(values, `${model}.${index}.unitLabel`) === 'Hour';
    const isPoint = get(values, `${model}.${index}.unitLabel`) === 'Point';

    return (
      <TableRow>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.date`}
            type="date"
            values={values}
            touched={rowTouched}
            errors={rowErrors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            required
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.operationRelatedId`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            select
          >
            <MenuItem key="" value="" />
            {operationOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </MyTextField>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <div
            className={classes.hoursField}
            style={{
              visibility: isHour ? 'visible' : 'hidden'
            }}
          >
            <MyTextField
              name={`${model}.${index}.startHours`}
              type="number"
              values={values}
              touched={rowTouched}
              errors={rowErrors}
              margin="none"
              smallFont
              onChange={handleChange}
              onBlur={createHandleTimeFieldBlur(index)}
              fullWidth
              required={isHour}
              inputProps={{ min: 0, max: 72 }}
            />
            <span className={classes.divider}>:</span>
            <MyTextField
              name={`${model}.${index}.startMinutes`}
              type="number"
              values={values}
              touched={rowTouched}
              errors={rowErrors}
              margin="none"
              smallFont
              onChange={handleChange}
              onBlur={createHandleTimeFieldBlur(index)}
              fullWidth
              required={isHour}
              inputProps={{ min: 0, max: 59, step: 15 }}
            />
          </div>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <div
            className={classes.hoursField}
            style={{
              visibility: isHour ? 'visible' : 'hidden'
            }}
          >
            <MyTextField
              name={`${model}.${index}.endHours`}
              type="number"
              values={values}
              touched={rowTouched}
              errors={rowErrors}
              margin="none"
              smallFont
              onChange={handleChange}
              onBlur={createHandleTimeFieldBlur(index)}
              fullWidth
              required={isHour}
              inputProps={{ min: get(values, `${model}.${index}.startHours`), max: 72 }}
            />
            <span className={classes.divider}>:</span>
            <MyTextField
              name={`${model}.${index}.endMinutes`}
              type="number"
              values={values}
              touched={rowTouched}
              errors={rowErrors}
              margin="none"
              smallFont
              onChange={handleChange}
              onBlur={createHandleTimeFieldBlur(index)}
              fullWidth
              required={isHour}
              inputProps={{ min: 0, max: 59, step: 15 }}
            />
          </div>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <div
            style={{
              visibility: isHour ? 'visible' : 'hidden'
            }}
          >
            <MyTextField
              name={`${model}.${index}.timeSpan`}
              type="number"
              values={values}
              touched={rowTouched}
              errors={rowErrors}
              margin="none"
              smallFont
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              disabled
            />
          </div>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.amount`}
            type="number"
            values={values}
            touched={rowTouched}
            errors={rowErrors}
            margin="none"
            smallFont
            redFont={
              isHour &&
              get(values, `${model}.${index}.timeSpan`) !== get(values, `${model}.${index}.amount`)
            }
            inputProps={isPoint ? { step: '1' } : { step: '0.25' }}
            onChange={handleChange}
            onBlur={handleAmountBlur}
            fullWidth
            required
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.unitLabel`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleUnitLabelChange}
            onBlur={handleBlur}
            fullWidth
            select
          >
            {Object.entries(enums.UNIT_LABEL).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </MyTextField>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.unitPrice`}
            type="number"
            values={values}
            touched={rowTouched}
            errors={rowErrors}
            margin="none"
            smallFont
            inputProps={{ step: '100' }}
            onChange={handleChange}
            onBlur={handleUnitPriceBlur}
            fullWidth
            required
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.price`}
            type="number"
            values={values}
            touched={rowTouched}
            errors={rowErrors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            required
            disabled
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.detail`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          {!['Archived', 'Integrated'].includes(values.voucherStatus) && (
            <IconButton aria-label="Delete" onClick={handleRemove}>
              <DeleteIcon fontSize="small" color="secondary" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  }
}
const EnchancedFieldRow = withOperationFormFieldRow('voucherOperations', {
  willSubmitWhenRemove: props => props.action === 'EditVoucher'
})(FieldRow);

function VoucherOperationFieldsForm(props) {
  const { classes, values } = props;

  return (
    <FieldArray
      name="voucherOperations"
      render={arrayHelpers => (
        <Paper className={classes.root}>
          <FieldsFormToolbar
            title="オペレーション"
            onAddClick={() => {
              arrayHelpers.push(voucherOperationInitialValues);
            }}
          />
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeadRow}>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 132 }}
                >
                  日付
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 160 }}
                >
                  作業
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 92 }}
                >
                  開始
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 92 }}
                >
                  終了
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  合計
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  実働
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 106 }}
                >
                  単位
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  単価
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  金額
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 416 }}
                >
                  詳細
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  削除
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <React.Fragment>
                {values.voucherOperations &&
                  values.voucherOperations.map((row, index) => (
                    <EnchancedFieldRow
                      key={index}
                      index={index}
                      row={row}
                      arrayHelpers={arrayHelpers}
                      {...props}
                    />
                  ))}
              </React.Fragment>
            </TableBody>
          </Table>
        </Paper>
      )}
    />
  );
}

export default compose(withStyles(styles))(VoucherOperationFieldsForm);
