import React from 'react';
import { FieldArray } from 'formik';
import { get } from 'lodash';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import enums from 'enums';
import withOperationFormFieldRow from 'hocs/withOperationFormFieldRow';
import { reportOperationInitialValues } from 'initialValues';

import MyTextField from 'elements/MyTextField';
import FieldsFormToolbar from 'elements/FieldsFormToolbar';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  table: {
    tableLayout: 'fixed'
  },
  tableHeadRow: {
    height: 48
  },
  tableHeadCell: {
    paddingRight: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit / 2
  },
  tableBodyCell: {
    paddingRight: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit / 2
  },
  hoursField: {
    display: 'flex',
    alignItems: 'center',
    '& input': {
      textAlign: 'center'
    }
  },
  divider: {
    margin: `0 ${theme.spacing.unit / 2}px`
  }
});

class FieldRow extends React.Component {
  render() {
    const {
      index,
      classes,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      userOptions,
      movable
    } = this.props;
    const {
      model,
      handleRemove,
      handleUp,
      handleDown,
      categorizedOperationOptions,
      createHandleTimeFieldBlur,
      handleSectionChange,
      handleUnitLabelChange
    } = this.props;

    const rowTouched = touched.reportOperations ? touched.reportOperations[index] : {};
    const rowErrors = errors.reportOperations ? errors.reportOperations[index] : {};

    const isHour = get(values, `${model}.${index}.unitLabel`) === 'Hour';
    const isPoint = get(values, `${model}.${index}.unitLabel`) === 'Point';
    const isNew = get(values, `${model}.${index}.id`) === undefined;

    const section = get(values, `${model}.${index}.section`);
    let operations;
    if (categorizedOperationOptions && section) {
      operations = categorizedOperationOptions[section];
    } else {
      operations = [];
    }

    return (
      <TableRow>
        {movable && (
          <TableCell className={classes.tableBodyCell} align="center" padding="none">
            {!isNew && (
              <>
                <IconButton aria-label="Up" onClick={handleUp}>
                  <KeyboardArrowUpIcon fontSize="small" />
                </IconButton>
                <IconButton aria-label="Down" onClick={handleDown}>
                  <KeyboardArrowDownIcon fontSize="small" />
                </IconButton>
              </>
            )}
          </TableCell>
        )}
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.date`}
            type="date"
            values={values}
            touched={rowTouched}
            errors={rowErrors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            required
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.section`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleSectionChange}
            onBlur={handleBlur}
            fullWidth
            select
          >
            <MenuItem key="" value="" />
            {Object.entries(enums.REPORT_SECTION).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </MyTextField>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.operationRelatedId`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            select
          >
            <MenuItem key="" value="" />
            {operations.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </MyTextField>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <div
            className={classes.hoursField}
            style={{
              visibility: isHour ? 'visible' : 'hidden'
            }}
          >
            <MyTextField
              name={`${model}.${index}.startHours`}
              type="number"
              values={values}
              touched={rowTouched}
              errors={rowErrors}
              margin="none"
              smallFont
              onChange={handleChange}
              onBlur={createHandleTimeFieldBlur(index)}
              fullWidth
              required={isHour}
              inputProps={{ min: 0, max: 72 }}
            />
            <span className={classes.divider}>:</span>
            <MyTextField
              name={`${model}.${index}.startMinutes`}
              type="number"
              values={values}
              touched={rowTouched}
              errors={rowErrors}
              margin="none"
              smallFont
              onChange={handleChange}
              onBlur={createHandleTimeFieldBlur(index)}
              fullWidth
              required={isHour}
              inputProps={{ min: 0, max: 59, step: 15 }}
            />
          </div>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <div
            className={classes.hoursField}
            style={{
              visibility: isHour ? 'visible' : 'hidden'
            }}
          >
            <MyTextField
              name={`${model}.${index}.endHours`}
              type="number"
              values={values}
              touched={rowTouched}
              errors={rowErrors}
              margin="none"
              smallFont
              onChange={handleChange}
              onBlur={createHandleTimeFieldBlur(index)}
              fullWidth
              required={isHour}
              inputProps={{ min: get(values, `${model}.${index}.startHours`), max: 72 }}
            />
            <span className={classes.divider}>:</span>
            <MyTextField
              name={`${model}.${index}.endMinutes`}
              type="number"
              values={values}
              touched={rowTouched}
              errors={rowErrors}
              margin="none"
              smallFont
              onChange={handleChange}
              onBlur={createHandleTimeFieldBlur(index)}
              fullWidth
              required={isHour}
              inputProps={{ min: 0, max: 59, step: 15 }}
            />
          </div>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <div
            style={{
              visibility: isHour ? 'visible' : 'hidden'
            }}
          >
            <MyTextField
              name={`${model}.${index}.timeSpan`}
              type="number"
              values={values}
              touched={rowTouched}
              errors={rowErrors}
              margin="none"
              smallFont
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              disabled
            />
          </div>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.amount`}
            type="number"
            values={values}
            touched={rowTouched}
            errors={rowErrors}
            margin="none"
            smallFont
            redFont={
              isHour &&
              get(values, `${model}.${index}.timeSpan`) !== get(values, `${model}.${index}.amount`)
            }
            inputProps={isPoint ? { step: '1' } : { step: '0.25' }}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            required
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.unitLabel`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleUnitLabelChange}
            onBlur={handleBlur}
            fullWidth
            select
          >
            {Object.entries(enums.UNIT_LABEL).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </MyTextField>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.userRelatedId`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            select
          >
            <MenuItem key="" value="" />
            {userOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </MyTextField>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.charge`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            select
          >
            <MenuItem key="" value="" />
            {Object.entries(enums.CHARGE).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </MyTextField>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.rating`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            select
          >
            <MenuItem value="" />
            {[5, 4, 3, 2, 1, 0].map(value => (
              <MenuItem key={value} value={value}>
                {`${value}`}
              </MenuItem>
            ))}
          </MyTextField>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.detail`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <MyTextField
            name={`${model}.${index}.systemCheck`}
            values={values}
            touched={touched}
            errors={errors}
            margin="none"
            smallFont
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            select
          >
            <MenuItem key="" value="" />
            {Object.entries(enums.SYSTEM_CHECK).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </MyTextField>
        </TableCell>
        <TableCell className={classes.tableBodyCell} align="center" padding="none">
          <IconButton aria-label="Delete" onClick={handleRemove} disabled={movable}>
            <DeleteIcon fontSize="small" color="secondary" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}
const EnchancedFieldRow = withOperationFormFieldRow('reportOperations', {
  willSubmitWhenRemove: props => props.action === 'EditReport'
})(FieldRow);

function ReportOperationFieldsForm(props) {
  const { classes, values, movable, onToggleMovable } = props;

  return (
    <FieldArray
      name="reportOperations"
      render={arrayHelpers => (
        <Paper className={classes.root}>
          <FieldsFormToolbar
            title="オペレーション"
            movable={movable}
            onToggleMovable={onToggleMovable}
            onAddClick={() => {
              const n = values.reportOperations.length;
              arrayHelpers.push({
                ...reportOperationInitialValues,
                sortOrder: n + 1
              });
            }}
            isReport={true}
          />
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeadRow}>
                {movable && (
                  <TableCell
                    className={classes.tableHeadCell}
                    align="center"
                    padding="none"
                    style={{ width: 108 }}
                  />
                )}
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 132 }}
                >
                  日付
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 108 }}
                >
                  部署
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 140 }}
                >
                  作業
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 92 }}
                >
                  開始
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 92 }}
                >
                  終了
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  合計
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  実働
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 106 }}
                >
                  単位
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 128 }}
                >
                  作業者
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 88 }}
                >
                  担当
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  評価
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 416 }}
                >
                  詳細
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  システム
                </TableCell>
                <TableCell
                  className={classes.tableHeadCell}
                  align="center"
                  padding="none"
                  style={{ width: 68 }}
                >
                  削除
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.reportOperations &&
                values.reportOperations.map((row, index) => (
                  <EnchancedFieldRow
                    key={index}
                    index={index}
                    row={row}
                    arrayHelpers={arrayHelpers}
                    movable={movable}
                    {...props}
                  />
                ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    />
  );
}

export default withStyles(styles)(ReportOperationFieldsForm);
