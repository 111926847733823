import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import enums from 'enums';
import { redirect } from 'browser';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 5,
    width: 180
  },
  button: {
    flex: '0 0 132px',
    textTransform: 'none'
  },
  count: {
    marginLeft: theme.spacing.unit * 3
  }
});

const ProgramStatus = props => {
  const { classes, resourceType, info } = props;

  let statusText;
  switch (resourceType) {
    case 'reports':
      statusText = enums.REPORT_STATUS[info.status];
      break;
    case 'vouchers':
      statusText = enums.VOUCHER_STATUS[info.status];
      break;
    default:
      throw new Error();
  }

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={() => {
          redirect(`${resourceType}?status=${info.status}`);
        }}
      >
        {statusText}
      </Button>
      <Typography variant="subtitle1" className={classes.count}>
        ({info.count})
      </Typography>
    </div>
  );
};

const EnhancedProgramStatus = withStyles(styles)(ProgramStatus);

export const ReportStatus = props => {
  return <EnhancedProgramStatus {...props} resourceType="reports" />;
};

export const VoucherStatus = props => {
  return <EnhancedProgramStatus {...props} resourceType="vouchers" />;
};
