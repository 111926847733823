import React from 'react';
import { EditorState, ContentState } from 'draft-js';

export const withDraftEditor = () => Component => {
  class C extends React.Component {
    state = {
      editorState: EditorState.createEmpty()
    };

    updateEditorState = rendered => {
      if (rendered) {
        const content = ContentState.createFromText(rendered);
        const editorState = EditorState.createWithContent(content);

        this.setState({
          editorState
        });
      } else {
        this.setState({
          editorState: EditorState.createEmpty()
        });
      }
    };

    handleEditorStateChange = editorState => {
      this.setState({
        editorState
      });
    };

    render() {
      return (
        <Component
          {...this.props}
          editorState={this.state.editorState}
          updateEditorState={this.updateEditorState}
          handleEditorStateChange={this.handleEditorStateChange}
        />
      );
    }
  }

  C.displayName = `withDraftEditor(${Component.displayName || Component.name})`;

  return C;
};

export default withDraftEditor;
