import React from 'react';
import { withFormik } from 'formik';
import { compose, graphql } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { LABEL_LIST } from 'gqls/label';
import { SEARCH_CUSTOMERS } from 'gqls/customer';
import enums from 'enums';
import { convertIntoSelectFieldValues, relateFields, takeOptionField } from 'utils/form';
import withPostalCodeField from 'hocs/withPostalCodeField';
import { notifyError } from 'notifications';

import PostalCodeField from 'elements/PostalCodeField';
import MyTextField from 'elements/MyTextField';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(theme.breakpoints.values.md + theme.spacing.unit * 2 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  form: {
    marginTop: theme.spacing.unit
  },
  menu: {
    width: 200
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing.unit / 4
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center'
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

class CustomerForm extends React.Component {
  handleNameFieldsBlur = e => {
    const { action, client, values, handleBlur } = this.props;
    handleBlur(e);

    if (action === 'EditCustomer') return;

    client
      .query({
        query: SEARCH_CUSTOMERS,
        variables: {
          where: {
            name: values.name,
            nameKana: values.nameKana
          }
        }
      })
      .then(({ data }) => {
        const { searchCustomers } = data;
        if (searchCustomers.count > 0) {
          notifyError('既に同じ会社名が登録されています。');
        }
      })
      .catch(() => {});
  };

  render() {
    const { classes, values, errors, touched, handleChange, handleBlur, handleSubmit } = this.props;

    const {
      action,
      data: { loading, labels = [] }
    } = this.props;
    const { handlePostalCodeInputDebounceChange } = this.props;

    if (loading) return null;

    const labelOptions = convertIntoSelectFieldValues(labels);

    let buttomLabel;
    switch (action) {
      case 'NewCustomer':
        buttomLabel = '登録';
        break;
      case 'EditCustomer':
        buttomLabel = '変更';
        break;
      default:
        throw new Error();
    }

    return (
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" align="center">
          顧客情報
        </Typography>
        <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={24}>
            <Grid item xs={6}>
              <MyTextField
                name="name"
                values={values}
                touched={touched}
                errors={errors}
                label="名前"
                onChange={handleChange}
                onBlur={this.handleNameFieldsBlur}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="nameKana"
                values={values}
                touched={touched}
                errors={errors}
                label="名前（カナ）"
                onChange={handleChange}
                onBlur={this.handleNameFieldsBlur}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="customerType"
                values={values}
                touched={touched}
                errors={errors}
                label="顧客種別"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                select
              >
                {Object.entries(enums.CUSTOMER_TYPE).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>
            <Grid item xs={6}>
              <PostalCodeField
                name="postalCode"
                values={values}
                touched={touched}
                errors={errors}
                label="郵便番号"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                onDebounce={handlePostalCodeInputDebounceChange}
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="prefecture"
                values={values}
                touched={touched}
                errors={errors}
                label="都道府県"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="address"
                values={values}
                touched={touched}
                errors={errors}
                label="住所"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="email"
                type="email"
                values={values}
                touched={touched}
                errors={errors}
                label="Email Address"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="tel"
                values={values}
                touched={touched}
                errors={errors}
                label="TEL"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                name="paymentType"
                values={values}
                touched={touched}
                errors={errors}
                label="支払い条件"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                select
              >
                {Object.entries(enums.CUSTOMER_PAYMENT_TYPE).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                name="paymentClosingDay"
                type="number"
                inputProps={{ min: '1', max: '31' }}
                values={values}
                touched={touched}
                errors={errors}
                label="締め日"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                name="paymentMonthLater"
                type="number"
                values={values}
                touched={touched}
                errors={errors}
                label="月後"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                name="paymentDay"
                type="number"
                inputProps={{ min: '1', max: '31' }}
                values={values}
                touched={touched}
                errors={errors}
                label="日付"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                name="maPrice"
                type="number"
                values={values}
                touched={touched}
                errors={errors}
                label="MA単価"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                name="eedPrice"
                type="number"
                values={values}
                touched={touched}
                errors={errors}
                label="EED単価"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MyTextField
                name="labelsRelatedIds"
                values={values}
                touched={touched}
                errors={errors}
                label="ラベル"
                onChange={handleChange}
                onBlur={handleBlur}
                SelectProps={{
                  multiple: true,
                  MenuProps: {
                    className: classes.menu
                  },
                  renderValue: selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip
                          key={value}
                          label={takeOptionField(labelOptions, value, 'label')}
                          className={classes.chip}
                          color="primary"
                          style={{ backgroundColor: takeOptionField(labelOptions, value, 'color') }}
                        />
                      ))}
                    </div>
                  )
                }}
                fullWidth
                select
              >
                {labelOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="memo"
                values={values}
                touched={touched}
                errors={errors}
                label="備考"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                multiline
                rowsMax="10"
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="registerDate"
                type="date"
                values={values}
                touched={touched}
                errors={errors}
                label="登録日"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="isFreezed"
                values={values}
                touched={touched}
                errors={errors}
                label="無効フラグ"
                margin="none"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                select
                required
              >
                <MenuItem value={true}>無効</MenuItem>
                <MenuItem value={false}>有効</MenuItem>
              </MyTextField>
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button type="submit" variant="contained" color="primary" className={classes.submit}>
              {buttomLabel}
            </Button>
          </div>
        </form>
      </Paper>
    );
  }
}

const takeNewValues = values => {
  return {
    data: relateFields(values)
  };
};

const takeEditValues = (values, initialValues) => {
  const { id, ...rest } = values;
  return {
    data: relateFields(rest, initialValues),
    where: {
      id
    }
  };
};

export default compose(
  withStyles(styles),
  graphql(LABEL_LIST),
  withFormik({
    mapPropsToValues: props => props.initialValues,
    validationSchema: props => props.schema,
    handleSubmit: (values, { props, setSubmitting }) => {
      const { action, initialValues, mutate, onSucceed } = props;

      let variables;
      switch (action) {
        case 'NewCustomer':
          variables = takeNewValues(values);
          break;
        case 'EditCustomer':
          variables = takeEditValues(values, initialValues);
          break;
        default:
          throw new Error();
      }

      console.log(variables);

      mutate({
        variables
      })
        .then(onSucceed)
        .catch(error => {})
        .finally(() => {
          setSubmitting(false);
        });
    },
    displayName: 'CustomerForm'
  }),
  withPostalCodeField()
)(CustomerForm);
