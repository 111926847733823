import React from 'react';
import classNames from 'classnames';
import { withFormik } from 'formik';
import { compose } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

import enums from 'enums';
import { confirm, redirect } from 'browser';

import MyTextField from 'elements/MyTextField';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(theme.breakpoints.values.md + theme.spacing.unit * 2 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  form: {
    marginTop: theme.spacing.unit
  },
  menu: {
    width: 200
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing.unit / 4
  },
  actionBottons: {
    marginTop: theme.spacing.unit * 3
  },
  buttonWrapper: {
    display: 'flex'
  },
  positionCenter: {
    justifyContent: 'center'
  },
  positionRight: {
    justifyContent: 'flex-end'
  }
});

class MailTemplateForm extends React.Component {
  handleDeleteClick = () => {
    const {
      values: { id },
      removeMailTemplate
    } = this.props;

    if (confirm('このテンプレートを削除してもよろしいですか？')) {
      removeMailTemplate({
        variables: {
          where: { id }
        }
      })
        .then(() => {
          redirect('/mailTemplates');
        })
        .catch(error => {});
    }
  };

  render() {
    const {
      action,
      classes,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit
    } = this.props;

    let buttomLabel;
    switch (action) {
      case 'NewMailTemplate':
        buttomLabel = '登録';
        break;
      case 'EditMailTemplate':
        buttomLabel = '変更';
        break;
      default:
        throw new Error();
    }

    return (
      <Paper className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <MyTextField
                name="name"
                values={values}
                touched={touched}
                errors={errors}
                label="テンプレート名"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MyTextField
                name="subject"
                values={values}
                touched={touched}
                errors={errors}
                label="件名"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MyTextField
                name="body"
                values={values}
                touched={touched}
                errors={errors}
                label="テンプレート"
                multiline
                rowsMax="20"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MyTextField
                name="pdfType"
                values={values}
                touched={touched}
                errors={errors}
                label="PDFタイプ"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                select
              >
                <MenuItem key="" value="" />
                {Object.entries(enums.PDF_TYPE).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </MyTextField>
            </Grid>
            <Grid item xs={12}>
              <MyTextField
                name="title"
                values={values}
                touched={touched}
                errors={errors}
                label="書類名"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MyTextField
                name="greeting"
                values={values}
                touched={touched}
                errors={errors}
                label="備考"
                multiline
                rowsMax="5"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MyTextField
                name="payment"
                values={values}
                touched={touched}
                errors={errors}
                label="お支払いに関して"
                multiline
                rowsMax="5"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MyTextField
                name="order"
                type="number"
                values={values}
                touched={touched}
                errors={errors}
                label="並び順"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} className={classes.actionBottons}>
            <Grid item xs={4} />
            <Grid item xs={4} className={classNames(classes.buttonWrapper, classes.positionCenter)}>
              <Button type="submit" variant="contained" color="primary">
                {buttomLabel}
              </Button>
            </Grid>
            <Grid item xs={4} className={classNames(classes.buttonWrapper, classes.positionRight)}>
              {action === 'EditMailTemplate' && (
                <Button size="small" color="secondary" onClick={this.handleDeleteClick}>
                  このテンプレートを削除
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
}

const takeNewValues = values => {
  return {
    data: values
  };
};

const takeEditValues = values => {
  const { id, ...rest } = values;
  return {
    data: rest,
    where: {
      id
    }
  };
};

export default compose(
  withStyles(styles),
  withFormik({
    mapPropsToValues: props => props.initialValues,
    validationSchema: props => props.schema,
    handleSubmit: (values, { props, setSubmitting }) => {
      const { action, mutate, onSucceed } = props;

      let variables;
      switch (action) {
        case 'NewMailTemplate':
          variables = takeNewValues(values);
          break;
        case 'EditMailTemplate':
          variables = takeEditValues(values);
          break;
        default:
          throw new Error();
      }

      mutate({
        variables
      })
        .then(onSucceed)
        .catch(error => {})
        .finally(() => {
          setSubmitting(false);
        });
    },
    displayName: 'MailTemplateForm'
  })
)(MailTemplateForm);
