import React from 'react';
import { compose } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import { SEARCH_REPORT_OPERATIONS } from 'gqls/program';
import { getPeriodRange, getYearMonthRange } from 'utils/period';
import { convertIntoDateFormat } from 'utils/date';
import { convertOperationTimeFieldsIntoForm } from 'formConvertors/program';
import enums from 'enums';
import withUbs from 'hocs/withUbs';
import wrapMenu from 'hocs/wrapMenu';
import { downloadCsv } from 'browser';

import SearchReportOperationForm from 'forms/SearchReportOperationForm';
import ReportOperationTable from 'elements/ReportOperationTable';

const styles = theme => ({
  main: {
    flex: '1 1 auto',
    minWidth: 0,
    paddingTop: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  }
});

const defaultSearchParams = {
  order: 'desc',
  orderBy: 'date',
  page: '0',
  rowsPerPage: '50'
};

class ReportOperationList extends React.Component {
  formatOperationFields = ([...operations]) => {
    operations = operations.map(operation => convertOperationTimeFieldsIntoForm(operation));

    return operations.map(operation => ({
      id: operation.id,
      reportId: operation.report.id,
      programNo: operation.report.program.programNo,
      date: convertIntoDateFormat(operation.date),
      title: operation.report.title,
      subTitle: operation.report.subTitle,
      section: enums.REPORT_SECTION[operation.section],
      operationName: operation.operation ? operation.operation.name : '',
      userName: operation.user ? operation.user.name : '',
      charge: enums.CHARGE[operation.charge],
      rating: operation.rating,
      timeSpan: operation.timeSpan ? operation.timeSpan : '-',
      amount: operation.amount,
      unitLabel: enums.UNIT_LABEL[operation.unitLabel],
      systemCheck: enums.SYSTEM_CHECK[operation.systemCheck],
      customerName: operation.report.customer ? operation.report.customer.name : '',
      personnelName: operation.report.personnel ? operation.report.personnel.name : '',
      detail: operation.detail
    }));
  };

  createHandleCopyClick = operations => () => {
    const head =
      '"No.","日付","PROGRAM","サブタイトル","部署","作業内容","作業者","担当","評価","合計","実働","単位","システム","クライアント","担当者","詳細"';
    const csvContent =
      head +
      '\n' +
      operations
        .map(({ ...operation }) => {
          delete operation.id;
          delete operation.reportId;
          return Object.values(operation)
            .map(v => {
              if (v) {
                return typeof v === 'string' ? `"${v.replace('"', '""')}"` : `"${v}"`;
              } else {
                return '""';
              }
            })
            .join(',');
        })
        .join('\n');

    downloadCsv('オペレーションリスト.csv', csvContent);
  };

  render() {
    const { classes } = this.props;
    const { list, count, order, orderBy, rowsPerPage, page, updateSearchParams } = this.props;

    const operations = this.formatOperationFields(list);

    return (
      <main className={classes.main}>
        <SearchReportOperationForm
          statusEnum={enums.REPORT_STATUS}
          updateSearchParams={updateSearchParams}
          onCopyClick={this.createHandleCopyClick(operations)}
        />
        <ReportOperationTable
          order={order}
          orderBy={orderBy}
          rowsPerPage={rowsPerPage}
          page={page}
          count={count}
          operations={operations}
          updateSearchParams={updateSearchParams}
        />
      </main>
    );
  }
}

const convert = ({ params }) => {
  const searchParams = {};
  searchParams['AND'] = [];

  Object.entries(params).forEach(([key, value]) => {
    switch (key) {
      case 'period':
        const periodRange = getPeriodRange(+value);
        searchParams['AND'].push({ date_gte: periodRange.begin });
        searchParams['AND'].push({ date_lte: periodRange.end });
        break;
      case 'yearMonth':
        const yearMonthRange = getYearMonthRange(value);
        searchParams['AND'].push({ date_gte: yearMonthRange.begin });
        searchParams['AND'].push({ date_lte: yearMonthRange.end });
        break;
      case 'status':
        searchParams['AND'].push({ report: { reportStatus: value } });
        break;
      case 'customer':
        searchParams['AND'].push({
          report: { customer: { OR: [{ name_contains: value }, { nameKana_contains: value }] } }
        });
        break;
      case 'personnel':
        searchParams['AND'].push({
          report: { personnel: { OR: [{ name_contains: value }, { nameKana_contains: value }] } }
        });
        break;
      case 'title':
        const titleParams = [];
        titleParams.push({ title_contains: value });
        titleParams.push({ subTitle_contains: value });
        searchParams['AND'].push({ report: { OR: titleParams } });
        break;
      case 'programNo':
        searchParams['AND'].push({ report: { program: { programNo: +value } } });
        break;
      case 'section':
        searchParams['AND'].push({ section: value });
        break;
      case 'userRelatedId':
        searchParams['AND'].push({ user: { id: value } });
        break;
      case 'operationRelatedId':
        searchParams['AND'].push({ operation: { id: value } });
        break;
      case 'charge':
        searchParams['AND'].push({ charge: value });
        break;
      case 'rating':
        searchParams['AND'].push({ rating: +value });
        break;
      case 'systemCheck':
        searchParams['AND'].push({ systemCheck: value });
        break;
      default:
        throw new Error();
    }
  });

  return searchParams;
};

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: 'オペレーションリスト' }),
  withUbs({
    defaultSearchParams,
    gql: SEARCH_REPORT_OPERATIONS,
    convertGraphqlParams: convert,
    takeResult: ({ data }) => {
      const {
        searchReportOperations: { reportOperations, count }
      } = data;
      return {
        list: reportOperations,
        count: count
      };
    }
  })
)(ReportOperationList);
