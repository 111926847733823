import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';
import * as yup from 'yup';

import { FETCH_CUSTOMER, NEW_PERSONNEL } from 'gqls/customer';
import { redirect } from 'browser';
import { convertIntoNewPersonnelForm } from 'formConvertors/customer';
import wrapMenu from 'hocs/wrapMenu';

import PersonnelForm from 'forms/PersonnelForm';

const styles = theme => ({
  main: {
    width: 800,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

class NewPersonnel extends React.Component {
  handleSucceed = ({ data }) => {
    const {
      addPersonnel: {
        customer: { id }
      }
    } = data;
    redirect(`/customers/${id}/personnels`);
  };

  render() {
    const { classes, data, mutate } = this.props;

    if (data.loading) return null;

    const initialValues = convertIntoNewPersonnelForm(data.fetchCustomer);

    return (
      <main className={classes.main}>
        <PersonnelForm
          action="NewPersonnel"
          schema={schema}
          initialValues={initialValues}
          mutate={mutate}
          onSucceed={this.handleSucceed}
          customer={data.fetchCustomer}
        />
      </main>
    );
  }
}

const schema = yup.object().shape({
  name: yup.string().required(),
  nameKana: yup.string().required(),
  department: yup.string(),
  postalCode: yup.string(),
  prefecture: yup.string(),
  address: yup.string(),
  email: yup.string().email(),
  memo: yup.string(),
  registerDate: yup.date().required(),
  isRetired: yup.bool().required()
});

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: '顧客情報 - 担当者' }),
  graphql(FETCH_CUSTOMER, {
    options: props => ({
      variables: {
        where: { id: props.match.params.customerId }
      }
    })
  }),
  graphql(NEW_PERSONNEL)
)(NewPersonnel);
