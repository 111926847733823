import queryString from 'query-string';
import { upperCase } from 'lodash';

import { removeVoidProps } from 'utils/util';

export const newHashHistory = (newParams, location) => {
  const searchParams = queryString.parse(location.search);

  const merged = removeVoidProps({ ...searchParams, ...newParams });

  return {
    pathname: location.pathname,
    search: `?${queryString.stringify(merged)}`
  };
};

export const takeSearchParams = location => {
  const searchParams = queryString.parse(location.search);
  return removeVoidProps(searchParams);
};

export const takeMergedSearchParams = (defaultSearchParams, location) => {
  const searchParams = takeSearchParams(location);
  return { ...defaultSearchParams, ...searchParams };
};

export const convertPaginationParams = params => {
  const { order, orderBy, rowsPerPage, page } = params;
  return {
    skip: +page * +rowsPerPage,
    first: +rowsPerPage,
    orderBy: `${orderBy}_${upperCase(order)}`
  };
};
