import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { convertIntoDateFormat } from 'utils/date';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`
  },
  head: {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing.unit}px`
  },
  previewWrapper: {
    flex: '1 1 auto',
    marginTop: theme.spacing.unit * 2
  }
});

class SentPdf extends React.Component {
  render() {
    const { classes, pdf } = this.props;

    return (
      <Paper className={classes.root}>
        <div className={classes.head}>
          <div>
            <Typography component="h2" variant="h6">
              {pdf.voucher.customer.name} - {pdf.voucher.title}
            </Typography>
          </div>
          <div>
            <Typography component="h2" variant="h6">
              送信日: {convertIntoDateFormat(pdf.registerDate)}
            </Typography>
          </div>
        </div>
        <div className={classes.previewWrapper}>
          <embed
            src={`data:application/pdf;base64,${pdf.pdfData}`}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(SentPdf);
