import gql from 'graphql-tag';

export const UserFieldsFragment = gql`
  fragment UserFields on User {
    id
    name
    nameKana
    signName
    loginId
    email
    employeeId
    userLevel
    joinedDate
    isRetired
    retiredDate
    sections {
      id
      name
    }
  }
`;

export const CustomerFieldsFragment = gql`
  fragment CustomerFields on Customer {
    id
    name
    nameKana
    customerType
    postalCode
    prefecture
    address
    tel
    email
    paymentType
    paymentClosingDay
    paymentMonthLater
    paymentDay
    maPrice
    eedPrice
    memo
    registerDate
    personnels {
      id
      name
      nameKana
      email
    }
    labels {
      id
      name
      color
      order
    }
    isFreezed
  }
`;

export const PersonnelFieldsFragment = gql`
  fragment PersonnelFields on Personnel {
    id
    customer {
      id
    }
    name
    nameKana
    department
    postalCode
    prefecture
    address
    tel
    email
    memo
    registerDate
    isRetired
  }
`;

export const ReportOperationFieldsFragment = gql`
  fragment ReportOperationFields on ReportOperation {
    id
    user {
      id
      name
    }
    date
    section
    operation {
      id
      name
      applyUnitPrice
    }
    startHours
    startMinutes
    endHours
    endMinutes
    unitLabel
    amount
    charge
    rating
    detail
    systemCheck
    sortOrder
  }
`;

export const ReportMediaFieldsFragment = gql`
  fragment ReportMediaFields on ReportMedia {
    id
    user {
      id
      name
    }
    media {
      id
      name
      unitPrice
    }
    date
    amount
    mediaType
    base
    detail
    systemCheck
    check
    sortOrder
  }
`;

export const ReportExpenseFieldsFragment = gql`
  fragment ReportExpenseFields on ReportExpense {
    id
    user {
      id
      name
    }
    date
    expense {
      id
      name
    }
    amount
    unitPrice
    detail
    systemCheck
    check
    sortOrder
  }
`;

export const ReportMessageFieldsFragment = gql`
  fragment ReportMessageFields on ReportMessage {
    id
    section
    user {
      id
      signName
    }
    content
    registerDateTime
  }
`;

export const ReportFieldsFragment = gql`
  fragment ReportFields on Report {
    id
    program {
      id
      programNo
      vouchers {
        id
        revision
      }
      reportMessages {
        ...ReportMessageFields
      }
    }
    user {
      id
      name
      nameKana
    }
    customer {
      id
      name
      nameKana
      email
      personnels {
        id
        name
        nameKana
        email
      }
      eedPrice
      maPrice
      labels {
        name
        color
        order
      }
    }
    personnel {
      id
      name
      nameKana
      email
    }
    reportOperations(orderBy: sortOrder_ASC) {
      ...ReportOperationFields
    }
    reportExpenses(orderBy: sortOrder_ASC) {
      ...ReportExpenseFields
    }
    reportMedias(orderBy: sortOrder_ASC) {
      ...ReportMediaFields
    }
    title
    subTitle
    reportStatus
    registerUser {
      signName
    }
    registerDate
    finishUser {
      signName
    }
    finishDate
    checkUser {
      signName
    }
    checkDate
    approveUser {
      signName
    }
    approveDate
    completeUser {
      signName
    }
    completeDate
    isNoMedia
    isNoExpense
    memo
  }
  ${ReportOperationFieldsFragment}
  ${ReportMediaFieldsFragment}
  ${ReportExpenseFieldsFragment}
  ${ReportMessageFieldsFragment}
`;

export const ReportFieldsOfListFragment = gql`
  fragment ReportFieldsOfList on Report {
    id
    program {
      id
      programNo
    }
    user {
      id
      name
      nameKana
    }
    customer {
      id
      name
      nameKana
      email
    }
    personnel {
      id
      name
      nameKana
      email
    }
    title
    subTitle
    reportStatus
    memo
    registerDate
  }
`;

export const VoucherOperationFieldsFragment = gql`
  fragment VoucherOperationFields on VoucherOperation {
    id
    date
    operation {
      id
      name
    }
    startHours
    startMinutes
    endHours
    endMinutes
    unitLabel
    amount
    unitPrice
    detail
  }
`;

export const VoucherMediaFieldsFragment = gql`
  fragment VoucherMediaFields on VoucherMedia {
    id
    date
    media {
      id
      name
    }
    amount
    unitPrice
    detail
  }
`;

export const VoucherExpenseFieldsFragment = gql`
  fragment VoucherExpenseFields on VoucherExpense {
    id
    date
    expense {
      id
      name
    }
    amount
    unitPrice
    detail
  }
`;

export const VoucherMessageFieldsFragment = gql`
  fragment VoucherMessageFields on VoucherMessage {
    id
    user {
      id
      signName
    }
    content
    registerDateTime
  }
`;

export const VoucherFieldsFragment = gql`
  fragment VoucherFields on Voucher {
    id
    program {
      id
      programNo
      report {
        id
      }
      vouchers {
        id
        revision
        title
      }
      voucherMessages {
        ...VoucherMessageFields
      }
      integratedFroms {
        id
        programNo
        vouchers {
          id
          revision
          title
        }
      }
    }
    user {
      id
      name
      nameKana
      email
    }
    customer {
      id
      name
      nameKana
      email
      personnels {
        id
        name
        nameKana
        email
      }
      eedPrice
      maPrice
      labels {
        name
        color
        order
      }
    }
    personnel {
      id
      name
      nameKana
      email
    }
    voucherOperations {
      ...VoucherOperationFields
    }
    voucherExpenses {
      ...VoucherExpenseFields
    }
    voucherMedias {
      ...VoucherMediaFields
    }
    revision
    title
    subTitle
    voucherStatus
    registerUser {
      id
      signName
    }
    registerDate
    checkUser {
      id
      signName
    }
    checkDate
    mailUser {
      id
      signName
    }
    mailDate
    fixUser {
      id
      signName
    }
    fixDate
    demandUser {
      id
      signName
    }
    demandDate
    completeUser {
      id
      signName
    }
    completeDate
    grayDate
    memo
    billDate
  }
  ${VoucherOperationFieldsFragment}
  ${VoucherMediaFieldsFragment}
  ${VoucherExpenseFieldsFragment}
  ${VoucherMessageFieldsFragment}
`;

export const VoucherFieldsOfListFragment = gql`
  fragment VoucherFieldsOfList on Voucher {
    id
    program {
      id
      programNo
      integratedFroms {
        id
      }
    }
    user {
      id
      name
      nameKana
    }
    customer {
      id
      name
      nameKana
      email
    }
    personnel {
      id
      name
      nameKana
      email
    }
    voucherOperations {
      unitPrice
      amount
    }
    voucherExpenses {
      unitPrice
      amount
    }
    voucherMedias {
      unitPrice
      amount
    }
    revision
    title
    subTitle
    voucherStatus
    memo
    registerDate
    billDate
  }
`;

export const LabelFieldsFragment = gql`
  fragment LabelFields on Label {
    id
    name
    color
    order
    lockVersion
  }
`;

export const OperationFieldsFragment = gql`
  fragment OperationFields on Operation {
    id
    name
    section
    applyUnitPrice
    order
    lockVersion
  }
`;

export const MediaFieldsFragment = gql`
  fragment MediaFields on Media {
    id
    name
    unitPrice
    order
    lockVersion
  }
`;

export const ExpenseFieldsFragment = gql`
  fragment ExpenseFields on Expense {
    id
    name
    order
    lockVersion
  }
`;

export const SectionFieldsFragment = gql`
  fragment SectionFields on Section {
    id
    name
    order
    lockVersion
  }
`;

export const MailTemplateFieldsFragment = gql`
  fragment MailTemplateFields on MailTemplate {
    id
    name
    subject
    body
    pdfType
    title
    greeting
    payment
    order
  }
`;

export const PdfFieldsOfListFragment = gql`
  fragment PdfFieldsOfList on Pdf {
    id
    pdfType
    voucher {
      program {
        programNo
      }
      customer {
        name
      }
      title
      subTitle
    }
    registerDate
  }
`;

export const PdfFieldsFragment = gql`
  fragment PdfFields on Pdf {
    id
    pdfNo
    pdfData
    voucher {
      title
      customer {
        name
      }
    }
    vouchers {
      title
      customer {
        name
      }
    }
    registerDate
  }
`;
