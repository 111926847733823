import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { convertIntoDateFormat } from 'utils/date';

import Typography from '@material-ui/core/Typography';

const Item = ({ classes, label, user, date }) => {
  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <Typography>{label}</Typography>
      </div>
      <div className={classes.body}>
        {user && (
          <React.Fragment>
            <Typography>{user.signName}</Typography>
            <Typography>{date && convertIntoDateFormat(date)}</Typography>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
const EnhancedItem = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 80,
    border: `1.2px solid ${theme.palette.divider}`
  },
  head: {
    textAlign: 'center',
    borderBottom: `1.2px solid ${theme.palette.divider}`
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 50
  }
}))(Item);

const styles = theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    width: 720,
    backgroundColor: theme.palette.background.paper
  }
});

class VoucherStatusFlow extends React.Component {
  render() {
    const {
      classes,
      voucher: {
        registerUser,
        registerDate,
        checkUser,
        checkDate,
        mailUser,
        mailDate,
        fixUser,
        fixDate,
        demandUser,
        demandDate,
        completeUser,
        completeDate,
        grayDate
      },
      paymentDay
    } = this.props;

    return (
      <div className={classes.wrapper}>
        <div className={classes.root}>
          <EnhancedItem label="登録" user={registerUser} date={registerDate} />
          <EnhancedItem label="確認" user={checkUser} date={checkDate} />
          <EnhancedItem label="メール" user={mailUser} date={mailDate} />
          <EnhancedItem label="確定" user={fixUser} date={fixDate} />
          <EnhancedItem label="請求書発行" user={demandUser} date={demandDate} />
          <EnhancedItem label="★" user={{}} date={grayDate} />
          <EnhancedItem label="終了" user={completeUser} date={completeDate} />
          <EnhancedItem label="支払日" user={{}} date={paymentDay} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(VoucherStatusFlow);
