import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Select, { components, createFilter } from 'react-select';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import MyTextField from 'elements/MyTextField';

const filterConfig = {
  stringify: option => {
    return `${option.data.name} ${option.data.nameKana} ${option.data.email}`;
  }
};

function inputComponent({ inputRef, type, required, ...rest }) {
  return <div ref={inputRef} {...rest} />;
}

const WrappedTextField = props => {
  const { selectProps, innerRef, children, innerProps } = props;

  return (
    <MyTextField
      type="div"
      InputProps={{
        inputComponent,
        inputProps: {
          className: selectProps.classes.input,
          inputRef: innerRef,
          children: children,
          ...innerProps
        }
      }}
      {...selectProps.TextFieldProps}
    />
  );
};

function NoOptionsMessage(props) {
  return (
    <Typography color="textSecondary" {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function SingleValue(props) {
  return <div>{props.children}</div>;
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <components.DownChevron emotion={props.emotion} size={12} />
      </components.DropdownIndicator>
    )
  );
};

const ClearIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <components.CrossIcon emotion={props.emotion} size={12} />
      </components.DropdownIndicator>
    )
  );
};

const replacingComponents = {
  Menu,
  NoOptionsMessage,
  Option,
  SingleValue,
  ValueContainer,
  DropdownIndicator,
  ClearIndicator
};

const styles = theme => ({
  input: {
    display: 'flex',
    alignItems: 'center'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  }
});

class SuggestSelectField extends React.Component {
  handleInputChange = (inputValue, actionMeta) => {
    const { onInputChange } = this.props;
    if (onInputChange) {
      onInputChange(inputValue, actionMeta);
    }
  };

  handleChange = value => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  handleBlur = e => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(e);
    }
  };

  render() {
    const { selectedOption, TextFieldProps, options, classes, theme } = this.props;

    const selectStyles = {
      valueContainer: base => ({
        ...base,
        display: 'flex'
      }),
      input: base => ({
        ...base,
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      }),
      dropdownIndicator: base => ({
        ...base,
        padding: '0 4px'
      }),
      indicatorSeparator: base => ({
        ...base,
        margin: 0
      })
    };

    return (
      <Select
        classes={classes}
        styles={selectStyles}
        value={selectedOption}
        options={options}
        components={{ ...replacingComponents, Control: WrappedTextField }}
        onInputChange={this.handleInputChange}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        isClearable
        placeholder=""
        filterOption={createFilter(filterConfig)}
        TextFieldProps={TextFieldProps}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(SuggestSelectField);
