import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';

import { EDIT_PASSWORD } from 'gqls/user';
import { notifySuccess } from 'notifications';
import wrapMenu from 'hocs/wrapMenu';

import PasswordForm from 'forms/PasswordForm';

const styles = theme => ({
  main: {
    width: 800,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

class EditPassword extends React.Component {
  handleSucceed = () => {
    notifySuccess('パスワードが更新されました。');
  };

  render() {
    const { classes, mutate } = this.props;
    return (
      <main className={classes.main}>
        <PasswordForm mutate={mutate} onSucceed={this.handleSucceed} />
      </main>
    );
  }
}

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: 'パスワード' }),
  graphql(EDIT_PASSWORD)
)(EditPassword);
