import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'react-apollo';

import { SEARCH_VOUCHERS } from 'gqls/program';
import { appendProgramNoSuffix } from 'formConvertors/program';
import withUbs from 'hocs/withUbs';
import { redirect } from 'browser';
import wrapMenu from 'hocs/wrapMenu';

import VoucherTable from 'elements/VoucherTable';
import FloatingAddButton from 'elements/FloatingAddButton';

const styles = theme => ({
  main: {
    flex: '1 1 auto',
    minWidth: 0,
    paddingTop: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  }
});

const defaultSearchParams = {
  order: 'desc',
  orderBy: 'registerDate',
  page: '0',
  rowsPerPage: '50'
};

class ProvisionalVoucherListScreen extends React.Component {
  render() {
    const { classes } = this.props;
    const { list, count, order, orderBy, rowsPerPage, page, updateSearchParams } = this.props;

    const convertedList = list.map(appendProgramNoSuffix);

    return (
      <main className={classes.main}>
        <VoucherTable
          order={order}
          orderBy={orderBy}
          rowsPerPage={rowsPerPage}
          page={page}
          count={count}
          vouchers={convertedList}
          updateSearchParams={updateSearchParams}
        />
        <FloatingAddButton
          handleClick={() => {
            redirect('/vouchers/new');
          }}
        />
      </main>
    );
  }
}

const convert = () => {
  const searchParams = {};
  searchParams['AND'] = [];
  searchParams['AND'].push({ voucherStatus: 'Provisional' });

  return searchParams;
};

export default compose(
  withStyles(styles),
  wrapMenu({
    appBarText: '仮伝票リスト'
  }),
  withUbs({
    defaultSearchParams,
    gql: SEARCH_VOUCHERS,
    convertGraphqlParams: convert,
    takeResult: ({ data }) => {
      const {
        searchVouchers: { vouchers, count }
      } = data;
      return {
        list: vouchers,
        count: count
      };
    }
  })
)(ProvisionalVoucherListScreen);
