import React from 'react';
import Downshift from 'downshift';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'react-apollo';

import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import withDebounceInput from 'hocs/withDebounceInput';

import MyTextField from 'elements/MyTextField';

const WrappedTextField = ({ TextFieldProps, debounceInputRef, classes, InputProps }) => {
  const { onBlur } = TextFieldProps;
  return (
    <MyTextField
      {...TextFieldProps}
      inputRef={debounceInputRef}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps,
        onBlur: onBlur
      }}
    />
  );
};
const EnchancedWrappedTextField = withDebounceInput()(WrappedTextField);
EnchancedWrappedTextField.displayName = 'MyTextField';

function renderSuggestion({ option, index, itemProps, highlightedIndex, selectedItem }) {
  const { id, name } = option;

  const isHighlighted = highlightedIndex === index;
  const isSelected = ((selectedItem && selectedItem.id) || '').indexOf(id) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      {name}
    </MenuItem>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  inputRoot: {
    flexWrap: 'wrap'
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1
  }
});

class SearchSelectField extends React.Component {
  state = {
    inputValue: '',
    selectedItem: null
  };

  static getDerivedStateFromProps(props, currentState) {
    const { defaultValue } = props;
    if (!currentState.selectedItem && defaultValue) {
      return {
        inputValue: defaultValue.name,
        selectedItem: defaultValue
      };
    } else {
      return null;
    }
  }

  handleInputChange = event => {
    const { onInputChange } = this.props;

    this.setState({ inputValue: event.target.value });

    onInputChange(event.target.value);
  };

  handleChange = item => {
    const { onChange } = this.props;

    this.setState({
      inputValue: item.name,
      selectedItem: item
    });

    onChange(item);
  };

  render() {
    const { TextFieldProps, classes } = this.props;
    const { inputValue, selectedItem } = this.state;
    const { options, onInputDebounceChange } = this.props;

    return (
      <Downshift
        inputValue={inputValue}
        selectedItem={selectedItem}
        onChange={this.handleChange}
        itemToString={item => (item === undefined || item === null ? '' : item.name)}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          isOpen,
          selectedItem
        }) => (
          <div className={classes.container}>
            <EnchancedWrappedTextField
              classes={classes}
              TextFieldProps={TextFieldProps}
              InputProps={getInputProps({
                onChange: this.handleInputChange,
                onKeyDown: this.handleKeyDown
              })}
              onDebounce={onInputDebounceChange}
            />
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {options.map((option, index) =>
                    renderSuggestion({
                      option,
                      index,
                      itemProps: getItemProps({ item: option }),
                      highlightedIndex,
                      selectedItem
                    })
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    );
  }
}

export default compose(withStyles(styles))(SearchSelectField);
