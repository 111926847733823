import React from 'react';
import { compose } from 'react-apollo';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';

import wrapMenu from 'hocs/wrapMenu';

import SettingCard from 'elements/SettingCard';

const styles = theme => ({
  main: {
    width: 600,
    marginTop: theme.spacing.unit * 8,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

const Operation = () => (
  <Grid item xs={12} sm={6}>
    <SettingCard
      title="オペレーション"
      desc="作業内容を設定する"
      uri="/operations"
      icon={
        <SvgIcon>
          <path d="M12,15C7.58,15 4,16.79 4,19V21H20V19C20,16.79 16.42,15 12,15M8,9A4,4 0 0,0 12,13A4,4 0 0,0 16,9M11.5,2C11.2,2 11,2.21 11,2.5V5.5H10V3C10,3 7.75,3.86 7.75,6.75C7.75,6.75 7,6.89 7,8H17C16.95,6.89 16.25,6.75 16.25,6.75C16.25,3.86 14,3 14,3V5.5H13V2.5C13,2.21 12.81,2 12.5,2H11.5Z" />
        </SvgIcon>
      }
    />
  </Grid>
);

const Media = () => (
  <Grid item xs={12} sm={6}>
    <SettingCard
      title="メディア"
      desc="作業で用意するメディアを設定する"
      uri="/medias"
      icon={
        <SvgIcon>
          <path d="M18,9H16V7H18M18,13H16V11H18M18,17H16V15H18M8,9H6V7H8M8,13H6V11H8M8,17H6V15H8M18,3V5H16V3H8V5H6V3H4V21H6V19H8V21H16V19H18V21H20V3H18Z" />
        </SvgIcon>
      }
    />
  </Grid>
);

const Expense = () => (
  <Grid item xs={12} sm={6}>
    <SettingCard
      title="経費"
      desc="作業に関わる経費を設定する"
      uri="/expenses"
      icon={
        <SvgIcon>
          <path d="M3,6H21V18H3V6M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M7,8A2,2 0 0,1 5,10V14A2,2 0 0,1 7,16H17A2,2 0 0,1 19,14V10A2,2 0 0,1 17,8H7Z" />
        </SvgIcon>
      }
    />
  </Grid>
);

const Email = () => (
  <Grid item xs={12} sm={6}>
    <SettingCard
      title="メール"
      desc="メールのテンプレートを設定する"
      uri="/mailTemplates"
      icon={
        <SvgIcon>
          <path d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" />
        </SvgIcon>
      }
    />
  </Grid>
);

const Label = () => (
  <Grid item xs={12} sm={6}>
    <SettingCard
      title="ラベル"
      desc="顧客に付けるラベルを設定する"
      uri="/labels"
      icon={
        <SvgIcon>
          <path d="M17.63,5.84C17.27,5.33 16.67,5 16,5H5A2,2 0 0,0 3,7V17A2,2 0 0,0 5,19H16C16.67,19 17.27,18.66 17.63,18.15L22,12L17.63,5.84Z" />
        </SvgIcon>
      }
    />
  </Grid>
);

const Section = () => (
  <Grid item xs={12} sm={6}>
    <SettingCard
      title="部署"
      desc="社内の部署を設定する"
      uri="/sections"
      icon={
        <SvgIcon>
          <path d="M9,4A4,4 0 0,1 13,8A4,4 0 0,1 9,12A4,4 0 0,1 5,8A4,4 0 0,1 9,4M9,6A2,2 0 0,0 7,8A2,2 0 0,0 9,10A2,2 0 0,0 11,8A2,2 0 0,0 9,6M9,13C11.67,13 17,14.34 17,17V20H1V17C1,14.34 6.33,13 9,13M9,14.9C6.03,14.9 2.9,16.36 2.9,17V18.1H15.1V17C15.1,16.36 11.97,14.9 9,14.9M15,4A4,4 0 0,1 19,8A4,4 0 0,1 15,12C14.53,12 14.08,11.92 13.67,11.77C14.5,10.74 15,9.43 15,8C15,6.57 14.5,5.26 13.67,4.23C14.08,4.08 14.53,4 15,4M23,17V20H19V16.5C19,15.25 18.24,14.1 16.97,13.18C19.68,13.62 23,14.9 23,17Z" />
        </SvgIcon>
      }
    />
  </Grid>
);

const Setting = props => {
  const { classes } = props;
  return (
    <main className={classes.main}>
      <Grid container spacing={24}>
        <Operation />
        <Media />
        <Expense />
        <Email />
        <Label />
        <Section />
      </Grid>
    </main>
  );
};

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: '設定メニュー' })
)(Setting);
