import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'react-apollo';

import enums from 'enums';
import { getPeriodRange, getYearMonthRange } from 'utils/period';
import { SEARCH_VOUCHERS } from 'gqls/program';
import { appendProgramNoSuffix } from 'formConvertors/program';
import withUbs from 'hocs/withUbs';
import { redirect } from 'browser';
import wrapMenu from 'hocs/wrapMenu';

import SearchReportForm from 'forms/SearchReportForm';
import VoucherTable from 'elements/VoucherTable';
import FloatingAddButton from 'elements/FloatingAddButton';

const styles = theme => ({
  main: {
    flex: '1 1 auto',
    minWidth: 0,
    paddingTop: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  }
});

const defaultSearchParams = {
  order: 'desc',
  orderBy: 'billDate',
  page: '0',
  rowsPerPage: '50'
};

class VoucherList extends React.Component {
  render() {
    const { classes } = this.props;
    const { list, count, order, orderBy, rowsPerPage, page, updateSearchParams } = this.props;

    const convertedList = list.map(appendProgramNoSuffix);

    return (
      <main className={classes.main}>
        <SearchReportForm
          statusEnum={enums.VOUCHER_STATUS}
          updateSearchParams={updateSearchParams}
        />
        <VoucherTable
          order={order}
          orderBy={orderBy}
          rowsPerPage={rowsPerPage}
          page={page}
          count={count}
          vouchers={convertedList}
          updateSearchParams={updateSearchParams}
        />
        <FloatingAddButton
          handleClick={() => {
            redirect('/vouchers/new');
          }}
        />
      </main>
    );
  }
}

const convert = ({ params }) => {
  const searchParams = {};
  searchParams['AND'] = [];

  if (params.status === undefined) {
    searchParams['AND'].push({ voucherStatus_not_in: ['Provisional', 'Integrated', 'Archived'] });
  }

  Object.entries(params).forEach(([key, value]) => {
    switch (key) {
      case 'period':
        const periodRange = getPeriodRange(+value);
        searchParams['AND'].push({ billDate_gte: periodRange.begin });
        searchParams['AND'].push({ billDate_lte: periodRange.end });
        break;
      case 'yearMonth':
        const yearMonthRange = getYearMonthRange(value);
        searchParams['AND'].push({ billDate_gte: yearMonthRange.begin });
        searchParams['AND'].push({ billDate_lte: yearMonthRange.end });
        break;
      case 'status':
        searchParams['AND'].push({ voucherStatus: value });
        break;
      case 'customer':
        searchParams['AND'].push({
          customer: { OR: [{ name_contains: value }, { nameKana_contains: value }] }
        });
        break;
      case 'personnel':
        searchParams['AND'].push({
          personnel: { OR: [{ name_contains: value }, { nameKana_contains: value }] }
        });
        break;
      case 'title':
        const titleParams = [];
        titleParams.push({ title_contains: value });
        titleParams.push({ subTitle_contains: value });
        searchParams['AND'].push({ OR: titleParams });
        break;
      case 'userRelatedId':
        searchParams['AND'].push({ user: { id: value } });
        break;
      case 'programNo':
        searchParams['AND'].push({ program: { programNo: +value } });
        break;
      default:
        throw new Error();
    }
  });

  return searchParams;
};

export default compose(
  withStyles(styles),
  wrapMenu({
    appBarText: '作業伝票リスト',
    colorOrange: true
  }),
  withUbs({
    defaultSearchParams,
    gql: SEARCH_VOUCHERS,
    convertGraphqlParams: convert,
    takeResult: ({ data }) => {
      const {
        searchVouchers: { vouchers, count }
      } = data;
      return {
        list: vouchers,
        count: count
      };
    }
  })
)(VoucherList);
