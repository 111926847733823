import React from 'react';
import { has, get, isEmpty } from 'lodash';

import withFormFieldRow from 'hocs/withFormFieldRow';
import { timeSpan } from 'utils/date';

export const withOperationFormFieldRow = (model, options = {}) => Component => {
  const { willSubmitWhenRemove = () => false } = options;

  class C extends React.Component {
    state = {
      operations: []
    };

    componentDidMount() {
      const { values, categorizedOperationOptions } = this.props;
      const { index } = this.props;

      const section = get(values, `${model}.${index}.section`);
      if (categorizedOperationOptions && section) {
        this.setState({ operations: categorizedOperationOptions[section] });
      }
    }

    createHandleTimeFieldBlur = index => e => {
      const { values, errors, handleBlur, setFieldValue, updatePrice } = this.props;
      const { startHours, startMinutes, endHours, endMinutes } = get(values, [model, index]);

      handleBlur(e);

      if (isEmpty(get(errors, [model, index]))) {
        const span = timeSpan(startHours, startMinutes, endHours, endMinutes);

        setFieldValue(`${model}.${index}.timeSpan`, span);
        setFieldValue(`${model}.${index}.amount`, span);

        if (has(values, `${model}.${index}.unitPrice`)) {
          updatePrice(span);
        }
      }
    };

    handleSectionChange = e => {
      const { categorizedOperationOptions, handleChange, setFieldValue } = this.props;
      const { index } = this.props;
      handleChange(e);

      const section = e.target.value;

      setFieldValue(`${model}.${index}.operationRelatedId`, '');

      if (categorizedOperationOptions && section) {
        this.setState({ operations: categorizedOperationOptions[section] });
      } else {
        this.setState({ operations: [] });
      }
    };

    handleUnitLabelChange = e => {
      const { handleChange, setFieldValue } = this.props;
      const { index } = this.props;
      handleChange(e);

      setFieldValue(`${model}.${index}.startHours`, '');
      setFieldValue(`${model}.${index}.startMinutes`, '');
      setFieldValue(`${model}.${index}.endHours`, '');
      setFieldValue(`${model}.${index}.endMinutes`, '');
      setFieldValue(`${model}.${index}.timeSpan`, 0);
      setFieldValue(`${model}.${index}.amount`, 0);
    };

    render() {
      return (
        <Component
          {...this.props}
          operations={this.state.operations}
          createHandleTimeFieldChange={this.createHandleTimeFieldChange}
          createHandleTimeFieldBlur={this.createHandleTimeFieldBlur}
          handleSectionChange={this.handleSectionChange}
          handleUnitLabelChange={this.handleUnitLabelChange}
        />
      );
    }
  }

  C.displayName = `withOperationFormFieldRow(${Component.displayName || Component.name})`;

  return withFormFieldRow(model, { willSubmitWhenRemove })(C);
};

export default withOperationFormFieldRow;
