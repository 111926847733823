import React from 'react';

import { notifyError } from 'notifications';

export const withDropzone = () => Component => {
  class C extends React.Component {
    state = {
      files: []
    };

    onDrop = (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        notifyError('1MB以上のファイルは送信できません。');
      }

      this.setState({ files: acceptedFiles });
    };

    onCancel = () => {
      this.setState({
        files: []
      });
    };

    onDelete = index => {
      const files = [...this.state.files];
      files.splice(index, 1);
      this.setState({
        files: [...files]
      });
    };

    render() {
      return (
        <Component
          {...this.props}
          files={this.state.files}
          onDrop={this.onDrop}
          onCancel={this.onCancel}
          onDelete={this.onDelete}
        />
      );
    }
  }

  C.displayName = `withDropzone(${Component.displayName || Component.name})`;

  return C;
};

export default withDropzone;
