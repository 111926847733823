import gql from 'graphql-tag';

import { LabelFieldsFragment } from './fragments';

export const LABEL_LIST = gql`
  query Labels {
    labels(orderBy: order_ASC) {
      ...LabelFields
    }
  }
  ${LabelFieldsFragment}
`;

export const ADD_LABEL = gql`
  mutation AddLabel($data: LabelCreateInput!) {
    addLabel(data: $data) {
      ...LabelFields
    }
  }
  ${LabelFieldsFragment}
`;

export const EDIT_LABEL = gql`
  mutation EditLabel($data: LabelUpdateInput!, $where: LabelWhereUniqueInput!) {
    editLabel(data: $data, where: $where) {
      ...LabelFields
    }
  }
  ${LabelFieldsFragment}
`;

export const REMOVE_LABEL = gql`
  mutation RemoveLabel($where: LabelWhereUniqueInput!) {
    removeLabel(where: $where) {
      ...LabelFields
    }
  }
  ${LabelFieldsFragment}
`;

export const ARRANGE_LABELS = gql`
  mutation ArrangeLabels($list: [ArrangeInput!]!) {
    arrangeLabels(list: $list) {
      ...LabelFields
    }
  }
  ${LabelFieldsFragment}
`;
