import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import orange from '@material-ui/core/colors/orange';

const styles = () => ({
  cssRoot: {
    color: '#fff',
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
});

function OrangeButton(props) {
  const { classes, children, ...rest } = props;

  return (
    <Button className={classNames(classes.margin, classes.cssRoot)} {...rest}>
      {children}
    </Button>
  );
}

export default withStyles(styles)(OrangeButton);
