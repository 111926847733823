import React from 'react';
import { maxBy } from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

import { ProgressContext } from 'contexts';
import { FETCH_REPORT, EDIT_REPORT } from 'gqls/program';
import { convertIntoReportForm } from 'formConvertors/program';
import { newWindow } from 'browser';
import wrapMenu from 'hocs/wrapMenu';
import { hoursField, minutesField } from 'schemas';

import JumpPageWrapper from 'elements/JumpPageWrapper';
import MessageIcon from 'elements/MessageIcon';
import ReportForm from 'forms/ReportForm';
import ReportMessagesColumn from 'elements/ReportMessagesColumn';

export const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  mainColumn: {
    flex: '1 1 auto',
    minWidth: 0,
    overflowY: 'auto'
  },
  rightColumn: {
    flex: '0 0 300px',
    display: 'flex'
  },
  main: {
    width: '100%',
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2
  },
  mailButton: {
    marginLeft: theme.spacing.unit * 4,
    padding: 8
  }
});

class EditReport extends React.Component {
  state = { messageColumnShowState: false, editReport: undefined };

  createHandleClick = voucher => () => {
    newWindow(`/vouchers/${voucher.id}`);
  };

  handleToggleMessageColumnShowState = () => {
    this.setState({ messageColumnShowState: !this.state.messageColumnShowState });
  };

  onSucceed = ({ data }) => {
    this.setValues(data.editReport);
  };

  setValues = values => {
    this.setState({ editReport: values });
  };

  render() {
    const { classes, data, mutate } = this.props;

    if (data.loading) return null;

    const report = this.state.editReport ? this.state.editReport : data.fetchReport;

    const program = report.program;
    const customer = report.customer;
    const voucher = maxBy(report.program.vouchers, 'revision');
    const initialValues = convertIntoReportForm(report);

    return (
      <ProgressContext.Consumer>
        {({ updateProgressState }) => (
          <div className={classes.root}>
            <div className={classes.mainColumn}>
              <main className={classes.main}>
                <JumpPageWrapper
                  next={
                    <React.Fragment>
                      {voucher && (
                        <Button
                          size="small"
                          color="primary"
                          onClick={this.createHandleClick(voucher)}
                        >
                          作業伝票へ >>
                        </Button>
                      )}
                      <IconButton
                        className={classes.mailButton}
                        onClick={this.handleToggleMessageColumnShowState}
                      >
                        <Badge badgeContent={program.reportMessages.length} color="primary">
                          <MessageIcon />
                        </Badge>
                      </IconButton>
                    </React.Fragment>
                  }
                />
                <ReportForm
                  action="EditReport"
                  schema={schema}
                  initialValues={initialValues}
                  customer={customer}
                  sections={data.sections}
                  hasVoucher={voucher ? true : false}
                  mutate={mutate}
                  updateProgressState={updateProgressState}
                  onSucceed={this.onSucceed}
                />
              </main>
            </div>
            {this.state.messageColumnShowState && (
              <div className={classes.rightColumn}>
                <ReportMessagesColumn program={program} setValues={this.setValues} />
              </div>
            )}
          </div>
        )}
      </ProgressContext.Consumer>
    );
  }
}

const schema = yup.object().shape({
  customerRelatedId: yup.string().required(),
  personnelRelatedId: yup.string(),
  userRelatedId: yup.string().required(),
  reportOperations: yup.array().of(
    yup.object().shape({
      date: yup.date().required(),
      section: yup.string(),
      operationRelatedId: yup.string(),
      startHours: hoursField,
      startMinutes: minutesField,
      endHours: hoursField,
      endMinutes: minutesField,
      unitLabel: yup.string(),
      amount: yup.string(),
      userRelatedId: yup.string(),
      charge: yup.string(),
      rating: yup.string(),
      detail: yup.string(),
      systemCheck: yup.string(),
      check: yup.string()
    })
  ),
  title: yup.string().required(),
  subTitle: yup.string(),
  memo: yup.string()
});

export default compose(
  withStyles(styles),
  wrapMenu({
    appBarText: 'ワークレポート'
  }),
  graphql(FETCH_REPORT, {
    options: props => ({
      variables: {
        where: { id: props.match.params.reportId }
      }
    })
  }),
  graphql(EDIT_REPORT)
)(EditReport);
