import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose, graphql } from 'react-apollo';

import { FETCH_PDF } from 'gqls/mail';
import wrapMenu from 'hocs/wrapMenu';

import SentPdf from 'elements/SentPdf';

const styles = theme => ({
  main: {
    flex: '1 1 auto',
    padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 3}px`
  }
});

class ShowPdf extends React.Component {
  render() {
    const { classes, data } = this.props;

    if (data.loading) return null;

    return (
      <main className={classes.main}>
        <SentPdf pdf={data.fetchPdf} />
      </main>
    );
  }
}

export default compose(
  withStyles(styles),
  wrapMenu({
    appBarText: '請求書'
  }),
  graphql(FETCH_PDF, {
    options: props => ({
      variables: {
        where: { id: props.match.params.pdfId }
      }
    })
  })
)(ShowPdf);
