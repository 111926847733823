import { findIndex } from 'lodash';

import moment from 'moment';

import constants from 'myConstants';

const getRange = (target, list) => {
  const index = findIndex(list, option => option.value === target);
  if (index >= 0) {
    return list[index].range;
  } else {
    throw new Error();
  }
};

export const getPeriods = () => {
  const list = [];

  const day = moment(constants.VALUE.START_DAY);
  const today = moment();

  let i = 1;
  while (day.isBefore(today)) {
    const begin = day.clone();
    day.add(1, 'years');
    const end = day.clone().subtract(1, 'days');
    list.push({
      value: i,
      label: `${i}期`,
      range: { begin: begin.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD') }
    });
    i++;
  }

  return list.reverse();
};

export const getCurrentPeriod = () => getPeriods()[0];

export const getPeriodRange = period => {
  const list = getPeriods();
  return getRange(period, list);
};

export const getYearMonthRange = yearMonth => {
  const list = getPassedYearMonths();
  return getRange(yearMonth, list);
};

export const getPassedYearMonths = period => {
  let cur;
  let end;

  const today = moment();

  if (period) {
    const range = getPeriodRange(period);
    cur = moment(range.begin);
    end = moment(range.end);
  } else {
    cur = moment(constants.VALUE.START_DAY);
    end = today.clone();
  }

  const list = [];
  while (cur.isBefore(end) && cur.isBefore(today)) {
    list.push({
      value: cur.format('YYYY-MM-DD'),
      label: cur.format('YYYY年MM月'),
      range: {
        begin: cur.format('YYYY-MM-DD'),
        end: cur
          .clone()
          .add(1, 'month')
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
      }
    });
    cur.add(1, 'month');
  }

  return list.reverse();
};
