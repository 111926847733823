import React from 'react';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import wrapTablePagination from 'hocs/wrapTablePagination';
import { newWindow } from 'browser';

import SortableTableHead from 'elements/SortableTableHead';

const columns = [
  {
    id: 'programNo',
    align: 'center',
    disablePadding: true,
    label: 'No.',
    sortable: false,
    width: 48
  },
  { id: 'date', align: 'center', disablePadding: true, label: '日付', sortable: true, width: 80 },
  {
    id: 'title',
    align: 'left',
    disablePadding: true,
    label: 'PROGRAM',
    sortable: false,
    width: 200
  },
  {
    id: 'mediaRelatedId',
    align: 'center',
    disablePadding: true,
    label: 'メディア',
    sortable: false,
    width: 120
  },
  {
    id: 'amount',
    align: 'center',
    disablePadding: true,
    label: '数量',
    sortable: false,
    width: 80
  },
  {
    id: 'user',
    align: 'center',
    disablePadding: true,
    label: '作業者',
    sortable: false,
    width: 120
  },
  {
    id: 'mediaType',
    align: 'center',
    disablePadding: true,
    label: '種類',
    sortable: true,
    width: 80
  },
  {
    id: 'base',
    align: 'center',
    disablePadding: true,
    label: '場所',
    sortable: true,
    width: 80
  },
  {
    id: 'check',
    align: 'center',
    disablePadding: true,
    label: 'チェック',
    sortable: true,
    width: 80
  },
  {
    id: 'customer',
    align: 'left',
    disablePadding: true,
    label: 'クライアント',
    sortable: false,
    width: 120
  }
];

const styles = theme => ({
  tableBodyCell: {
    paddingRight: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit / 2
  }
});

class HeadBody extends React.Component {
  createHandleClick = media => () => {
    newWindow(`/reports/${media.reportId}`);
  };

  render() {
    const { classes, order, orderBy, medias, handleRequestSort } = this.props;

    return (
      <React.Fragment>
        <SortableTableHead
          order={order}
          orderBy={orderBy}
          columns={columns}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {medias.map(media => (
            <TableRow hover tabIndex={-1} key={media.id} onClick={this.createHandleClick(media)}>
              <TableCell className={classes.tableBodyCell} align="left" component="th" scope="row">
                {media.programNo}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {media.date}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {media.title}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {media.mediaName}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {media.amount}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {media.user}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {media.mediaType}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {media.base}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {media.check}
              </TableCell>
              <TableCell className={classes.tableBodyCell} align="left">
                {media.customerName}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </React.Fragment>
    );
  }
}
const EnhancedHeadBody = withStyles(styles)(HeadBody);

export default compose(wrapTablePagination({ fixed: true }))(EnhancedHeadBody);
