import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { numberWithCommas } from 'utils/util';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto'
  },
  year: {
    width: 80
  },
  month: {
    width: 80
  }
});

function SaleTable(props) {
  const { classes, values, sumSummary, sumCount } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.year}>年</TableCell>
            <TableCell className={classes.month}>月</TableCell>
            <TableCell align="right">金額</TableCell>
            <TableCell align="right">件数</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(values).map(([key, values]) => (
            <React.Fragment key={key}>
              {values.map((row, index) => (
                <TableRow key={row.yearMonth}>
                  {index === 0 && (
                    <TableCell component="th" scope="row" rowSpan={values.length}>
                      {key}
                    </TableCell>
                  )}
                  <TableCell>{+row.yearMonth.slice(4, 6)}</TableCell>
                  <TableCell align="right">{numberWithCommas(row.summary)} 円</TableCell>
                  <TableCell align="right">{row.count}</TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
          <TableRow>
            <TableCell component="th" scope="row" colSpan={2}>
              合計
            </TableCell>
            <TableCell align="right">{numberWithCommas(sumSummary)} 円</TableCell>
            <TableCell align="right">{sumCount}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(SaleTable);
