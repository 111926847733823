import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'react-apollo';

import { SEARCH_CUSTOMERS } from 'gqls/customer';
import { redirect } from 'browser';
import withUbs from 'hocs/withUbs';
import wrapMenu from 'hocs/wrapMenu';

import SearchCustomerForm from 'forms/SearchCustomerForm';
import CustomerTable from 'elements/CustomerTable';
import FloatingAddButton from 'elements/FloatingAddButton';

const styles = theme => ({
  main: {
    flex: '1 1 auto',
    paddingTop: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  }
});

const defaultSearchParams = {
  order: 'desc',
  orderBy: 'registerDate',
  page: '0',
  rowsPerPage: '50'
};

class CustomerList extends React.Component {
  render() {
    const { classes } = this.props;
    const { list, count, order, orderBy, rowsPerPage, page, updateSearchParams } = this.props;

    return (
      <main className={classes.main}>
        <SearchCustomerForm updateSearchParams={updateSearchParams} />
        <CustomerTable
          order={order}
          orderBy={orderBy}
          rowsPerPage={rowsPerPage}
          page={page}
          count={count}
          customers={list}
          updateSearchParams={updateSearchParams}
        />
        <FloatingAddButton
          handleClick={() => {
            redirect('/customers/new');
          }}
        />
      </main>
    );
  }
}

const convert = ({ params }) => {
  const searchParams = {};
  searchParams['AND'] = [];

  Object.entries(params).forEach(([key, value]) => {
    switch (key) {
      case 'keyword':
        const keywordParams = [];
        keywordParams.push({ name_contains: value });
        keywordParams.push({ nameKana_contains: value });
        keywordParams.push({ personnels_some: { name_contains: value } });
        keywordParams.push({ personnels_some: { nameKana_contains: value } });
        searchParams['AND'].push({ OR: keywordParams });
        break;
      case 'tel':
        const telParams = [];
        telParams.push({ tel_contains: value });
        telParams.push({ personnels_some: { tel_contains: value } });
        searchParams['AND'].push({ OR: telParams });
        break;
      case 'email':
        const emailParams = [];
        emailParams.push({ email_contains: value });
        emailParams.push({ personnels_some: { email_contains: value } });
        searchParams['AND'].push({ OR: emailParams });
        break;
      default:
        throw new Error();
    }
  });

  return searchParams;
};

export default compose(
  withStyles(styles),
  wrapMenu({ appBarText: '顧客リスト' }),
  withUbs({
    defaultSearchParams,
    gql: SEARCH_CUSTOMERS,
    convertGraphqlParams: convert,
    takeResult: ({ data }) => {
      const {
        searchCustomers: { customers, count }
      } = data;
      return {
        list: customers,
        count: count
      };
    }
  })
)(CustomerList);
